import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";

import { useNavigate } from "react-router-dom";
import useQueryHook from "../myHooks/queryHook";
import { editprofile, profile } from "../redux/Actions/userActions";
import toast from "react-hot-toast";
import { computeHeadingLevel } from "@testing-library/react";
import { useDispatch, useSelector } from "react-redux";
import { EditProfileData } from "../redux/Reducers/profileSlice";
import useMutationHook from "../myHooks/mutationHook";
import Loader from "../Components/Loader";

export default function CompleteProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const data = useSelector((state) => state?.postData?.editprofiledata);
  console.log(data, "updatedData");

  // =================================================GET PROFILE DATA========================================

  const {
    data: profiledata,
    isLoading,
    refetch,
  } = useQueryHook(["getUserById"], profile);

  console.log(profiledata, "data");

  // =============================================react state===============================================

  const [images, setImages] = useState(null);
  const [image, setImage] = useState(null);
  const [nickName, setnickName] = useState(null);
  const [name, setName] = useState(null);
  const [lastname, setlastname] = useState(null);
  const [about, setabout] = useState(null);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [backendImages, setBackendImages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [objectUrls, setObjectUrls] = useState([]);
  const [deleteImage, setDeleteImage] = useState(null);
  const [path, setPath] = useState(null);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (profiledata) {
      setImages(data?.images || profiledata?.data?.images || []);
      setBackendImages(data?.images || profiledata?.data?.images || []);
      setCity(data?.city || profiledata?.data?.city || null);
      setState(data?.state || profiledata?.data?.state || null);
      setCountry(data?.country || profiledata?.data?.country || null);
      setName(data?.name || profiledata?.data?.name || null);
      setlastname(profiledata?.data?.sur_name);
      setnickName(data?.nickName || profiledata?.data?.nick_name || null);
      setabout(data?.about || profiledata?.data?.about || null);
      setImage(data?.image || profiledata?.data?.image || null);
    }
    if (location?.state?.from) {
      setPath(location?.state?.from);
    }
  }, [profiledata]);

  console.log(path, "pppaaattthh");

  // =====================================handleFile==========================================================

  // Function to handle file selection
  // const handleImageChange = (e, i) => {
  //   let newImage = e.target.files;
  //   setImages((prevImages) => {
  //     const existingImage = prevImages?.find((img) => img.key === i);
  //     if (existingImage) {
  //       return prevImages?.map((img) =>
  //         img.key === i ? { key: i, image: newImage } : img
  //       );
  //     } else {
  //       return [...prevImages, { key: i, image: newImage }];
  //     }
  //   });
  // };

  // useEffect(() => {
  //   // Set backend images when the component mounts
  //   if (initialImages) {
  //     setBackendImages(initialImages);
  //   }
  // }, [initialImages]);

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const newObjectUrl = URL.createObjectURL(file);
      setObjectUrls((prevUrls) => [...prevUrls, newObjectUrl]);

      // Continue with your existing logic
      const newUploadedFiles = [...uploadedFiles];
      newUploadedFiles[index] = file;
      setUploadedFiles(newUploadedFiles);
    }
  };

  console.log(backendImages, "bacvk");
  console.log(uploadedFiles, "bacvk");

  useEffect(() => {
    // Clean up function to revoke object URLs
    return () => {
      objectUrls.forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  }, [objectUrls]);

  // ===============================================editProfile api==========================================

  const {
    mutate,
    isPending,
    isSuccess,
    data: editProfileData,
  } = useMutationHook(editprofile);

  useEffect(() => {
    if (isSuccess) {
      if (editProfileData?.status == 200) {
        console.log(editProfileData, "datdatadtad");
        // sessionStorage.setItem("token", data?.data?.token);
        // if (!data?.user_detail?.profile_completed) {

        // setFlag(!flag);
        refetch();

        !deleteImage && navigate("/user-profile-screen");

        dispatch(EditProfileData(null));
        // }
      }
    }
  }, [isSuccess]);

  // useEffect(() => {

  //   refetch();
  // }, [flag]);

  // Function to create the image object and append to FormData
  // const appendImageToFormData = (formData, image, file) => {
  //   if (image && file) {
  //     // Create the image object
  //     const imageObject = {
  //       uri: `${BASE_URL}public/${image}`,
  //       name: file.name,
  //       type: file.type,
  //     };

  //     // Convert the object to a JSON string

  //     // Append the file itself
  //     formData.append('images', file);
  //   }
  // };

  const handleDeleteImage = (img) => {
    setDeleteImage(img);
    const formData = new FormData();
    if (img) {
      formData.append("images_to_remove", img);
      mutate(formData);
    }
  };

  return (
    <Layout>
      {(isPending || isLoading) && <Loader />}
      <div className="right-side">
        {/* <div className="commn-right-top">
          <div className="search-nav-bar">
            <input type="search" placeholder="Search here..." />
          </div>
          <div className="notify-bell">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="30"
              viewBox="0 0 28 30"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M14.3331 0.00156536C14.4077 0.0367861 14.4814 0.0735721 14.5568 0.105662C15.0768 0.324813 15.3733 0.684847 15.3935 1.20298C15.4084 1.59276 15.4057 1.9841 15.397 2.37387C15.3935 2.5398 15.4478 2.60946 15.6443 2.64155C19.7215 3.30292 22.7604 5.92177 23.6032 9.53463C23.7471 10.1498 23.7778 10.7924 23.7962 11.4232C23.8348 12.7397 23.7672 14.0617 23.8584 15.3742C24.0146 17.6197 25.0959 19.4935 26.9692 20.9947C27.5095 21.4275 27.9243 21.9104 27.9892 22.5741C28.1173 23.8906 27.092 24.9276 25.6055 24.9989C25.5178 25.0028 25.4309 25.002 25.3432 25.002C17.7851 25.002 10.2269 25.0004 2.66877 25.0043C1.79701 25.0051 1.05856 24.7656 0.512174 24.1387C-0.301701 23.2057 -0.132436 21.9245 0.926127 21.0972C1.84963 20.374 2.60211 19.5342 3.16516 18.5605C3.88081 17.3223 4.20618 16.0019 4.20881 14.6135C4.21145 13.4128 4.19303 12.2122 4.21846 11.0116C4.30441 6.90169 7.76513 3.32405 12.3037 2.64938C12.5616 2.61103 12.6317 2.52806 12.6159 2.31361C12.5984 2.08037 12.6326 1.84322 12.608 1.61154C12.5256 0.83747 12.8518 0.28881 13.6789 0C13.8955 0.00156536 14.1139 0.00156536 14.3331 0.00156536Z"
                  fill="black"
                />
                <path
                  d="M8.89035 26.259C12.3125 26.259 15.7092 26.259 19.1059 26.259C19.0348 28.0529 16.573 29.9251 14.2261 29.9971C11.3118 30.0863 9.22712 28.1225 8.89035 26.259Z"
                  fill="black"
                />
              </g>
            </svg>
          </div>
        </div> */}
        <div className="right-side-flow">
          <div className="main-content">
            <div className="main-content-contin">
              <div className="common-page-left-part">
                <div className="commn-profile-show">
                  <img
                    style={{ borderRadius: "50%" }}
                    src={
                      image instanceof File
                        ? URL.createObjectURL(image)
                        : `${BASE_URL}public/${image}`
                    }
                    alt="logo"
                  />
                  <h2>
                    {profiledata?.data?.name} {profiledata?.data?.sur_name}
                    {profiledata?.data?.age
                      ? ", " + profiledata?.data?.age
                      : ""}
                  </h2>
                  <div className="">
                    Change Profile Picture
                    <input
                      type="file"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link
                      to={
                        data?.seekingFor
                          ? "/seeking-for"
                          : "/user-profile-screen"
                      }
                      className="back-right-part"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    {/* <div className="online">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill="#3BAD45" />
                      </svg>
                      Online
                    </div> */}
                  </div>
                  <div className="follow-main-data">
                    <div className="commn-form-feild">
                      <Form className="mt-3">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Nick Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            value={nickName}
                            onChange={(e) => setnickName(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>About You</Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Type here (max 10 words)"
                            rows={3}
                            value={about}
                            onChange={(e) => setabout(e.target.value)}
                          />
                        </Form.Group>
                        <Row>
                          <Col lg={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>Country</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type here "
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>State</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type here "
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>City</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type here "
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              />
                            </Form.Group>
                          </Col>

                          {/* <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>Images</Form.Label>
                              <div className="row img-upd-prof-list">
                                {images?.map((image, i) => {
                                  return (
                                    <div className="col-md-4 ">
                                      <div className="upd-profl-img">
                                        <img
                                          style={{
                                            height: "160px",
                                            width: "100%",
                                            borderRadius: "15px",
                                            objectFit: "cover",
                                          }}
                                          src={
                                            image instanceof File
                                              ? URL.createObjectURL(image)
                                              : `${BASE_URL}public/${image}`
                                          }
                                          alt="Profile"
                                        />
                                        
                                        <Form.Control
                                          type="file"
                                          onChange={(e) => handleFile(i, e)}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </Form.Group>
                          </Col> */}
                          <Col lg={12} className="mb-4">
                            <div className="select-gender-head text-start">
                              <Form.Label>Images</Form.Label>
                            </div>
                            <div className="about-img-contain">
                              {Array.from({ length: 5 }, (_, i) => (
                                <div className="about-img-item" key={i}>
                                  <div
                                    className="recent-photos-sign"
                                    style={{ position: "relative" }}
                                  >
                                    <input
                                      id={`file-input-${i}`}
                                      type="file"
                                      className="file-input"
                                      accept="image/*"
                                      style={{
                                        opacity: 0,
                                        position: "absolute",
                                        height: "100%",
                                        width: "100%",
                                        cursor: "pointer",
                                      }}
                                      onChange={(e) => handleImageChange(e, i)}
                                    />

                                    {uploadedFiles[i] ? (
                                      <>
                                        {/* <div className="top-right-dlt">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                        fill="none"
                                        onClick={() =>
                                          handleDeleteImage(backendImages[i])
                                        }
                                      >
                                        <path
                                          d="M1.64985 2.74619C1.5459 2.74619 1.46106 2.74619 1.37588 2.74619C1.10091 2.74619 0.825599 2.74954 0.55096 2.74485C0.232057 2.7395 0.00134711 2.50822 5.77665e-06 2.20129C-0.00133556 1.88835 0.231051 1.65807 0.556996 1.65037C0.5912 1.6497 0.62574 1.65004 0.660279 1.65004C3.88754 1.65004 7.11513 1.6497 10.3424 1.65138C10.44 1.65138 10.5426 1.65472 10.6341 1.68384C10.891 1.76585 11.0352 2.02223 10.9926 2.29535C10.954 2.54035 10.7388 2.73682 10.4826 2.74285C10.1563 2.75054 9.82933 2.74586 9.50271 2.74619C9.45677 2.74619 9.41083 2.74619 9.34913 2.74619C9.34913 2.81648 9.34913 2.87104 9.34913 2.92559C9.34913 5.04828 9.34812 7.17098 9.3498 9.29367C9.35013 9.95738 9.06678 10.4578 8.49704 10.7938C8.23548 10.9484 7.94676 11.001 7.64395 11C6.57826 10.9973 5.51257 10.999 4.44654 10.999C4.08572 10.999 3.72456 10.9997 3.36374 10.999C2.35707 10.997 1.65086 10.2941 1.65019 9.28965C1.64918 7.17131 1.64985 5.05297 1.64985 2.93463C1.64985 2.87907 1.64985 2.82351 1.64985 2.74619ZM3.84864 6.31112C3.84864 6.76665 3.84126 7.22218 3.85367 7.67705C3.85669 7.79453 3.89927 7.92406 3.96198 8.02413C4.08572 8.22161 4.34426 8.29458 4.56659 8.22395C4.80334 8.14864 4.95021 7.93778 4.95055 7.6583C4.95222 6.769 4.95189 5.87969 4.94954 4.99038C4.94921 4.9144 4.93747 4.83508 4.91299 4.76345C4.82849 4.51544 4.57665 4.36582 4.32548 4.40498C4.04682 4.4485 3.85233 4.66371 3.84998 4.94486C3.84596 5.40039 3.84897 5.85592 3.84864 6.31112ZM7.15001 6.32518C7.15001 5.87835 7.15638 5.43152 7.14565 4.98469C7.14296 4.87022 7.10876 4.74437 7.05142 4.6453C6.93204 4.43778 6.67651 4.35444 6.44446 4.42038C6.20269 4.48933 6.0481 4.70588 6.04776 4.98938C6.04608 5.87868 6.04642 6.76799 6.04877 7.6573C6.0491 7.73328 6.0605 7.8126 6.08465 7.88423C6.16815 8.13091 6.42166 8.28219 6.67215 8.24337C6.95082 8.20019 7.14565 7.98531 7.14833 7.70416C7.15269 7.24461 7.14967 6.78473 7.15001 6.32518Z"
                                          fill="url(#paint0_linear_2919_34496)"
                                        />
                                        <path
                                          d="M5.49345 1.09409C5.11989 1.09409 4.74633 1.09945 4.37277 1.09242C4.10215 1.0874 3.88351 0.877876 3.85166 0.612456C3.8198 0.34804 3.98646 0.096343 4.245 0.0210347C4.28558 0.00932007 4.32917 0.00195659 4.37143 0.00195659C5.12291 0.000952475 5.87406 -0.00239456 6.62554 0.0029607C6.89616 0.00496892 7.11413 0.216167 7.14665 0.482591C7.17918 0.746338 7.01285 1.00038 6.75297 1.07267C6.68423 1.09175 6.60911 1.09409 6.53668 1.09476C6.18894 1.09677 5.8412 1.09577 5.49345 1.09577C5.49345 1.0951 5.49345 1.09443 5.49345 1.09409Z"
                                          fill="url(#paint1_linear_2919_34496)"
                                        />
                                        <defs>
                                          <linearGradient
                                            id="paint0_linear_2919_34496"
                                            x1="5.5"
                                            y1="0"
                                            x2="5.5"
                                            y2="11"
                                            gradientUnits="userSpaceOnUse"
                                          >
                                            <stop stop-color="#E11685" />
                                            <stop
                                              offset="1"
                                              stop-color="#6D0000"
                                            />
                                          </linearGradient>
                                          <linearGradient
                                            id="paint1_linear_2919_34496"
                                            x1="5.5"
                                            y1="0"
                                            x2="5.5"
                                            y2="11"
                                            gradientUnits="userSpaceOnUse"
                                          >
                                            <stop stop-color="#E11685" />
                                            <stop
                                              offset="1"
                                              stop-color="#6D0000"
                                            />
                                          </linearGradient>
                                        </defs>
                                      </svg>
                                    </div> */}
                                        <img
                                          src={URL.createObjectURL(
                                            uploadedFiles[i]
                                          )}
                                          alt={`Uploaded ${i}`}
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            cursor: "pointer",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </>
                                    ) : backendImages[i] ? (
                                      <>
                                        <div className="top-right-dlt">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="11"
                                            height="11"
                                            viewBox="0 0 11 11"
                                            fill="none"
                                            onClick={() =>
                                              handleDeleteImage(
                                                backendImages[i]
                                              )
                                            }
                                          >
                                            <path
                                              d="M1.64985 2.74619C1.5459 2.74619 1.46106 2.74619 1.37588 2.74619C1.10091 2.74619 0.825599 2.74954 0.55096 2.74485C0.232057 2.7395 0.00134711 2.50822 5.77665e-06 2.20129C-0.00133556 1.88835 0.231051 1.65807 0.556996 1.65037C0.5912 1.6497 0.62574 1.65004 0.660279 1.65004C3.88754 1.65004 7.11513 1.6497 10.3424 1.65138C10.44 1.65138 10.5426 1.65472 10.6341 1.68384C10.891 1.76585 11.0352 2.02223 10.9926 2.29535C10.954 2.54035 10.7388 2.73682 10.4826 2.74285C10.1563 2.75054 9.82933 2.74586 9.50271 2.74619C9.45677 2.74619 9.41083 2.74619 9.34913 2.74619C9.34913 2.81648 9.34913 2.87104 9.34913 2.92559C9.34913 5.04828 9.34812 7.17098 9.3498 9.29367C9.35013 9.95738 9.06678 10.4578 8.49704 10.7938C8.23548 10.9484 7.94676 11.001 7.64395 11C6.57826 10.9973 5.51257 10.999 4.44654 10.999C4.08572 10.999 3.72456 10.9997 3.36374 10.999C2.35707 10.997 1.65086 10.2941 1.65019 9.28965C1.64918 7.17131 1.64985 5.05297 1.64985 2.93463C1.64985 2.87907 1.64985 2.82351 1.64985 2.74619ZM3.84864 6.31112C3.84864 6.76665 3.84126 7.22218 3.85367 7.67705C3.85669 7.79453 3.89927 7.92406 3.96198 8.02413C4.08572 8.22161 4.34426 8.29458 4.56659 8.22395C4.80334 8.14864 4.95021 7.93778 4.95055 7.6583C4.95222 6.769 4.95189 5.87969 4.94954 4.99038C4.94921 4.9144 4.93747 4.83508 4.91299 4.76345C4.82849 4.51544 4.57665 4.36582 4.32548 4.40498C4.04682 4.4485 3.85233 4.66371 3.84998 4.94486C3.84596 5.40039 3.84897 5.85592 3.84864 6.31112ZM7.15001 6.32518C7.15001 5.87835 7.15638 5.43152 7.14565 4.98469C7.14296 4.87022 7.10876 4.74437 7.05142 4.6453C6.93204 4.43778 6.67651 4.35444 6.44446 4.42038C6.20269 4.48933 6.0481 4.70588 6.04776 4.98938C6.04608 5.87868 6.04642 6.76799 6.04877 7.6573C6.0491 7.73328 6.0605 7.8126 6.08465 7.88423C6.16815 8.13091 6.42166 8.28219 6.67215 8.24337C6.95082 8.20019 7.14565 7.98531 7.14833 7.70416C7.15269 7.24461 7.14967 6.78473 7.15001 6.32518Z"
                                              fill="url(#paint0_linear_2919_34496)"
                                            />
                                            <path
                                              d="M5.49345 1.09409C5.11989 1.09409 4.74633 1.09945 4.37277 1.09242C4.10215 1.0874 3.88351 0.877876 3.85166 0.612456C3.8198 0.34804 3.98646 0.096343 4.245 0.0210347C4.28558 0.00932007 4.32917 0.00195659 4.37143 0.00195659C5.12291 0.000952475 5.87406 -0.00239456 6.62554 0.0029607C6.89616 0.00496892 7.11413 0.216167 7.14665 0.482591C7.17918 0.746338 7.01285 1.00038 6.75297 1.07267C6.68423 1.09175 6.60911 1.09409 6.53668 1.09476C6.18894 1.09677 5.8412 1.09577 5.49345 1.09577C5.49345 1.0951 5.49345 1.09443 5.49345 1.09409Z"
                                              fill="url(#paint1_linear_2919_34496)"
                                            />
                                            <defs>
                                              <linearGradient
                                                id="paint0_linear_2919_34496"
                                                x1="5.5"
                                                y1="0"
                                                x2="5.5"
                                                y2="11"
                                                gradientUnits="userSpaceOnUse"
                                              >
                                                <stop stop-color="#E11685" />
                                                <stop
                                                  offset="1"
                                                  stop-color="#6D0000"
                                                />
                                              </linearGradient>
                                              <linearGradient
                                                id="paint1_linear_2919_34496"
                                                x1="5.5"
                                                y1="0"
                                                x2="5.5"
                                                y2="11"
                                                gradientUnits="userSpaceOnUse"
                                              >
                                                <stop stop-color="#E11685" />
                                                <stop
                                                  offset="1"
                                                  stop-color="#6D0000"
                                                />
                                              </linearGradient>
                                            </defs>
                                          </svg>
                                        </div>
                                        <img
                                          src={`${BASE_URL}public/${backendImages[i]}`}
                                          alt={`Backend ${i}`}
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            cursor: "pointer",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <div className="top-right-delt">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="47"
                                          height="80"
                                          viewBox="0 0 47 46"
                                          fill="none"
                                        >
                                          <g opacity="0.3">
                                            <path
                                              d="M41.9101 26.2097C41.6601 26.0564 41.5087 25.9702 41.3645 25.8744C39.0908 24.373 36.8003 22.8955 34.5531 21.3558C33.4619 20.6087 32.4717 20.5752 31.3373 21.2696C28.2416 23.1709 25.1075 25.0076 21.9999 26.8921C21.6538 27.1029 21.4303 27.0813 21.1058 26.861C19.4546 25.7403 17.777 24.6627 16.1258 23.5421C15.3784 23.0344 14.6261 22.9195 13.7464 23.1613C9.59326 24.3083 5.42809 25.4218 1.26771 26.5473C1.18359 26.5713 1.09947 26.5904 0.955265 26.6287C0.943248 26.43 0.92402 26.2623 0.92402 26.0947C0.92402 19.7825 0.91681 13.4728 0.928827 7.16297C0.936037 3.68361 3.2794 0.929813 6.71393 0.328767C7.11531 0.259323 7.53111 0.254534 7.93969 0.254534C16.9695 0.249745 25.9992 0.24735 35.029 0.254534C38.4899 0.256929 41.2371 2.47194 41.9101 5.79805C42.011 6.29852 42.0422 6.82294 42.0447 7.33538C42.0543 13.2022 42.0519 19.069 42.0447 24.9357C42.0447 25.3308 41.9629 25.7283 41.9101 26.2097ZM13.5013 16.1739C15.3952 16.1667 16.9214 14.6222 16.907 12.728C16.8925 10.8626 15.3639 9.35164 13.4893 9.34685C11.5929 9.34446 10.0547 10.877 10.0571 12.7663C10.0595 14.6533 11.6025 16.1811 13.5013 16.1739Z"
                                              fill="#B4B4B4"
                                            />
                                            <path
                                              d="M0.991317 31.3413C3.18567 30.7403 5.39924 30.1344 7.61282 29.531C9.67498 28.9706 11.7347 28.4031 13.8041 27.8667C14.0396 27.8069 14.3737 27.8643 14.578 27.996C16.4022 29.1766 18.2144 30.3739 20.017 31.5879C20.964 32.2273 21.8869 32.2489 22.8651 31.6574C26.0233 29.7537 29.1934 27.8739 32.3467 25.9678C32.6952 25.7571 32.9332 25.7188 33.2865 25.9726C34.2647 26.6742 35.2813 27.3232 36.3292 28.0248C35.738 28.2786 35.202 28.463 34.7093 28.724C30.722 30.8289 28.5685 34.1167 28.3546 38.6233C28.2729 40.357 27.4148 41.2167 25.6627 41.2191C19.7575 41.2191 13.8498 41.2262 7.9445 41.2167C4.39701 41.2095 1.53209 38.8628 1.02497 35.4073C0.830286 34.1047 0.991317 32.7493 0.991317 31.3413Z"
                                              fill="#B4B4B4"
                                            />
                                            <path
                                              d="M42.0495 36.6597C42.8065 36.6597 43.4987 36.6573 44.1909 36.6597C45.5777 36.6669 46.5848 37.6104 46.5992 38.9059C46.6112 40.2109 45.6306 41.1831 44.2486 41.2167C43.5396 41.2334 42.8306 41.2191 42.0495 41.2191C42.0495 41.9925 42.0663 42.7133 42.0446 43.4365C42.0062 44.7655 41.0616 45.7281 39.807 45.7496C38.538 45.7712 37.5117 44.8062 37.4781 43.5346C37.4589 42.7923 37.4757 42.0476 37.4757 41.2191C36.7234 41.2191 36 41.231 35.2789 41.2167C34.0844 41.1927 33.1783 40.4719 32.9596 39.392C32.6808 38.0127 33.6854 36.7172 35.0963 36.6669C35.8606 36.6406 36.6273 36.6621 37.4733 36.6621C37.4733 35.8815 37.4589 35.1248 37.4757 34.3705C37.5069 32.8786 38.8505 31.8418 40.2973 32.177C41.3188 32.4141 42.0182 33.3073 42.0471 34.4471C42.0639 35.1559 42.0495 35.8671 42.0495 36.6597Z"
                                              fill="#B4B4B4"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Col>
                        </Row>
                      </Form>
                      <div className="common-form-btn">
                        <Button
                          onClick={() => {
                            // navigate("/hobbies-interests");
                            if (!image) {
                              toast.error("Profile picture is required");
                            } else if (!name) {
                              toast.error("Name is required");
                            } else if (!nickName) {
                              toast.error("Nick name is required");
                            } else if (!about) {
                              toast.error("About is required");
                            } else if (!country) {
                              toast.error("Country is required");
                            } else if (!city) {
                              toast.error("City is required");
                            } else if (!state) {
                              toast.error("State is required");
                            } else {
                              if (data?.seekingFor) {
                                dispatch(
                                  EditProfileData({
                                    ...data,
                                    name,
                                    image,
                                    images,
                                    country,
                                    city,
                                    state,
                                    nickName,
                                    about,
                                  })
                                );
                                navigate("/hobbies-interests");
                              } else {
                                const formData = new FormData();

                                formData.append("name", name);

                                formData.append("image", image);

                                formData.append("about", about);

                                formData.append("nick_name", nickName);

                                formData.append("country", country);

                                formData.append("state", state);

                                formData.append("city", city);

                                formData.append("web", true);

                                // console.log(images,"iiiiii")

                                // images?.forEach((x) =>
                                //   formData.append(`images`, x)
                                // );
                                // console.log(backendImages, "bsck");
                                // backendImages.forEach((image, index) => {
                                //   if (image) {
                                //     // Ensure image is not undefined
                                //     formData.append(`images`, image);
                                //   }
                                // });
                                // console.log(uploadedFiles, "uploadedFiles");

                                if (uploadedFiles) {
                                  formData.append(`from_web`, 1);

                                  const filteredFiles = uploadedFiles.filter(
                                    (file) => file !== undefined
                                  );
                                  console.log(filteredFiles, "filtered");

                                  filteredFiles?.forEach((file) => {
                                    if (file) {
                                      // Ensure file is not undefined
                                      formData.append(`images`, file);
                                    }
                                  });
                                }

                                // backendImages.forEach((image, index) => {
                                //   // Use a dummy File object for illustration
                                //   // You may need to create or fetch the actual file object as required
                                //   const dummyFile = new File([""], image, {
                                //     type: "image/jpg",
                                //   });
                                //   appendImageToFormData(
                                //     formData,
                                //     image,
                                //     dummyFile
                                //   );
                                // });

                                // uploadedFiles.forEach((file, index) => {
                                //   if (file) {
                                //     const imageObject = {
                                //       uri: URL.createObjectURL(file), // Use object URL for local file
                                //       name: file.name,
                                //       type: file.type,
                                //     };

                                //     // formData.append(
                                //     //   "images_metadata",
                                //     //   JSON.stringify(imageObject)
                                //     // );
                                //     formData.append("images", file);
                                //   }
                                // });

                                mutate(formData);
                              }
                            }
                          }}
                        >
                          {data?.seekingFor ? "Continue" : "Save"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
