import { Button, Container, InputGroup } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { EditProfileData, ProfileData } from "../redux/Reducers/profileSlice";
import useQueryHook from "../myHooks/queryHook";
import { editprofile, profile } from "../redux/Actions/userActions";
import useMutationHook from "../myHooks/mutationHook";
import Loader from "../Components/Loader";

export default function Occupation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state?.profileData?.profiledata);
  console.log(data, "prevData");

  const updatedData = useSelector(
    (state) => state?.profileData?.editprofiledata
  );
  console.log(updatedData, "updatedData");

  // =====================================================react state============================================

  const [identityData, setIdentityData] = useState(null);

  // =================================================GET PROFILE DATA========================================

  const { data: profiledata } = useQueryHook(["getUserById"], profile);

  console.log(profiledata, "data");

  useEffect(() => {
    if (profiledata) {
      profiledata?.data?.identity_data?.map((identityData) => {
        if (identityData?.identity === profiledata?.data?.identity_number) {
          setIdentityData(identityData);
          setOccupation(identityData?.name || "");
          setOccupationArr(identityData?.types || []);
        }
      });
    }
  }, [profiledata]);

  console.log(identityData, "identityu");

  // ===============================================editProfile api==========================================

  const {
    mutate,
    isPending,
    isSuccess,
    data: editProfileData,
  } = useMutationHook(editprofile);

  useEffect(() => {
    if (editProfileData?.status === 200) {
      console.log(editProfileData, "datdatadtad");
      // sessionStorage.setItem("token", data?.data?.token);
      // if (!data?.user_detail?.profile_completed) {
      navigate("/dashboard");
      dispatch(EditProfileData(null));
      // }
    }
  }, [isSuccess]);

  // =============================================react state======================================

  const [occupation, setOccupation] = useState(null);
  const [occupationTypes, setOccupationTypes] = useState(null);
  const [occupationArr, setOccupationArr] = useState([]);
  const [tandc, setTandc] = useState(false);

  useEffect(() => {
    if (data) {
      setOccupation(data?.occupation);
      setOccupationArr(
        Array.isArray(data?.occupationArr) ? data.occupationArr : []
      );
      setTandc(data?.tandc);
    }
  }, [data]);

  // ===============================================handleOcupationArr================================

  const handleOcupationArr = () => {
    if (occupationTypes?.length < 1 || occupationTypes == null) {
      toast.error("Please add some data");
    } else {
      setOccupationArr([...occupationArr, occupationTypes]);
      setOccupationTypes("");
    }
  };

  const handleremove = (index) => {
    let copy = [...occupationArr];
    let cutData = copy.splice(index, 1);

    setOccupationArr(copy);
  };

  return (
    <>
      {isPending && <Loader />}
      <section className="Common-sign-flow">
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="text-center mb-3">
                <img
                  className="Common-sign-logo"
                  src={require("../Assets/images/logo.svg").default}
                  alt="logo"
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="page-back-icon">
                <Link to="/identify-yourself">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                      fill="black"
                    />
                  </svg>
                  Back
                </Link>
              </div>
              <div className="Common-sign-boxout">
                <Row>
                  <Col lg={12}>
                    <div className="select-gender-box">
                      <div className="select-gender-head ">
                        <h2>Occupation</h2>

                        <p className="">Define your skill set</p>
                      </div>
                      <div className="profile-preference-optn detail-view-inputs">
                        <Row>
                          <Col lg={8} className="mx-auto">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Select Occupation</Form.Label>
                                <Form.Select
                                  name="occupation"
                                  value={occupation}
                                  onChange={(e) =>
                                    setOccupation(e.target.value)
                                  }
                                  aria-label="Default select example"
                                >
                                  <option>Select</option>
                                  <option value="Administrative / Secretarial / Clerical">
                                    Administrative / Secretarial / Clerical
                                  </option>
                                  <option value="Advertising / Media">
                                    Advertising / Media
                                  </option>
                                  <option value="Artistic / Creative / Performance">
                                    Artistic / Creative / Performance
                                  </option>
                                  <option value="Construction / Trades">
                                    Construction / Trades
                                  </option>
                                  <option value="Domestic Helper">
                                    Domestic Helper
                                  </option>
                                  <option value="Education / Academic">
                                    Education / Academic
                                  </option>
                                  <option value="Entertainment / Media">
                                    Entertainment / Media
                                  </option>
                                  <option value="Executive / Management / HR">
                                    Executive / Management / HR
                                  </option>
                                  <option value="Farming / Agriculture">
                                    Farming / Agriculture
                                  </option>
                                  <option value="Finance / Banking / Real Estate">
                                    Finance / Banking / Real Estate
                                  </option>
                                  <option value="Fire / Law enforcement / Security">
                                    Fire / Law enforcement / Security
                                  </option>
                                  <option value="Hair Dresser / Personal Grooming">
                                    Hair Dresser / Personal Grooming
                                  </option>
                                  <option value="IT / Communications">
                                    IT / Communications
                                  </option>
                                  <option value="Laborer / Manufacturing">
                                    Laborer / Manufacturing
                                  </option>
                                  <option value="Legal">Legal</option>
                                  <option value="Medical / Dental / Veterinary">
                                    Medical / Dental / Veterinary
                                  </option>
                                  <option value="Military">Military</option>
                                  <option value="Nanny / Child care">
                                    Nanny / Child care
                                  </option>
                                  <option value="No occupation / Stay at home">
                                    No occupation / Stay at home
                                  </option>
                                  <option value="Non-profit / Clergy / Social services">
                                    Non-profit / Clergy / Social services
                                  </option>
                                  <option value="Political / Govt / Civil Service">
                                    Political / Govt / Civil Service
                                  </option>
                                  <option value="Retail / Food services">
                                    Retail / Food services
                                  </option>
                                  <option value="Retired">Retired</option>
                                  <option value="Sales / Marketing">
                                    Sales / Marketing
                                  </option>
                                  <option value="Self Employed">
                                    Self Employed
                                  </option>
                                  <option value="Sports / Recreation">
                                    Sports / Recreation
                                  </option>
                                  <option value="Student">Student</option>
                                  <option value="Technical / Science / Engineering">
                                    Technical / Science / Engineering
                                  </option>
                                  <option value="Transportation">
                                    Transportation
                                  </option>
                                  <option value="Travel / Hospitality">
                                    Travel / Hospitality
                                  </option>
                                </Form.Select>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg={8} className="mx-auto">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>
                                  Mention your occupation.
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter keyword"
                                    value={occupationTypes}
                                    name="occupationTypes"
                                    onChange={(e) =>
                                      setOccupationTypes(e.target.value)
                                    }
                                  />
                                  <Button
                                    className="border-0"
                                    variant="outline-secondary"
                                    onClick={handleOcupationArr}
                                  >
                                    Add
                                  </Button>
                                </InputGroup>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg={8} className="mx-auto">
                            <div className="occupation-keyword">
                              <ul className="d-flex">
                                {occupationArr?.length > 0 &&
                                  occupationArr?.map((occ, i) => {
                                    return (
                                      <li style={{padding:"10px"}}>
                                        {occ}
                                        <button style={{padding:"10px"}} onClick={() => handleremove(i)}>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="9"
                                            viewBox="0 0 9 9"
                                            fill="none"
                                          >
                                            <path
                                              d="M8.36971 1.29971C8.44137 1.23056 8.49855 1.14783 8.5379 1.05634C8.57725 0.964857 8.59798 0.866452 8.59889 0.766868C8.59981 0.667285 8.58088 0.568516 8.54321 0.476327C8.50554 0.384137 8.44989 0.300372 8.37951 0.22992C8.30912 0.159467 8.22541 0.103738 8.13325 0.0659841C8.0411 0.02823 7.94235 0.00920731 7.84276 0.0100254C7.74318 0.0108435 7.64476 0.0314862 7.55323 0.0707492C7.46171 0.110012 7.37893 0.167109 7.30971 0.238708L4.30371 3.24371L1.29871 0.238708C1.23005 0.165022 1.14725 0.105919 1.05525 0.0649275C0.963247 0.0239355 0.863934 0.0018935 0.763231 0.00011672C0.662528 -0.00166006 0.562499 0.0168648 0.469111 0.0545858C0.375723 0.0923068 0.290889 0.148451 0.21967 0.21967C0.148451 0.290889 0.0923068 0.375722 0.0545858 0.46911C0.0168648 0.562499 -0.00166006 0.662528 0.00011672 0.763231C0.0018935 0.863934 0.0239355 0.963247 0.0649275 1.05525C0.105919 1.14725 0.165022 1.23005 0.238708 1.29871L3.24171 4.30471L0.236709 7.30971C0.104229 7.45188 0.0321051 7.63993 0.0355333 7.83423C0.0389615 8.02853 0.117674 8.21392 0.255087 8.35133C0.3925 8.48874 0.577885 8.56745 0.772186 8.57088C0.966487 8.57431 1.15453 8.50219 1.29671 8.36971L4.30371 5.36471L7.30871 8.37071C7.45088 8.50319 7.63893 8.57531 7.83323 8.57188C8.02753 8.56846 8.21292 8.48974 8.35033 8.35233C8.48774 8.21492 8.56645 8.02953 8.56988 7.83523C8.57331 7.64093 8.50119 7.45288 8.36871 7.31071L5.36571 4.30471L8.36971 1.29971Z"
                                              fill="black"
                                            />
                                          </svg>
                                        </button>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </Col>
                          <Col lg={8} className="mx-auto my-5">
                            <p>
                              Your profile will be displayed whenever a person
                              makes a detailed search looking for your
                              profession. Write your keywords separated by a
                              comma so we can find them, like carpenter,
                              bricklayer, electrician, lawyer, accountant,
                              bartender, waiter.
                            </p>
                            <div className="occupation-checkbox">
                              <input
                                type="checkbox"
                                value={tandc}
                                onChange={(e) => setTandc(e.target.checked)}
                                checked={tandc}
                              />
                              <label>
                                By selecting your occupation, you agree to
                                register as a <b>Job/Service Provider</b>
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="Common-sign-button">
                      {/* <Link to="/tell-us-about" className="back-btn">
                        Back
                      </Link> */}
                      <Button
                        onClick={() => {
                          if (!occupation) {
                            toast.error("Occupation is required");
                          } else if (occupationArr?.length === 0) {
                            toast.error("Please enter occupation type");
                          } else if (!tandc) {
                            toast.error("Please accept term and conditions");
                          } else {
                            if (data) {
                              dispatch(
                                ProfileData({
                                  ...data,
                                  occupation,
                                  occupationArr,
                                  tandc,
                                })
                              );
                              navigate("/allow-location");
                            }

                            if (updatedData) {
                              let formData = new FormData();

                              // Append fields from updatedData if they are present
                              if (updatedData?.about)
                                formData.append("about", updatedData.about);
                              if (updatedData?.bodyArt)
                                formData.append(
                                  "body_art",
                                  updatedData.bodyArt
                                );
                              if (updatedData?.bodyType)
                                formData.append(
                                  "body_type",
                                  updatedData.bodyType
                                );
                              if (updatedData?.city)
                                formData.append("city", updatedData.city);
                              if (updatedData?.country)
                                formData.append("country", updatedData.country);
                              if (updatedData?.drink)
                                formData.append("drink", updatedData.drink);
                              if (updatedData?.education)
                                formData.append(
                                  "education",
                                  updatedData.education
                                );
                              if (updatedData?.ethnicity)
                                formData.append(
                                  "ethnicity",
                                  updatedData.ethnicity
                                );
                              if (updatedData?.eyeColor)
                                formData.append(
                                  "eye_color",
                                  updatedData.eyeColor
                                );
                              if (updatedData?.hairColor)
                                formData.append(
                                  "hair_color",
                                  updatedData.hairColor
                                );
                              if (updatedData?.hasChildren !== undefined)
                                formData.append(
                                  "children",
                                  updatedData.hasChildren
                                );
                              if (updatedData?.hasPets !== undefined)
                                formData.append("pets", updatedData.hasPets);
                              if (updatedData?.height)
                                formData.append("height", updatedData.height);
                              if (updatedData?.hobbyArr)
                                formData.append(
                                  "interest_hobbies",
                                  updatedData.hobbyArr
                                );
                              if (updatedData?.image)
                                formData.append("image", updatedData.image);
                              if (updatedData?.images)
                                updatedData?.images?.forEach((x) =>
                                  formData.append(`images`, x)
                                );
                              // formData.append("images", updatedData.images);
                              // updatedData?.images?.forEach((x) =>
                              //   console.log(`images`, x)
                              // );
                              if (updatedData?.languageAbility)
                                formData.append(
                                  "education_lang_ability",
                                  updatedData.languageAbility
                                );
                              if (updatedData?.languageSpoken)
                                formData.append(
                                  "language_spoken",
                                  updatedData.languageSpoken
                                );
                              if (updatedData?.lifestyle)
                                formData.append(
                                  "looking_for",
                                  updatedData.lifestyle
                                );
                              if (updatedData?.maritalStatus !== undefined)
                                formData.append(
                                  "marital_status",
                                  +updatedData.maritalStatus
                                );
                              if (updatedData?.messagePreference)
                                formData.append(
                                  "preference_receive_message",
                                  updatedData.messagePreference
                                );
                              if (updatedData?.name)
                                formData.append("name", updatedData.name);
                              if (updatedData?.nationality)
                                formData.append(
                                  "nationality",
                                  updatedData.nationality
                                );
                              if (updatedData?.nickName)
                                formData.append(
                                  "nick_name",
                                  updatedData.nickName
                                );
                              if (updatedData?.numberOfChildren !== undefined)
                                formData.append(
                                  "number_of_children",
                                  updatedData.numberOfChildren
                                );
                              if (updatedData?.occupation)
                                formData.append(
                                  "basic_occupation",
                                  updatedData.occupation
                                );
                              if (updatedData?.oldestChild)
                                formData.append(
                                  "oldest_children",
                                  updatedData.oldestChild
                                );
                              if (updatedData?.postSee)
                                formData.append(
                                  "post_see",
                                  updatedData.postSee
                                );
                              if (updatedData?.profilePreference)
                                formData.append(
                                  "profile_preference",
                                  updatedData.profilePreference
                                );
                              if (updatedData?.religion)
                                formData.append(
                                  "religion",
                                  updatedData.religion
                                );
                              if (updatedData?.showOnProfile !== undefined)
                                formData.append(
                                  "show_marital_status",
                                  updatedData.showOnProfile
                                );
                              if (updatedData?.smoke)
                                formData.append("smoke", updatedData.smoke);
                              if (updatedData?.sport)
                                formData.append("sport", updatedData.sport);
                              if (updatedData?.starSign)
                                formData.append(
                                  "star_sign",
                                  updatedData.starSign
                                );
                              if (updatedData?.state)
                                formData.append("state", updatedData.state);
                              if (updatedData?.wantMoreKids !== undefined)
                                formData.append(
                                  "more_kids",
                                  updatedData.wantMoreKids
                                );
                              if (updatedData?.weight)
                                formData.append("weight", updatedData.weight);
                              if (updatedData?.willingToMove)
                                formData.append(
                                  "willing_to_move",
                                  updatedData.willingToMove
                                );
                              if (updatedData?.youngestChild)
                                formData.append(
                                  "youngest_children",
                                  updatedData.youngestChild
                                );
                              if (updatedData?.identityNumber)
                                formData.append(
                                  "identity_number",
                                  updatedData.identityNumber
                                );

                              // Append additional fields if they are present
                              if (occupation)
                                formData.append("occupation", occupation);
                              if (occupationArr)
                                formData.append(
                                  "occupation_types",
                                  occupationArr
                                );

                              mutate(formData);
                            }
                          }
                        }}
                        to=""
                        className="continue-btn"
                      >
                        {data ? "Continue" : "Save"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
