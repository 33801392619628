// src/components/GoogleLogin.js
import React, { useEffect } from "react";
import { signInWithPopup } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useMutationHook from "../myHooks/mutationHook";
import { auth, googleProvider } from "../utils/Firebase";
import { socialLogin } from "../redux/Actions/userActions";
const GoogleLogin = () => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  // ============================socilaLogin login====================================================

  const { mutate, isPending, isError, isSuccess, data } =
    useMutationHook(socialLogin);

  useEffect(() => {
    if (isSuccess) {
      console.log(data, "data");
      if (data?.status === 200) {
        sessionStorage.setItem("token", data?.data?.token);
        // dispatch(userIdforGetDetails(data?.data?.data?._id));
        if (data?.data?.user_detail?.profile_completed===false) {
          window.location.href = "/profile-details";
        } else {
          window.location.href = "/dashboard";
        }
      }
    }
  }, [isSuccess]);

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("hii");
    try {
      console.log("first");
      const result = await signInWithPopup(auth, googleProvider);
      console.log("User Info: ", result?.user);
      if (result?.user) {
        mutate({
          social_token: result?.user?.uid,
          // full_name: result?.user?.displayName,
          // login_type: 2,
          device_token: result?.user?.accessToken,
          social_type:"GOOGLE"
          // device_type: 2,
          // email: result?.user?.email,
          // name: result?.user?.displayName,
        });
      }
    } catch (error) {
      console.error("Error during login: ", error.message);
    }
  };

  return (
    <div>
      <div
        onClick={handleLogin}
        style={{ cursor: "pointer" }}
        className="goggle-bnt"
      >
        <Link className="social-btn">
          {isPending ? (
            <div className="loader"></div>
          ) : (
            <>
              <img
                src={require("../Assets/images/google-icon.png")}
                alt="google logo"
              />
              Continue with Google
            </>
          )}
        </Link>
      </div>
    </div>
  );
};

export default GoogleLogin;
