import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";



const filterSlice = createSlice({
  name: "filterSlice",
  initialState: {
    filterState: {},
    advancefilterState: {},
    friendshipState:{},
    travelfilterState:{},
    jobfilterState:{},
    sportsfilterState:{}
  },
  reducers: {
    filters: (state, action) => {
      state.filterState = action.payload;
    },
    advanceFilter: (state, action) => {
      state.advancefilterState = action.payload;
    },
  
    friendShipFilters: (state, action) => {
      state.friendshipState = action.payload;
    },
    travelFilters:(state, action) => {
      state.travelfilterState = action.payload;
    },
    jobFilters:(state, action) => {
      state.jobfilterState = action.payload;
    },
    sportsFilters:(state, action) => {
      state.sportsfilterState = action.payload;
    },
  },
 
    
   


  
  
});



export const { filters, advanceFilter,friendShipFilters,travelFilters,jobFilters,sportsFilters } = filterSlice.actions;

export default filterSlice;
