import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";

export default function Landing() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const settings = {
    slidesToShow: 3,
    swipeToSlide: true, // Enable swiping
    focusOnSelect: true,
    nav: false,
    vertical: true,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 768, // Example breakpoint for tablets and mobile devices
        settings: {
          vertical: false, // Disable vertical swiping on smaller screens
          verticalSwiping: false,
          slidesToShow: 3, // Show one slide at a time on mobile
          swipeToSlide: true, // Enable horizontal swiping on mobile
        },
      },
    ],
  };
  return (
    <>
      <section className="header-sec">
        <Container>
          <div className="main-navbar">
            <Navbar expand="lg">
              <Navbar.Brand href="#">
                <img src={require("../Assets/images/logo.svg").default} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="mx-auto my-2 my-lg-0"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  <Nav.Link href="/profile-details">Tab 1</Nav.Link>
                  <Nav.Link href="#action2">Tab 2</Nav.Link>
                  <Nav.Link href="#action3">Tab 3</Nav.Link>
                  <Nav.Link href="#action4">Tab 4</Nav.Link>
                  <Nav.Link href="#action5">Tab 5</Nav.Link>
                  <Nav.Link href="#action6">Tab 6</Nav.Link>
                </Nav>
                <Form className="d-flex">
                  <Link
                    to="/login"
                    variant="outline-success"
                    className="theme-btn"
                  >
                    Login
                  </Link>
                </Form>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </Container>
      </section>
      <section className="banner-sec">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="banner-text-side">
                <h1>
                  Your Online Hub for <span>Connections</span> & Conversations
                </h1>
                <p>
                  Connect with friends, share your passions, and explore new
                  horizons at Frizapp. Join us and start shaping your social
                  experience today.{" "}
                </p>
                <Link to="/sign-up">
                  Join Now{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <rect
                      width="40"
                      height="40"
                      rx="10"
                      fill="url(#paint0_linear_2919_25337)"
                    />
                    <path
                      d="M26.9974 20.1389C26.9322 20.3838 26.7734 20.559 26.6044 20.7328C24.9914 22.3884 23.3822 24.0478 21.7705 25.7047C21.4735 26.0102 21.0971 26.1011 20.7975 25.8706C20.5236 25.6612 20.2765 25.3991 20.0666 25.1225C19.8694 24.8618 19.9258 24.493 20.1331 24.2375C20.1741 24.1874 20.2189 24.14 20.2637 24.0939C21.1867 23.147 22.1097 22.2013 23.0327 21.2544C23.066 21.2202 23.098 21.182 23.1492 21.1253C23.0686 21.1253 23.0161 21.1253 22.9636 21.1253C19.9386 21.1253 16.9122 21.1253 13.8872 21.1253C13.3072 21.1253 13 20.8106 13 20.2179C13 20.0585 13 19.8992 13 19.7398C13.0013 19.1853 13.2996 18.8745 13.836 18.8745C15.385 18.8745 16.934 18.8745 18.4843 18.8745C19.9693 18.8745 21.4556 18.8745 22.9406 18.8745C22.9905 18.8745 23.0391 18.8745 23.1223 18.8745C23.0711 18.8192 23.0391 18.7836 23.0059 18.7494C22.079 17.7932 21.1509 16.8384 20.2253 15.8822C19.9334 15.5806 19.8464 15.1829 20.0691 14.8773C20.274 14.5968 20.5287 14.3426 20.7988 14.1266C21.0574 13.9211 21.4197 13.9804 21.6719 14.199C21.7206 14.2412 21.7654 14.2873 21.8114 14.3334C23.4065 15.9744 25.0004 17.6154 26.598 19.2538C26.7708 19.4303 26.9347 19.6081 27 19.8583C26.9974 19.9518 26.9974 20.0454 26.9974 20.1389Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2919_25337"
                        x1="5.96046e-07"
                        y1="20"
                        x2="40"
                        y2="20"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#2D5FE5" />
                        <stop offset="0.51" stop-color="#EF117E" />
                        <stop offset="1" stop-color="#3BAD45" />
                      </linearGradient>
                    </defs>
                  </svg>
                </Link>
              </div>
            </Col>
            <Col lg={6}>
              <div className="banner-img-side">
                <img src={require("../Assets/images/banner-img-side.png")} />
                <div className="banner-img-pis">
                  <img src={require("../Assets/images/banner-img-pis.png")} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="connect-forever-sec">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="connect-forever-img">
                <img
                  src={require("../Assets/images/connect-forever-img.png")}
                />
                <div className="connect-forever-part">
                  <img
                    src={require("../Assets/images/connect-forever-part-img.png")}
                  />
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="connect-forever-text">
                <h2>Share life’s moments, Connect forever.</h2>
                <p>
                  Improve your social life with our innovative app, designed to
                  make distances between people a distant memory. Thanks to its
                  intuitive interface and advanced features, connecting with old
                  friends and meeting new ones has never been easier. Our
                  platform is the ideal place to share the moments you care
                  about, spark interesting discussions, and discover unexpected
                  passions. Whether you’re looking for inspiration, support, or
                  just someone to share a smile with, our app helps you create
                  deep, lasting connections. Join us and start building
                  connections. Join u and start building connections that will
                  enrich your life, day after day.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="screen-sec">
        <Container fluid className="p-0">
          <div className="screen-sec-box position-relative">
            <h2>Screens</h2>
            <img src={require("../Assets/images/screen-sec-img.png")} />
          </div>
        </Container>
      </section>

      <section className="testimonials-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="slider-container">
                <Row>
                  <Col lg={12}>
                    <div className="testimonials-head">
                      <h2>Testimonials</h2>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center position-relative">
                  <Col lg={10}>
                    <Slider
                      asNavFor={nav2}
                      ref={(slider) => (sliderRef1 = slider)}
                      nav={false}
                      dots={false}
                    >
                      <div className="slider-wrap">
                        <div className="testimonials-card">
                          <img
                            src={require("../Assets/images/testimonials-b-img.png")}
                          />
                          <div className="testimonials-card-text">
                            <h2>Arianna Adele</h2>
                            <p>
                              Lorem Ipsum is simply dummy text for printing and
                              typesetting industry. Lorem Ipsum has been
                              industry’s standard dummy text ever since 1500s,
                              when and unknown printer took galley of it.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="slider-wrap">
                        <div className="testimonials-card">
                          <img
                            src={require("../Assets/images/testimonials-b-img.png")}
                          />
                          <div className="testimonials-card-text">
                            <h2>Arianna Adele</h2>
                            <p>
                              Lorem Ipsum is simply dummy text for printing and
                              typesetting industry. Lorem Ipsum has been
                              industry’s standard dummy text ever since 1500s,
                              when and unknown printer took galley of it.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="slider-wrap">
                        <div className="testimonials-card">
                          <img
                            src={require("../Assets/images/testimonials-b-img.png")}
                          />
                          <div className="testimonials-card-text">
                            <h2>Arianna Adele</h2>
                            <p>
                              Lorem Ipsum is simply dummy text for printing and
                              typesetting industry. Lorem Ipsum has been
                              industry’s standard dummy text ever since 1500s,
                              when and unknown printer took galley of it.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="slider-wrap">
                        <div className="testimonials-card">
                          <img
                            src={require("../Assets/images/testimonials-b-img.png")}
                          />
                          <div className="testimonials-card-text">
                            <h2>Arianna Adele</h2>
                            <p>
                              Lorem Ipsum is simply dummy text for printing and
                              typesetting industry. Lorem Ipsum has been
                              industry’s standard dummy text ever since 1500s,
                              when and unknown printer took galley of it.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="slider-wrap">
                        <div className="testimonials-card">
                          <img
                            src={require("../Assets/images/testimonials-b-img.png")}
                          />
                          <div className="testimonials-card-text">
                            <h2>Arianna Adele</h2>
                            <p>
                              Lorem Ipsum is simply dummy text for printing and
                              typesetting industry. Lorem Ipsum has been
                              industry’s standard dummy text ever since 1500s,
                              when and unknown printer took galley of it.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="slider-wrap">
                        <div className="testimonials-card">
                          <img
                            src={require("../Assets/images/testimonials-b-img.png")}
                          />
                          <div className="testimonials-card-text">
                            <h2>Arianna Adele</h2>
                            <p>
                              Lorem Ipsum is simply dummy text for printing and
                              typesetting industry. Lorem Ipsum has been
                              industry’s standard dummy text ever since 1500s,
                              when and unknown printer took galley of it.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </Col>
                  <Col lg={2}>
                    <Slider
                      {...settings}
                      asNavFor={nav1}
                      ref={(slider) => (sliderRef2 = slider)}
                      slidesToShow={3}
                      swipeToSlide={false}
                      focusOnSelect={true}
                      nav={false}
                      vertical={true}
                      verticalSwiping={true}
                      className="testimonials-small-img-list"
                    >
                      <div className="slider-nav testimonials-small-img">
                        <img
                          style={{ cursor: "pointer" }}
                          src={require("../Assets/images/testimonials-b-img.png")}
                        />
                      </div>
                      <div className="slider-nav testimonials-small-img">
                        <img
                          style={{ cursor: "pointer" }}
                          src={require("../Assets/images/testimonials-b-img.png")}
                        />
                      </div>
                      <div className="slider-nav testimonials-small-img">
                        <img
                          style={{ cursor: "pointer" }}
                          src={require("../Assets/images/testimonials-b-img.png")}
                        />
                      </div>
                      <div className="slider-nav testimonials-small-img">
                        <img
                          style={{ cursor: "pointer" }}
                          src={require("../Assets/images/testimonials-b-img.png")}
                        />
                      </div>
                      <div className="slider-nav testimonials-small-img">
                        <img
                          style={{ cursor: "pointer" }}
                          src={require("../Assets/images/testimonials-b-img.png")}
                        />
                      </div>
                      <div className="slider-nav testimonials-small-img">
                        <img
                          style={{ cursor: "pointer" }}
                          src={require("../Assets/images/testimonials-b-img.png")}
                        />
                      </div>
                    </Slider>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Get The App section  */}

      <section className="get-the-app-sec">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="get-the-app-text">
                <h2>Get The App</h2>
                <p>
                  Lorem Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem Ipsum has been industry’s standard dummy text
                  ever since 1500s, when and unknown printer took galley of it.
                  Lorem Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem Ipsum is simply dummy text for printing and
                  typesetting industry. Lorem Ipsum is simply dummy text for
                  printing and typesetting industry.
                </p>
                <div className="play-store-links">
                  <img
                    src={require("../Assets/images/app-store.svg").default}
                  />
                  <img
                    src={require("../Assets/images/play-store.svg").default}
                  />
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="get-the-app-img">
                <img src={require("../Assets/images/get-app-img.png")} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Footer section  */}

      <section className="footer-sec position-relative">
        <Container>
          <Row className="align-items-center ">
            <Col lg={12}>
              <div className="footer-logo">
                <img src={require("../Assets/images/logo.svg").default} />
              </div>
            </Col>
            <Col lg={4}>
              <div className="footer-list">
                <h3>Legal</h3>
                <ul>
                  <li>Privacy</li>
                  <li>Privacy</li>
                  <li>Privacy</li>
                  <li>Privacy</li>
                </ul>
              </div>
            </Col>
            <Col lg={4}>
              <div className="footer-list">
                <h3>Legal</h3>
                <ul>
                  <li>Privacy</li>
                  <li>Privacy</li>
                  <li>Privacy</li>
                  <li>Privacy</li>
                </ul>
              </div>
            </Col>
            <Col lg={4}>
              <div className="footer-list">
                <h3>Legal</h3>
                <ul>
                  <li>Privacy</li>
                  <li>Privacy</li>
                  <li>Privacy</li>
                  <li>Privacy</li>
                </ul>
              </div>
            </Col>
            <Col lg={12}>
              <div className="footer-social-icons">
                <ul>
                  <li>
                    <img
                      src={require("../Assets/images/fb-icon.svg").default}
                    />
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={12}>
              <div className="copy-right-line">
                <p>Copyright © 2024. All Rights Reserved. </p>
              </div>
            </Col>
            <div className="footer-img-side">
              <img src={require("../Assets/images/footer-bottom-img.png")} />
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}
