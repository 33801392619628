import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";

const useQueryHook = (queryKey, queryFn, options = {}) => {
  const { data, error, isLoading, isError, isSuccess, refetch } = useQuery({
    queryKey,
    queryFn,
    ...options,

    // {
    //   onSuccess: (data) => {
    //     toast.success("Data fetched successfully");
    //   },
    onError: (error) => {
      toast.error("Something went wrong");
    },
    // }
  });

  return { data, error, isLoading, isError, isSuccess, refetch };
};

export default useQueryHook;
