import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import Slider from "react-slick";
import Tab from "react-bootstrap/Tab";

export default function RestrictPerson() {
  var settingsTwo = {
    dots: true,
    infinite: false,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };
  return (
    <Layout>
      <div className="right-side">
        <div className="right-side-flow">
          <div className="main-content">
            <div className="full-view-content">
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link to="/dashboard" className="back-right-part">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    {/* <div className="online">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill="#3BAD45" />
                      </svg>
                      Online
                    </div> */}
                  </div>
                  <div className="follow-main-data">
                    <div className="commn-form-title">
                      <h2>Notification</h2>
                    </div>
                    <div className="likes-list-contain">
                      <div className="likes-list-card">
                        <div className="likes-list-pro">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="">
                            <h5>Devid,23</h5>
                            <p>Chandigarh,India</p>
                          </div>
                        </div>
                      </div>
                      <div className="likes-list-card">
                        <div className="likes-list-pro">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="">
                            <h5>Devid,23</h5>
                            <p>Chandigarh,India</p>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="likes-list-card">
                        <div className="likes-list-pro">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="">
                            <h5>Devid,23</h5>
                            <p>Chandigarh,India</p>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="likes-list-card">
                        <div className="likes-list-pro">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="">
                            <h5>Devid,23</h5>
                            <p>Chandigarh,India</p>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="likes-list-card">
                        <div className="likes-list-pro">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="">
                            <h5>Devid,23</h5>
                            <p>Chandigarh,India</p>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="likes-list-card">
                        <div className="likes-list-pro">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="">
                            <h5>Devid,23</h5>
                            <p>Chandigarh,India</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
