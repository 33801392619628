import React from 'react'
import { useDispatch } from 'react-redux';
import useQueryHook from '../myHooks/queryHook';
import { profile } from '../redux/Actions/userActions';

function ProfilePicture() {

   
    const dispatch = useDispatch();
    let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  
    // =================================================GET PROFILE DATA========================================
  
    const { data: profiledata } = useQueryHook(["getUserById"], profile);
  
    console.log(profiledata, "data");
  return (
    <div className="commn-profile-show">
    <img
        style={{ borderRadius: "50%" }}
        src={`${BASE_URL}public/${profiledata?.data?.image}`}
        alt="logo"
      />
      <h2>
        {profiledata?.data?.name} {profiledata?.data?.sur_name}
        {profiledata?.data?.age
          ? ", " + profiledata?.data?.age
          : ""}
      </h2>
      {/* <div className="">
        Change Profile Picture
        <input type="file" />
      </div> */}
    </div>
  )
}

export default ProfilePicture