import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editprofile } from "../redux/Actions/userActions";
import useMutationHook from "../myHooks/mutationHook";
import { EditProfileData, ProfileData } from "../redux/Reducers/profileSlice";
import Loader from "../Components/Loader";

export default function SubscriptionPlans() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state?.profileData?.profiledata);
  console.log(data, "prevData");

  const updatedData = useSelector(
    (state) => state?.profileData?.editprofiledata
  );
  console.log(updatedData, "updatedData");

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    margin: "12px",
  };

  // ===============================================editProfile api==========================================

  const {
    mutate,
    isPending,
    isSuccess,
    data: editProfileData,
  } = useMutationHook(editprofile);

  useEffect(() => {
    if (editProfileData?.status === 200) {
      console.log(editProfileData, "datdatadtad");
      // sessionStorage.setItem("token", data?.data?.token);
      // if (!data?.user_detail?.profile_completed) {
      updatedData?.seekingFor ? navigate("/filters") : navigate("/dashboard");
      dispatch(EditProfileData(null));
      dispatch(ProfileData(null));
      // }
    }
  }, [isSuccess]);

  return (
    <>
      {isPending && <Loader />}
      <section className="Common-sign-flow">
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="text-center mb-3">
                <img
                  className="Common-sign-logo"
                  src={require("../Assets/images/logo.svg").default}
                  alt="logo"
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="page-back-icon">
                <Link
                  to={
                    updatedData?.seekingFor
                      ? "/profile-preferences"
                      : updatedData?.withoutAds == 1
                      ? "/plans-for-advertising"
                      : updatedData?.plan
                      ? `/plans-for-jobs`
                      : data?.withoutAds == 1
                      ? `/profile-preference-two`
                      : `/profile-preference`
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                      fill="black"
                    />
                  </svg>
                  Back
                </Link>
              </div>
              <div className="Common-sign-boxout">
                <div className="follow-main-data">
                  <div className="commn-form-feild">
                    <div className="commn-form-title mb-4">
                      <h2>Plans for you!</h2>
                      <p>Subscribe to exciting world!</p>
                    </div>
                    <Row className="mb-3">
                      <Col lg={12}>
                        <div className="plans-feed-box">
                          <Slider {...settings}>
                            <div className="plans-select-box enjoy-color-slide">
                              <h3>Enjoy</h3>
                              <p>Plan for advertising</p>
                              <div className="subscribe-plan-price-box">
                                <div>
                                  <h2>$10.99</h2>
                                  <div>for 1 month</div>
                                </div>
                              </div>
                            </div>
                            <div className="plans-select-box essential-color-slide">
                              <h3>Essential</h3>
                              <p>Plan for work</p>
                              <div className="subscribe-plan-price-box">
                                <div>
                                  <h2>$10.99</h2>
                                  <div>for 1 month</div>
                                </div>
                              </div>
                            </div>
                            <div className="plans-select-box recommended-color-slide">
                              <h3>Recommended</h3>
                              <p>Plan for work</p>
                              <div className="subscribe-plan-price-box">
                                <div>
                                  <h2>$10.99</h2>
                                  <div>for 1 month</div>
                                </div>
                              </div>
                            </div>
                            <div className="plans-select-box cupid-color-slide">
                              <h3>Cupid</h3>
                              <p>Plan for Dating</p>
                              <div className="subscribe-plan-price-box">
                                <div>
                                  <h2>$10.99</h2>
                                  <div>for 1 month</div>
                                </div>
                              </div>
                            </div>
                          </Slider>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg={12}>
                        <div className="subscribe-plan-desc">
                          <div>What you get</div>
                          <p>
                            The plan allows to be at the top of search profiles
                            and also has 3d scan for profile certification,
                            remove advertising
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <div className="common-form-btn">
                      <Button
                        onClick={() => {
                          if (updatedData?.withoutAds == "1") {
                            let formData = new FormData();

                            // Append fields from updatedData if they are present
                            if (updatedData?.withoutAds !== undefined)
                              formData.append(
                                "without_advertise",
                                updatedData.withoutAds
                              );
                            if (updatedData?.about)
                              formData.append("about", updatedData.about);
                            if (updatedData?.bodyArt)
                              formData.append("body_art", updatedData.bodyArt);
                            if (updatedData?.bodyType)
                              formData.append(
                                "body_type",
                                updatedData.bodyType
                              );
                            if (updatedData?.city)
                              formData.append("city", updatedData.city);
                            if (updatedData?.country)
                              formData.append("country", updatedData.country);
                            if (updatedData?.drink)
                              formData.append("drink", updatedData.drink);
                            if (updatedData?.education)
                              formData.append(
                                "education",
                                updatedData.education
                              );
                            if (updatedData?.ethnicity)
                              formData.append(
                                "ethnicity",
                                updatedData.ethnicity
                              );
                            if (updatedData?.eyeColor)
                              formData.append(
                                "eye_color",
                                updatedData.eyeColor
                              );
                            if (updatedData?.hairColor)
                              formData.append(
                                "hair_color",
                                updatedData.hairColor
                              );
                            if (updatedData?.hasChildren !== undefined)
                              formData.append(
                                "children",
                                updatedData.hasChildren
                              );
                            if (updatedData?.hasPets !== undefined)
                              formData.append("pets", updatedData.hasPets);
                            if (updatedData?.height)
                              formData.append("height", updatedData.height);
                            if (updatedData?.hobbyArr)
                              formData.append(
                                "interest_hobbies",
                                updatedData.hobbyArr
                              );
                            if (updatedData?.image)
                              formData.append("image", updatedData.image);
                            if (updatedData?.images)
                              updatedData?.images?.forEach((x) =>
                                formData.append(`images`, x)
                              );
                            if (updatedData?.languageAbility)
                              formData.append(
                                "education_lang_ability",
                                updatedData.languageAbility
                              );
                            if (updatedData?.languageSpoken)
                              formData.append(
                                "language_spoken",
                                updatedData.languageSpoken
                              );
                            if (updatedData?.lifestyle)
                              formData.append(
                                "looking_for",
                                updatedData.lifestyle
                              );
                            if (updatedData?.maritalStatus !== undefined)
                              formData.append(
                                "marital_status",
                                +updatedData.maritalStatus
                              );
                            if (updatedData?.messagePreference)
                              formData.append(
                                "preference_receive_message",
                                updatedData.messagePreference
                              );
                            if (updatedData?.name)
                              formData.append("name", updatedData.name);
                            if (updatedData?.nationality)
                              formData.append(
                                "nationality",
                                updatedData.nationality
                              );
                            if (updatedData?.nickName)
                              formData.append(
                                "nick_name",
                                updatedData.nickName
                              );
                            if (updatedData?.numberOfChildren !== undefined)
                              formData.append(
                                "number_of_children",
                                updatedData.numberOfChildren
                              );
                            if (updatedData?.occupation)
                              formData.append(
                                "basic_occupation",
                                updatedData.occupation
                              );
                            if (updatedData?.oldestChild)
                              formData.append(
                                "oldest_children",
                                updatedData.oldestChild
                              );
                            if (updatedData?.postSee)
                              formData.append("post_see", updatedData.postSee);
                            if (
                              updatedData?.profilePreference == "0" ||
                              updatedData?.profilePreference == "1"
                            )
                              formData.append(
                                "profile_preference",
                                updatedData.profilePreference
                              );
                            if (updatedData?.religion)
                              formData.append("religion", updatedData.religion);
                            if (updatedData?.showOnProfile !== undefined)
                              formData.append(
                                "show_marital_status",
                                updatedData.showOnProfile
                              );
                            if (updatedData?.smoke)
                              formData.append("smoke", updatedData.smoke);
                            if (updatedData?.sport)
                              formData.append("sport", updatedData.sport);
                            if (updatedData?.starSign)
                              formData.append(
                                "star_sign",
                                updatedData.starSign
                              );
                            if (updatedData?.state)
                              formData.append("state", updatedData.state);
                            if (updatedData?.wantMoreKids !== undefined)
                              formData.append(
                                "more_kids",
                                updatedData.wantMoreKids
                              );
                            if (updatedData?.weight)
                              formData.append("weight", updatedData.weight);
                            if (updatedData?.willingToMove)
                              formData.append(
                                "willing_to_move",
                                updatedData.willingToMove
                              );
                            if (updatedData?.youngestChild)
                              formData.append(
                                "youngest_children",
                                updatedData.youngestChild
                              );

                            mutate(formData);
                          } else if (data?.withoutAds == "1") {
                            let formData = new FormData();
                            formData.append(
                              "without_advertise",
                              data?.withoutAds
                            );
                            formData.append("profile_completed", true);
                            formData.append("sur_name", data?.lastName);
                            formData.append("nick_name", data?.nickName);
                            formData.append("image", data?.image);

                            data?.images?.forEach((x) =>
                              formData.append(`images`, x?.image[0])
                            );
                            data?.images?.forEach((x) =>
                              console.log(`images`, x?.image[0])
                            );

                            formData.append("name", data?.firstName);
                            formData.append("country", data?.country);
                            formData.append("state", data?.state);
                            formData.append("city", data?.city);
                            formData.append("gender", data?.gender);
                            formData.append("show_gender", data?.showGender);
                            formData.append(
                              "looking_for_soulmate",
                              data?.lookingForSoulmate
                            );
                            formData.append("about", data?.about);
                            formData.append(
                              "profile_preference",
                              data?.profilePreference
                            );

                            mutate(formData);
                          } else {
                            if (updatedData?.seekingFor) {
                              // Navigate to /filters

                              let formData = new FormData();

                              // Append fields from updatedData if they are present
                              if (updatedData?.withoutAds !== undefined)
                                formData.append(
                                  "without_advertise",
                                  updatedData.withoutAds
                                );
                              if (updatedData?.about)
                                formData.append("about", updatedData.about);
                              if (updatedData?.bodyArt)
                                formData.append(
                                  "body_art",
                                  updatedData.bodyArt
                                );
                              if (updatedData?.bodyType)
                                formData.append(
                                  "body_type",
                                  updatedData.bodyType
                                );
                              if (updatedData?.city)
                                formData.append("city", updatedData.city);
                              if (updatedData?.country)
                                formData.append("country", updatedData.country);
                              if (updatedData?.drink)
                                formData.append("drink", updatedData.drink);
                              if (updatedData?.education)
                                formData.append(
                                  "education",
                                  updatedData.education
                                );
                              if (updatedData?.ethnicity)
                                formData.append(
                                  "ethnicity",
                                  updatedData.ethnicity
                                );
                              if (updatedData?.eyeColor)
                                formData.append(
                                  "eye_color",
                                  updatedData.eyeColor
                                );
                              if (updatedData?.hairColor)
                                formData.append(
                                  "hair_color",
                                  updatedData.hairColor
                                );
                              if (updatedData?.hasChildren !== undefined)
                                formData.append(
                                  "children",
                                  updatedData.hasChildren
                                );
                              if (updatedData?.hasPets !== undefined)
                                formData.append("pets", updatedData.hasPets);
                              if (updatedData?.height)
                                formData.append("height", updatedData.height);
                              if (updatedData?.hobbyArr)
                                formData.append(
                                  "interest_hobbies",
                                  updatedData.hobbyArr
                                );
                              if (updatedData?.image)
                                formData.append("image", updatedData.image);
                              if (updatedData?.images)
                                updatedData?.images?.forEach((x) =>
                                  formData.append(`images`, x)
                                );
                              if (updatedData?.languageAbility)
                                formData.append(
                                  "education_lang_ability",
                                  updatedData.languageAbility
                                );
                              if (updatedData?.languageSpoken)
                                formData.append(
                                  "language_spoken",
                                  updatedData.languageSpoken
                                );
                              if (updatedData?.lifestyle)
                                formData.append(
                                  "looking_for",
                                  updatedData.lifestyle
                                );
                              if (updatedData?.maritalStatus !== undefined)
                                formData.append(
                                  "marital_status",
                                  +updatedData.maritalStatus
                                );
                              if (updatedData?.messagePreference)
                                formData.append(
                                  "preference_receive_message",
                                  updatedData.messagePreference
                                );
                              if (updatedData?.name)
                                formData.append("name", updatedData.name);
                              if (updatedData?.nationality)
                                formData.append(
                                  "nationality",
                                  updatedData.nationality
                                );
                              if (updatedData?.nickName)
                                formData.append(
                                  "nick_name",
                                  updatedData.nickName
                                );
                              if (updatedData?.numberOfChildren !== undefined)
                                formData.append(
                                  "number_of_children",
                                  updatedData.numberOfChildren
                                );
                              if (updatedData?.occupation)
                                formData.append(
                                  "basic_occupation",
                                  updatedData.occupation
                                );
                              if (updatedData?.oldestChild)
                                formData.append(
                                  "oldest_children",
                                  updatedData.oldestChild
                                );
                              if (updatedData?.postSee)
                                formData.append(
                                  "post_see",
                                  updatedData.postSee
                                );
                              if (
                                updatedData?.profilePreference == "0" ||
                                updatedData?.profilePreference == "1"
                              )
                                formData.append(
                                  "profile_preference",
                                  updatedData.profilePreference
                                );
                              if (updatedData?.religion)
                                formData.append(
                                  "religion",
                                  updatedData.religion
                                );
                              if (updatedData?.showOnProfile !== undefined)
                                formData.append(
                                  "show_marital_status",
                                  updatedData.showOnProfile
                                );
                              if (updatedData?.smoke)
                                formData.append("smoke", updatedData.smoke);
                              if (updatedData?.sport)
                                formData.append("sport", updatedData.sport);
                              if (updatedData?.starSign)
                                formData.append(
                                  "star_sign",
                                  updatedData.starSign
                                );
                              if (updatedData?.state)
                                formData.append("state", updatedData.state);
                              if (updatedData?.wantMoreKids !== undefined)
                                formData.append(
                                  "more_kids",
                                  updatedData.wantMoreKids
                                );
                              if (updatedData?.weight)
                                formData.append("weight", updatedData.weight);
                              if (updatedData?.willingToMove)
                                formData.append(
                                  "willing_to_move",
                                  updatedData.willingToMove
                                );
                              if (updatedData?.youngestChild)
                                formData.append(
                                  "youngest_children",
                                  updatedData.youngestChild
                                );

                              mutate(formData);
                              // navigate("/filters");
                            } else if (
                              data?.profilePreference === 1 ||
                              updatedData?.profilePreference === 1
                            ) {
                              // Navigate to /identify-yourself
                              navigate("/identify-yourself");
                            } else {
                              // Redirect to /dashboard
                              window.location.href = "/dashboard"; // Use replace to avoid login page in history
                            }
                          }
                        }}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
