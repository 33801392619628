import { createSlice } from "@reduxjs/toolkit";

const ProfileSlice = createSlice({
    name:"profileSlice",
    initialState:{
        profiledata:null,
        editprofiledata:null,

    },
    reducers:{
        ProfileData:(state,action)=>{
            
            state.profiledata=action.payload
        },
        EditProfileData:(state,action)=>{
            
            state.editprofiledata=action.payload
        }
    }
})

export const { ProfileData,EditProfileData} = ProfileSlice.actions


export default ProfileSlice