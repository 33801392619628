import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import toast from "react-hot-toast";
import useMutationHook from "../myHooks/mutationHook";
import { changePassword } from "../redux/Actions/userActions";
import Loader from "../Components/Loader";

// import { useNavigate } from "react-router-dom";

export default function ChangePasswordSettings() {
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [show, setshow] = useState(false);
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);

  // ===============================================login api==========================================

  const { mutate, isPending, isSuccess, data } =
    useMutationHook(changePassword);

  useEffect(() => {
    if (data?.status === 200) {
      console.log(data, "datdatadtad");
      // toast.success(data?.status);
      navigate("/settings");
    }
  }, [isSuccess]);

  // const handleRedirect = () => {
  //   navigate("/plans-for-you"); // Replace '/target-page' with your target route
  // };

  return (
    <Layout>
      {isPending && <Loader />}
      <div className="right-side">
        {/* <div className="commn-right-top">
          <div className="search-nav-bar">
            <input type="search" placeholder="Search here..." />
          </div>
          <div className="notify-bell">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="30"
              viewBox="0 0 28 30"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M14.3331 0.00156536C14.4077 0.0367861 14.4814 0.0735721 14.5568 0.105662C15.0768 0.324813 15.3733 0.684847 15.3935 1.20298C15.4084 1.59276 15.4057 1.9841 15.397 2.37387C15.3935 2.5398 15.4478 2.60946 15.6443 2.64155C19.7215 3.30292 22.7604 5.92177 23.6032 9.53463C23.7471 10.1498 23.7778 10.7924 23.7962 11.4232C23.8348 12.7397 23.7672 14.0617 23.8584 15.3742C24.0146 17.6197 25.0959 19.4935 26.9692 20.9947C27.5095 21.4275 27.9243 21.9104 27.9892 22.5741C28.1173 23.8906 27.092 24.9276 25.6055 24.9989C25.5178 25.0028 25.4309 25.002 25.3432 25.002C17.7851 25.002 10.2269 25.0004 2.66877 25.0043C1.79701 25.0051 1.05856 24.7656 0.512174 24.1387C-0.301701 23.2057 -0.132436 21.9245 0.926127 21.0972C1.84963 20.374 2.60211 19.5342 3.16516 18.5605C3.88081 17.3223 4.20618 16.0019 4.20881 14.6135C4.21145 13.4128 4.19303 12.2122 4.21846 11.0116C4.30441 6.90169 7.76513 3.32405 12.3037 2.64938C12.5616 2.61103 12.6317 2.52806 12.6159 2.31361C12.5984 2.08037 12.6326 1.84322 12.608 1.61154C12.5256 0.83747 12.8518 0.28881 13.6789 0C13.8955 0.00156536 14.1139 0.00156536 14.3331 0.00156536Z"
                  fill="black"
                />
                <path
                  d="M8.89035 26.259C12.3125 26.259 15.7092 26.259 19.1059 26.259C19.0348 28.0529 16.573 29.9251 14.2261 29.9971C11.3118 30.0863 9.22712 28.1225 8.89035 26.259Z"
                  fill="black"
                />
              </g>
            </svg>
          </div>
        </div> */}
        <div className="right-side-flow">
          <div className="main-content">
            <div className="full-view-content">
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link to="/settings" className="back-right-part">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    {/* <div className="online">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill="#3BAD45" />
                      </svg>
                      Online
                    </div> */}
                  </div>
                  <div className="follow-main-data">
                    <div className="commn-form-feild detail-view-inputs">
                      <div className="commn-form-title mb-4 ">
                        <h2>Change Password</h2>
                        <p>
                          Create a new password
                          <br />
                          and keep it in mind
                        </p>
                      </div>
                      <Row>
                        <Col lg={12} className="mx-auto">
                          <Form>
                            <Form.Group
                              className="mb-3 position-relativemb-3 ad-height-input position-relative"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Old Password</Form.Label>
                              <Form.Control
                                type={show ? "text" : "password"}
                                placeholder="Type here.."
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                              />
                              <span
                                className="input-eye-show"
                                style={{ cursor: "pointer", marginTop: "2px" }}
                                onClick={() => setshow(!show)}
                              >
                                {show ? (
                                  <svg
                                    width="18"
                                    height="12"
                                    viewBox="0 0 18 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.12249 0C12.8263 0.154398 15.7665 1.80304 17.7323 5.09244C18.089 5.68935 18.0879 6.31372 17.7371 6.91363C16.1908 9.5586 13.9064 11.2207 10.8837 11.8138C6.65697 12.6434 2.37134 10.6459 0.261879 6.92184C-0.0809653 6.31657 -0.0925898 5.69929 0.259013 5.0997C1.82721 2.426 4.14225 0.782249 7.20619 0.182499C7.43486 0.137821 7.66576 0.0985113 7.89761 0.0778303C8.26227 0.045151 8.62837 0.0303112 9.12249 0ZM8.95608 10.877C9.36899 10.8432 9.74607 10.8138 10.123 10.7808C10.2359 10.7709 10.3488 10.7562 10.4601 10.7355C13.1844 10.2295 15.2601 8.80066 16.6877 6.44349C16.8706 6.14133 16.8641 5.87042 16.6867 5.56684C14.8045 2.34612 11.1281 0.633693 7.42642 1.30954C4.71997 1.80367 2.68106 3.27219 1.28261 5.61941C1.12576 5.88258 1.12608 6.1347 1.28436 6.39676C3.0282 9.28454 5.62509 10.7218 8.95608 10.877Z"
                                      fill="#40413A"
                                    />
                                    <path
                                      d="M5.50105 6.00556C5.50216 4.08348 7.07418 2.53051 9.00879 2.54014C10.9445 2.54993 12.496 4.0928 12.4954 6.00745C12.4949 7.92953 10.9249 9.48014 8.98777 9.47193C7.05284 9.46372 5.49993 7.9199 5.50105 6.00556ZM11.3493 6.00493C11.3484 4.73075 10.2819 3.67507 8.99685 3.67634C7.71098 3.6776 6.64662 4.73375 6.64726 6.00761C6.64789 7.28052 7.7156 8.33763 8.99971 8.33715C10.2841 8.33668 11.3503 7.27847 11.3493 6.00493Z"
                                      fill="#40413A"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0.126908 6.54176C0.794012 5.40463 1.65272 4.43371 2.64636 3.58536C3.97413 2.45177 5.4719 1.67112 7.17658 1.34325C9.10234 0.972909 10.972 1.1673 12.7756 1.96285C12.9506 2.03998 13.0479 2.01565 13.1777 1.8776C13.7148 1.30638 14.263 0.74622 14.8125 0.187536C15.1389 -0.144312 15.651 -0.0171777 15.7725 0.422631C15.8405 0.668494 15.7576 0.87011 15.5845 1.04739C14.8946 1.75342 14.2085 2.46342 13.5211 3.17195C10.1047 6.69308 6.68906 10.2151 3.26854 13.7321C3.15778 13.846 3.00625 13.947 2.85714 13.9855C2.61503 14.048 2.38379 13.9018 2.2736 13.6772C2.1255 13.3749 2.30394 13.132 2.60515 12.8287C3.45513 11.9734 3.84134 11.5005 3.97084 11.3898C3.89987 11.3385 3.8558 11.3038 3.80915 11.2733C2.30738 10.2916 1.0905 9.01675 0.131916 7.47668C0.130915 7.47521 0.130056 7.47359 0.129054 7.47211C-0.0446623 7.1907 -0.0406556 6.82729 0.126908 6.54176ZM6.22257 9.02826C5.07839 7.25678 5.72818 5.41333 6.60535 4.5138C7.47178 3.62533 9.25774 2.95308 10.9468 4.11411C11.3253 3.72179 11.7004 3.33301 12.0743 2.94541C12.072 2.94409 12.0527 2.92948 12.0312 2.92108C10.1014 2.17125 8.15592 2.12508 6.21098 2.83303C4.11365 3.59642 2.52388 5.03414 1.25706 6.88526C1.19196 6.98039 1.21113 7.04041 1.26694 7.12271C1.87065 8.01546 2.57382 8.81514 3.40061 9.49107C3.84592 9.85508 4.32185 10.1794 4.78791 10.5245C5.27028 10.0251 5.71931 9.56054 6.16791 9.09551C6.18809 9.07472 6.20511 9.04994 6.22257 9.02826ZM10.1177 4.99889C9.29795 4.4259 8.06147 4.61984 7.34643 5.4182C6.6022 6.24929 6.5939 7.45146 7.0684 8.14112C8.08236 7.09631 9.09747 6.05033 10.1177 4.99889Z"
                                      fill="#40413A"
                                    />
                                    <path
                                      d="M6.17077 12.3833C6.47199 12.0749 6.75674 11.7765 7.05152 11.4891C7.09072 11.4509 7.18359 11.4477 7.24512 11.4608C9.91468 12.0304 12.2929 11.3597 14.4273 9.66526C15.3113 8.96336 16.0606 8.12593 16.7006 7.18451C16.7011 7.18392 16.7015 7.18333 16.7018 7.18274C16.7758 7.07375 16.7745 6.927 16.7003 6.81815C15.9993 5.79031 15.1619 4.90258 14.1919 4.15614C14.4694 3.87149 14.7395 3.59421 15.0177 3.30882C15.5597 3.72326 16.064 4.20599 16.5334 4.72736C17.029 5.27779 17.4782 5.86538 17.8644 6.50253C18.0506 6.80974 18.0436 7.20324 17.8524 7.50722C16.4278 9.77219 14.5402 11.4636 12.0216 12.3364C10.1342 12.9904 8.22016 13.0034 6.30542 12.4314C6.26707 12.4196 6.22987 12.4044 6.17077 12.3833Z"
                                      fill="#40413A"
                                    />
                                    <path
                                      d="M8.1263 10.3663C8.47115 10.013 8.79497 9.67897 9.1228 9.34919C9.15257 9.31925 9.20623 9.30657 9.25058 9.30096C10.3186 9.1638 11.1077 8.35158 11.237 7.25648C11.2425 7.21062 11.2535 7.15472 11.2824 7.12419C11.6026 6.78615 11.9269 6.45238 12.2556 6.11154C12.5579 6.91122 12.4104 8.28595 11.5929 9.2666C10.6566 10.39 9.24014 10.7073 8.1263 10.3663Z"
                                      fill="#40413A"
                                    />
                                  </svg>
                                )}
                              </span>
                            </Form.Group>
                          </Form>
                        </Col>
                        <Col lg={12} className="mx-auto">
                          <Form>
                            <Form.Group
                              className="mb-3 position-relativemb-3 ad-height-input position-relative"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>New Password</Form.Label>
                              <Form.Control
                                type={show1 ? "text" : "password"}
                                placeholder="Type here.."
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                              />
                              <span
                                className="input-eye-show"
                                style={{ cursor: "pointer", marginTop: "2px" }}
                                onClick={() => setshow1(!show1)}
                              >
                                {show1 ? (
                                  <svg
                                    width="18"
                                    height="12"
                                    viewBox="0 0 18 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.12249 0C12.8263 0.154398 15.7665 1.80304 17.7323 5.09244C18.089 5.68935 18.0879 6.31372 17.7371 6.91363C16.1908 9.5586 13.9064 11.2207 10.8837 11.8138C6.65697 12.6434 2.37134 10.6459 0.261879 6.92184C-0.0809653 6.31657 -0.0925898 5.69929 0.259013 5.0997C1.82721 2.426 4.14225 0.782249 7.20619 0.182499C7.43486 0.137821 7.66576 0.0985113 7.89761 0.0778303C8.26227 0.045151 8.62837 0.0303112 9.12249 0ZM8.95608 10.877C9.36899 10.8432 9.74607 10.8138 10.123 10.7808C10.2359 10.7709 10.3488 10.7562 10.4601 10.7355C13.1844 10.2295 15.2601 8.80066 16.6877 6.44349C16.8706 6.14133 16.8641 5.87042 16.6867 5.56684C14.8045 2.34612 11.1281 0.633693 7.42642 1.30954C4.71997 1.80367 2.68106 3.27219 1.28261 5.61941C1.12576 5.88258 1.12608 6.1347 1.28436 6.39676C3.0282 9.28454 5.62509 10.7218 8.95608 10.877Z"
                                      fill="#40413A"
                                    />
                                    <path
                                      d="M5.50105 6.00556C5.50216 4.08348 7.07418 2.53051 9.00879 2.54014C10.9445 2.54993 12.496 4.0928 12.4954 6.00745C12.4949 7.92953 10.9249 9.48014 8.98777 9.47193C7.05284 9.46372 5.49993 7.9199 5.50105 6.00556ZM11.3493 6.00493C11.3484 4.73075 10.2819 3.67507 8.99685 3.67634C7.71098 3.6776 6.64662 4.73375 6.64726 6.00761C6.64789 7.28052 7.7156 8.33763 8.99971 8.33715C10.2841 8.33668 11.3503 7.27847 11.3493 6.00493Z"
                                      fill="#40413A"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0.126908 6.54176C0.794012 5.40463 1.65272 4.43371 2.64636 3.58536C3.97413 2.45177 5.4719 1.67112 7.17658 1.34325C9.10234 0.972909 10.972 1.1673 12.7756 1.96285C12.9506 2.03998 13.0479 2.01565 13.1777 1.8776C13.7148 1.30638 14.263 0.74622 14.8125 0.187536C15.1389 -0.144312 15.651 -0.0171777 15.7725 0.422631C15.8405 0.668494 15.7576 0.87011 15.5845 1.04739C14.8946 1.75342 14.2085 2.46342 13.5211 3.17195C10.1047 6.69308 6.68906 10.2151 3.26854 13.7321C3.15778 13.846 3.00625 13.947 2.85714 13.9855C2.61503 14.048 2.38379 13.9018 2.2736 13.6772C2.1255 13.3749 2.30394 13.132 2.60515 12.8287C3.45513 11.9734 3.84134 11.5005 3.97084 11.3898C3.89987 11.3385 3.8558 11.3038 3.80915 11.2733C2.30738 10.2916 1.0905 9.01675 0.131916 7.47668C0.130915 7.47521 0.130056 7.47359 0.129054 7.47211C-0.0446623 7.1907 -0.0406556 6.82729 0.126908 6.54176ZM6.22257 9.02826C5.07839 7.25678 5.72818 5.41333 6.60535 4.5138C7.47178 3.62533 9.25774 2.95308 10.9468 4.11411C11.3253 3.72179 11.7004 3.33301 12.0743 2.94541C12.072 2.94409 12.0527 2.92948 12.0312 2.92108C10.1014 2.17125 8.15592 2.12508 6.21098 2.83303C4.11365 3.59642 2.52388 5.03414 1.25706 6.88526C1.19196 6.98039 1.21113 7.04041 1.26694 7.12271C1.87065 8.01546 2.57382 8.81514 3.40061 9.49107C3.84592 9.85508 4.32185 10.1794 4.78791 10.5245C5.27028 10.0251 5.71931 9.56054 6.16791 9.09551C6.18809 9.07472 6.20511 9.04994 6.22257 9.02826ZM10.1177 4.99889C9.29795 4.4259 8.06147 4.61984 7.34643 5.4182C6.6022 6.24929 6.5939 7.45146 7.0684 8.14112C8.08236 7.09631 9.09747 6.05033 10.1177 4.99889Z"
                                      fill="#40413A"
                                    />
                                    <path
                                      d="M6.17077 12.3833C6.47199 12.0749 6.75674 11.7765 7.05152 11.4891C7.09072 11.4509 7.18359 11.4477 7.24512 11.4608C9.91468 12.0304 12.2929 11.3597 14.4273 9.66526C15.3113 8.96336 16.0606 8.12593 16.7006 7.18451C16.7011 7.18392 16.7015 7.18333 16.7018 7.18274C16.7758 7.07375 16.7745 6.927 16.7003 6.81815C15.9993 5.79031 15.1619 4.90258 14.1919 4.15614C14.4694 3.87149 14.7395 3.59421 15.0177 3.30882C15.5597 3.72326 16.064 4.20599 16.5334 4.72736C17.029 5.27779 17.4782 5.86538 17.8644 6.50253C18.0506 6.80974 18.0436 7.20324 17.8524 7.50722C16.4278 9.77219 14.5402 11.4636 12.0216 12.3364C10.1342 12.9904 8.22016 13.0034 6.30542 12.4314C6.26707 12.4196 6.22987 12.4044 6.17077 12.3833Z"
                                      fill="#40413A"
                                    />
                                    <path
                                      d="M8.1263 10.3663C8.47115 10.013 8.79497 9.67897 9.1228 9.34919C9.15257 9.31925 9.20623 9.30657 9.25058 9.30096C10.3186 9.1638 11.1077 8.35158 11.237 7.25648C11.2425 7.21062 11.2535 7.15472 11.2824 7.12419C11.6026 6.78615 11.9269 6.45238 12.2556 6.11154C12.5579 6.91122 12.4104 8.28595 11.5929 9.2666C10.6566 10.39 9.24014 10.7073 8.1263 10.3663Z"
                                      fill="#40413A"
                                    />
                                  </svg>
                                )}
                              </span>
                            </Form.Group>
                          </Form>
                        </Col>

                        <Col lg={12} className="mx-auto">
                          <Form>
                            <Form.Group
                              className="mb-3 position-relativemb-3 ad-height-input position-relative"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Confirm Password</Form.Label>
                              <Form.Control
                                type={show2 ? "text" : "password"}
                                placeholder="Type here.."
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                              <span
                                className="input-eye-show"
                                style={{ cursor: "pointer", marginTop: "2px" }}
                                onClick={() => setshow2(!show2)}
                              >
                                {show2 ? (
                                  <svg
                                    width="18"
                                    height="12"
                                    viewBox="0 0 18 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.12249 0C12.8263 0.154398 15.7665 1.80304 17.7323 5.09244C18.089 5.68935 18.0879 6.31372 17.7371 6.91363C16.1908 9.5586 13.9064 11.2207 10.8837 11.8138C6.65697 12.6434 2.37134 10.6459 0.261879 6.92184C-0.0809653 6.31657 -0.0925898 5.69929 0.259013 5.0997C1.82721 2.426 4.14225 0.782249 7.20619 0.182499C7.43486 0.137821 7.66576 0.0985113 7.89761 0.0778303C8.26227 0.045151 8.62837 0.0303112 9.12249 0ZM8.95608 10.877C9.36899 10.8432 9.74607 10.8138 10.123 10.7808C10.2359 10.7709 10.3488 10.7562 10.4601 10.7355C13.1844 10.2295 15.2601 8.80066 16.6877 6.44349C16.8706 6.14133 16.8641 5.87042 16.6867 5.56684C14.8045 2.34612 11.1281 0.633693 7.42642 1.30954C4.71997 1.80367 2.68106 3.27219 1.28261 5.61941C1.12576 5.88258 1.12608 6.1347 1.28436 6.39676C3.0282 9.28454 5.62509 10.7218 8.95608 10.877Z"
                                      fill="#40413A"
                                    />
                                    <path
                                      d="M5.50105 6.00556C5.50216 4.08348 7.07418 2.53051 9.00879 2.54014C10.9445 2.54993 12.496 4.0928 12.4954 6.00745C12.4949 7.92953 10.9249 9.48014 8.98777 9.47193C7.05284 9.46372 5.49993 7.9199 5.50105 6.00556ZM11.3493 6.00493C11.3484 4.73075 10.2819 3.67507 8.99685 3.67634C7.71098 3.6776 6.64662 4.73375 6.64726 6.00761C6.64789 7.28052 7.7156 8.33763 8.99971 8.33715C10.2841 8.33668 11.3503 7.27847 11.3493 6.00493Z"
                                      fill="#40413A"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0.126908 6.54176C0.794012 5.40463 1.65272 4.43371 2.64636 3.58536C3.97413 2.45177 5.4719 1.67112 7.17658 1.34325C9.10234 0.972909 10.972 1.1673 12.7756 1.96285C12.9506 2.03998 13.0479 2.01565 13.1777 1.8776C13.7148 1.30638 14.263 0.74622 14.8125 0.187536C15.1389 -0.144312 15.651 -0.0171777 15.7725 0.422631C15.8405 0.668494 15.7576 0.87011 15.5845 1.04739C14.8946 1.75342 14.2085 2.46342 13.5211 3.17195C10.1047 6.69308 6.68906 10.2151 3.26854 13.7321C3.15778 13.846 3.00625 13.947 2.85714 13.9855C2.61503 14.048 2.38379 13.9018 2.2736 13.6772C2.1255 13.3749 2.30394 13.132 2.60515 12.8287C3.45513 11.9734 3.84134 11.5005 3.97084 11.3898C3.89987 11.3385 3.8558 11.3038 3.80915 11.2733C2.30738 10.2916 1.0905 9.01675 0.131916 7.47668C0.130915 7.47521 0.130056 7.47359 0.129054 7.47211C-0.0446623 7.1907 -0.0406556 6.82729 0.126908 6.54176ZM6.22257 9.02826C5.07839 7.25678 5.72818 5.41333 6.60535 4.5138C7.47178 3.62533 9.25774 2.95308 10.9468 4.11411C11.3253 3.72179 11.7004 3.33301 12.0743 2.94541C12.072 2.94409 12.0527 2.92948 12.0312 2.92108C10.1014 2.17125 8.15592 2.12508 6.21098 2.83303C4.11365 3.59642 2.52388 5.03414 1.25706 6.88526C1.19196 6.98039 1.21113 7.04041 1.26694 7.12271C1.87065 8.01546 2.57382 8.81514 3.40061 9.49107C3.84592 9.85508 4.32185 10.1794 4.78791 10.5245C5.27028 10.0251 5.71931 9.56054 6.16791 9.09551C6.18809 9.07472 6.20511 9.04994 6.22257 9.02826ZM10.1177 4.99889C9.29795 4.4259 8.06147 4.61984 7.34643 5.4182C6.6022 6.24929 6.5939 7.45146 7.0684 8.14112C8.08236 7.09631 9.09747 6.05033 10.1177 4.99889Z"
                                      fill="#40413A"
                                    />
                                    <path
                                      d="M6.17077 12.3833C6.47199 12.0749 6.75674 11.7765 7.05152 11.4891C7.09072 11.4509 7.18359 11.4477 7.24512 11.4608C9.91468 12.0304 12.2929 11.3597 14.4273 9.66526C15.3113 8.96336 16.0606 8.12593 16.7006 7.18451C16.7011 7.18392 16.7015 7.18333 16.7018 7.18274C16.7758 7.07375 16.7745 6.927 16.7003 6.81815C15.9993 5.79031 15.1619 4.90258 14.1919 4.15614C14.4694 3.87149 14.7395 3.59421 15.0177 3.30882C15.5597 3.72326 16.064 4.20599 16.5334 4.72736C17.029 5.27779 17.4782 5.86538 17.8644 6.50253C18.0506 6.80974 18.0436 7.20324 17.8524 7.50722C16.4278 9.77219 14.5402 11.4636 12.0216 12.3364C10.1342 12.9904 8.22016 13.0034 6.30542 12.4314C6.26707 12.4196 6.22987 12.4044 6.17077 12.3833Z"
                                      fill="#40413A"
                                    />
                                    <path
                                      d="M8.1263 10.3663C8.47115 10.013 8.79497 9.67897 9.1228 9.34919C9.15257 9.31925 9.20623 9.30657 9.25058 9.30096C10.3186 9.1638 11.1077 8.35158 11.237 7.25648C11.2425 7.21062 11.2535 7.15472 11.2824 7.12419C11.6026 6.78615 11.9269 6.45238 12.2556 6.11154C12.5579 6.91122 12.4104 8.28595 11.5929 9.2666C10.6566 10.39 9.24014 10.7073 8.1263 10.3663Z"
                                      fill="#40413A"
                                    />
                                  </svg>
                                )}
                              </span>
                            </Form.Group>
                          </Form>
                        </Col>
                      </Row>

                      <div className="common-form-btn">
                        <Button
                          onClick={() => {
                            if (!oldPassword) {
                              toast.error("Old passsword is required");
                            } else if (!newPassword) {
                              toast.error("New password is required");
                            } else if (!confirmPassword) {
                              toast.error("Confirm password is required");
                            } else if (newPassword !== confirmPassword) {
                              toast.error("Password did not match");
                            } else {
                              mutate({
                                old_password: oldPassword,
                                new_password: newPassword,
                              });
                            }
                          }}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
