import { Button, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import React, { useEffect, useState } from "react";
import useMutationHook from "../myHooks/mutationHook";
import {
  forgotOTPverify,
  resendOtp,
  verifyOtp,
} from "../redux/Actions/userActions";
import Loader from "../Components/Loader";
import toast from "react-hot-toast";
import { RegisterData } from "../redux/Reducers/RegistrationSlice";
import { useDispatch } from "react-redux";

export default function OTPVerification() {
  const location = useLocation();
  console.log(location);
  const navigate = useNavigate();
  const dispatch=useDispatch()
  // ======================================react state========================================
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [userEmail, setUserEmail] = useState(null);
  const [requestType, setRequestType] = useState(null);

  // ===================================run timer============================================

  useEffect(() => {
    if (location?.state) {
      setUserEmail(location?.state?.email);
      setRequestType(location?.state?.requestType);
    }
    let timeinterval = null;
    if (timer > 0) {
      timeinterval = setInterval(() => {
        setTimer((old) => old - 1);
      }, 1000);
    } else {
      setTimer(0);
    }

    return () => clearInterval(timeinterval);
  }, [timer]);

  // ================================================resend code api==========================================

  const { mutate, isPending, isSuccess, data } = useMutationHook(resendOtp);

  useEffect(() => {
    if (data?.status === 200) {
      setTimer(60);
    }
  }, [isSuccess]);
  console.log(isPending);

  // ================================================handleresend=============================================

  const handleResend = () => {
    mutate({
      email: userEmail,
      request_type: requestType === "email" ? 1 : 2,
    });
  };

  // =======================================================verifyOtp api=======================================

  const {
    mutate: verifyOTPmutate,
    isPending: verifyOTPpending,
    isSuccess: verifyOTPsuccess,
    data: verifyOTPdata,
  } = useMutationHook(requestType === "email" ? verifyOtp : forgotOTPverify);

  useEffect(() => {
    if (verifyOTPsuccess) {
      if (verifyOTPdata?.status === 200) {
        sessionStorage.setItem("token", verifyOTPdata?.data?.token);
        requestType === "email"
          ? (window.location.href = "/profile-details")
          : navigate(`/reset-password/${verifyOTPdata?.data?._id}`);
          dispatch(RegisterData(null))
      }
    }
  }, [verifyOTPsuccess]);

  // // ======================================================forgotOTPverify========================================

  // const {
  //   mutate: forgetverifyOTPmutate,
  //   isPending: forgetverifyOTPpending,
  //   isSuccess: forgetverifyOTPsuccess,
  //   data: forgetverifyOTPdata,
  // } = useMutationHook(forgotOTPverify);

  // useEffect(() => {
  //   if (verifyOTPdata?.status === 200) {
  //     sessionStorage.setItem("token", verifyOTPdata?.data?.token);
  //     requestType === "email" ? navigate("/profile-details") : navigate("/reset-password");
  //   }
  // }, [verifyOTPsuccess]);

  // ===============================================handleverify==========================================

  const handleVerify = () => {
    if (otp?.length < 4) {
      toast.error("Please enter OTP");
    } else {
      verifyOTPmutate({
        email: userEmail,
        otp: otp,
      });
    }
  };

  return (
    <>
      {isPending && <Loader />}
      <section className="Common-sign-flow">
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="text-center mb-5">
                <img
                  className="Common-sign-logo"
                  src={require("../Assets/images/logo.svg").default}
                  alt="logo"
                />
              </div>
            </Col>
            <Col lg={8} className="mx-auto">
              {/* <div className="page-back-icon">
                <Link to="/profile-preference">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                      fill="black"
                    />
                  </svg>
                  Back
                </Link>
              </div> */}
              <div className="Common-sign-boxout">
                <Row>
                  <Col lg={12}>
                    <div className="select-gender-box">
                      <div className="select-gender-head ">
                        <h2>OTP Verification </h2>

                        <p className="">
                          Please enter 4 digit code sent to your email
                        </p>
                      </div>
                      <div className="profile-preference-optn detail-view-inputs">
                        <Row>
                          <Col lg={8} className="mx-auto">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <div style={{ textAlign: "center" }}>
                                  {/* <Form.Label>OTP</Form.Label> */}
                                </div>
                                <div className="form-set height-none justify-content-add otp-outline-chnge">
                                  <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    separator={
                                      <span style={{ width: "8px" }}></span>
                                    }
                                    renderInput={(props) => (
                                      <input {...props} />
                                    )}
                                    Style={{
                                      border: "1px solid transparent",
                                      borderRadius: "8px",
                                      width: "100px",
                                      height: "54px",
                                      fontSize: "12px",
                                      color: "#000",
                                      fontWeight: "400",
                                      caretColor: "blue",
                                      // display: "flex",
                                      borderBlock:
                                        "1px solid var(--Border, #E8ECF4)",
                                      justifyContent: "space-between",
                                    }}
                                  />
                                </div>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg={8} className="mx-auto">
                            <div className="otp-time">
                              <p>OTP expires in : {timer} seconds</p>
                              <p>
                                <span
                                  onClick={handleResend}
                                  style={{ cursor: "pointer" }}
                                >
                                  Resend Code
                                </span>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  {timer === 0 ? (
                    ""
                  ) : (
                    <Col lg={8} className="mx-auto">
                      <div className="Common-sign-button">
                        {/* <Link to="/tell-us-about" className="back-btn">
    Back
  </Link> */}
                        <Button
                          onClick={handleVerify}
                          // to="/profile-details"
                          className="continue-btn w-100"
                        >
                          {verifyOTPpending ? (
                            <div className="loader"></div>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
