// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDCH671P65XwcmFq7i1FWtYSpyTaSFRWb4",
  authDomain: "frizzapp-6a934.firebaseapp.com",
  projectId: "frizzapp-6a934",
  storageBucket: "frizzapp-6a934.appspot.com",
  messagingSenderId: "448641062516",
  appId: "1:448641062516:web:c72039b125a375b02f2321"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };