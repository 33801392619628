import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import useQueryHook from "../myHooks/queryHook";
import { profile } from "../redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { filters, friendShipFilters } from "../redux/Reducers/fIlterSlice";
import ProfilePicture from "../Components/ProfilePicture";
import toast from "react-hot-toast";
// import { useNavigate } from "react-router-dom";

export default function Friendship() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  // =================================================GET PROFILE DATA========================================

  const { data: profiledata } = useQueryHook(["getUserById"], profile);

  console.log(profiledata, "data");

  const friendShipfilterData = useSelector(
    (state) => state?.filterData?.friendshipState
  );
  console.log(friendShipfilterData, "friendShipfilterData");

  // =========================================react state=================================================

  const [name, setName] = useState(null);
  const [country, setcountry] = useState(null);
  const [city, setcity] = useState(null);

  useEffect(()=>{
    if(friendShipfilterData){
      setName(friendShipfilterData?.name)
      setcity(friendShipfilterData?.city)
      setcountry(friendShipfilterData?.country)
    }

  },[friendShipfilterData])

  return (
    <Layout>
      <div className="right-side">
        {/* <div className="commn-right-top">
          <div className="search-nav-bar">
            <input type="search" placeholder="Search here..." />
          </div>
          <div className="notify-bell">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="30"
              viewBox="0 0 28 30"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M14.3331 0.00156536C14.4077 0.0367861 14.4814 0.0735721 14.5568 0.105662C15.0768 0.324813 15.3733 0.684847 15.3935 1.20298C15.4084 1.59276 15.4057 1.9841 15.397 2.37387C15.3935 2.5398 15.4478 2.60946 15.6443 2.64155C19.7215 3.30292 22.7604 5.92177 23.6032 9.53463C23.7471 10.1498 23.7778 10.7924 23.7962 11.4232C23.8348 12.7397 23.7672 14.0617 23.8584 15.3742C24.0146 17.6197 25.0959 19.4935 26.9692 20.9947C27.5095 21.4275 27.9243 21.9104 27.9892 22.5741C28.1173 23.8906 27.092 24.9276 25.6055 24.9989C25.5178 25.0028 25.4309 25.002 25.3432 25.002C17.7851 25.002 10.2269 25.0004 2.66877 25.0043C1.79701 25.0051 1.05856 24.7656 0.512174 24.1387C-0.301701 23.2057 -0.132436 21.9245 0.926127 21.0972C1.84963 20.374 2.60211 19.5342 3.16516 18.5605C3.88081 17.3223 4.20618 16.0019 4.20881 14.6135C4.21145 13.4128 4.19303 12.2122 4.21846 11.0116C4.30441 6.90169 7.76513 3.32405 12.3037 2.64938C12.5616 2.61103 12.6317 2.52806 12.6159 2.31361C12.5984 2.08037 12.6326 1.84322 12.608 1.61154C12.5256 0.83747 12.8518 0.28881 13.6789 0C13.8955 0.00156536 14.1139 0.00156536 14.3331 0.00156536Z"
                  fill="black"
                />
                <path
                  d="M8.89035 26.259C12.3125 26.259 15.7092 26.259 19.1059 26.259C19.0348 28.0529 16.573 29.9251 14.2261 29.9971C11.3118 30.0863 9.22712 28.1225 8.89035 26.259Z"
                  fill="black"
                />
              </g>
            </svg>
          </div>
        </div> */}
        <div className="right-side-flow">
          <div className="main-content">
            <div className="main-content-contin">
              <div className="common-page-left-part">
              <ProfilePicture/>
                {/* <div className="commn-profile-show">
                  <img
                    style={{ borderRadius: "50%" }}
                    src={`${BASE_URL}public/${profiledata?.data?.image}`}
                    alt="logo"
                  />
                  <h2>
                    {profiledata?.data?.name} {profiledata?.data?.sur_name}
                    {profiledata?.data?.age
                      ? ", " + profiledata?.data?.age
                      : ""}
                  </h2>
                 
                </div> */}
              </div>
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link to="/seeking-for" className="back-right-part">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    {/* <div className="online">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill="#3BAD45" />
                      </svg>
                      Online
                    </div> */}
                  </div>
                  <div className="follow-main-data">
                    <div className="commn-form-feild">
                      <div className="commn-form-title mb-4">
                        <h2>Friendship</h2>
                        <p>Please fill details</p>
                      </div>
                      <div className="commn-form-feild">
                        <Form className="mt-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type here.."
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type here.."
                              onChange={(e) => setcountry(e.target.value)}
                              value={country}
                            />
                          </Form.Group>{" "}
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type here.."
                              onChange={(e) => setcity(e.target.value)}
                              value={city}
                            />
                          </Form.Group>
                        </Form>
                      </div>

                      <div className="common-form-btn">
                        <Button
                          onClick={() => {

                            if(!name && !country && !city){
                              toast.error("Please select at least one option");
                            }else{
                              dispatch(
                              friendShipFilters({
                                ...friendShipfilterData,
                                name,
                                country,
                                city,
                              })
                            );

                            navigate("/user-profile");

                            }
                       
                           
                          }}
                        >
                          Save
                        </Button>
                      </div>
                      <div
                        onClick={() => {
                          dispatch(friendShipFilters(null));

                          navigate("/user-profile");
                        }}
                        className="filters-clear"
                      >
                        <Link>Clear Filter</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
