import React from "react";

const Loader = () => {
  return (
    <>
      <div className="loader-main">
        <div
          class="spinner-border text-danger"
          role="status"
          style={{ width: "80px", height: "80px"}}
        >
          <span class="visually-hidden"></span>
        </div>
      </div>
    </>
  );
};

export default Loader;
