import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

export default function MyPosts() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/seeking-for"); // Replace '/target-page' with your target route
  };
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Layout>
      <div className="right-side">
        <div className="commn-right-top social">
          <div className="post-top-tab">
            <ul>
              <li>
                <Link to="/social-posts">All Posts</Link>
              </li>
              <li>
                <Link>My Friend</Link>
              </li>
              <li>
                <Link to="/my-posts" className="active">
                  My Post
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-flex gap-3">
            <div className="notify-bell">
              <Link to="/create-new-post">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    d="M12.5121 12.5125C12.5121 12.1515 12.5121 11.883 12.5121 11.6145C12.5131 8.58275 12.4928 5.55 12.5233 2.51827C12.5406 0.783241 14.0793 -0.354802 15.7015 0.100822C16.7897 0.406944 17.4802 1.36091 17.4853 2.63828C17.4975 5.64764 17.4894 8.65699 17.4904 11.6663C17.4904 11.9135 17.4904 12.1606 17.4904 12.5115C17.8097 12.5115 18.0731 12.5115 18.3365 12.5115C21.3683 12.5125 24.401 12.4952 27.4328 12.5206C29.1891 12.5359 30.3343 14.0167 29.9112 15.651C29.6204 16.7738 28.6593 17.4806 27.3463 17.4857C24.337 17.4969 21.3276 17.4898 18.3182 17.4898C18.0731 17.4898 17.828 17.4898 17.4904 17.4898C17.4904 17.801 17.4904 18.043 17.4904 18.2851C17.4894 21.3402 17.5077 24.3953 17.4802 27.4494C17.465 29.1997 15.9659 30.3418 14.3336 29.9076C13.2169 29.6106 12.5202 28.6475 12.5151 27.3284C12.504 24.3191 12.5111 21.3097 12.5111 18.3003C12.5111 18.0563 12.5111 17.8132 12.5111 17.4888C12.1633 17.4888 11.8968 17.4888 11.6303 17.4888C8.59861 17.4878 5.56586 17.5071 2.53413 17.4786C0.792999 17.4623 -0.348095 15.9419 0.0963419 14.3157C0.395345 13.2234 1.34931 12.5217 2.62058 12.5156C5.62993 12.5023 8.63929 12.5105 11.6486 12.5105C11.8978 12.5125 12.147 12.5125 12.5121 12.5125Z"
                    fill="#808080"
                  />
                </svg>
              </Link>
            </div>
            <div className="notify-bell">
              <Link to="">
                <svg
                  width="34"
                  height="30"
                  viewBox="0 0 34 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.0296 15.0732L18.1458 9.08391C17.5019 8.82429 16.9067 8.79146 16.3601 8.98543C15.7381 9.20614 15.2969 9.7871 15.0367 10.7283C14.7953 11.6628 14.7233 12.6858 14.8206 13.7973C14.9179 14.9087 15.1304 15.9263 15.4581 16.8498C15.9865 18.3389 16.6936 18.9262 17.5795 18.6119C18.0507 18.4447 18.4417 18.0513 18.7524 17.4317C19.0752 16.7866 19.1676 16.0004 19.0296 15.0732ZM19.2193 8.16185L20.4751 6.85673L20.8709 6.71627L22.1022 14.4922C22.3199 15.9429 22.8905 16.5044 23.8141 16.1767C24.6434 15.8824 25.2438 15.0327 25.6153 13.6276C25.999 12.1969 25.8765 10.5957 25.2478 8.82394C24.4385 6.54327 23.0309 5.0585 21.025 4.36963C19.038 3.67407 16.9325 3.7209 14.7083 4.51012C12.2015 5.39967 10.3999 7.11064 9.30371 9.64305C8.20078 12.1566 8.13757 14.7893 9.11406 17.5412C10.0437 20.1611 11.5322 21.9037 13.5794 22.7689C15.6521 23.6463 18.0079 23.6168 20.6467 22.6804C23.1158 21.8042 24.9761 20.3483 26.2273 18.3127L26.7892 18.9091C25.2515 21.5133 22.9936 23.3437 20.0156 24.4005C16.8679 25.5174 14.0123 25.5439 11.4489 24.4799C8.90434 23.4092 7.15384 21.5262 6.19741 18.8308C5.57539 17.0779 5.36835 15.3581 5.57628 13.6715C5.78421 11.9849 6.30588 10.4946 7.14129 9.20073C7.98886 7.88132 9.02643 6.73855 10.254 5.7724C11.4749 4.7874 12.8016 4.04074 14.2341 3.53243C16.7786 2.62951 19.1876 2.57051 21.461 3.35545C23.7344 4.14038 25.3225 5.80513 26.2254 8.34968C27.0214 10.5926 27.0642 12.6572 26.3541 14.5432C25.6561 16.4038 24.5155 17.615 22.9322 18.1768C22.1029 18.4711 21.4012 18.4972 20.8272 18.2552C20.2533 18.0132 19.8404 17.5974 19.5887 17.0076C19.4902 17.8065 19.2063 18.5015 18.7369 19.0925C18.2675 19.6835 17.6652 20.1094 16.9302 20.3703C15.9689 20.7114 15.0818 20.6335 14.2689 20.1368C13.4492 19.6212 12.8354 18.7885 12.4275 17.6388C11.7252 15.6597 11.7008 13.7371 12.3545 11.8711C13.0202 9.97956 14.1824 8.7395 15.8411 8.15093C16.9343 7.763 18.0604 7.76664 19.2193 8.16185Z"
                    fill="#808080"
                  ></path>
                </svg>
              </Link>
            </div>
            <div className="notify-bell">
              <Link to="">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.5575 19.1976L17.6969 13.1911C17.5377 13.0829 17.3495 13.0179 17.1531 13.0032C16.9568 12.9885 16.7599 13.0248 16.5842 13.108C16.4086 13.1912 16.2611 13.318 16.158 13.4746C16.0548 13.6311 16.0002 13.8112 16 13.9949V26.0038C15.9994 26.1878 16.0536 26.3684 16.1566 26.5253C16.2596 26.6822 16.4073 26.8094 16.5833 26.8926C16.7593 26.9758 16.9566 27.0118 17.1532 26.9966C17.3498 26.9813 17.5381 26.9155 17.6969 26.8063L26.5575 20.8051C26.6944 20.7136 26.806 20.5929 26.8829 20.4531C26.9599 20.3133 27 20.1585 27 20.0014C27 19.8442 26.9599 19.6894 26.8829 19.5496C26.806 19.4098 26.6944 19.2892 26.5575 19.1976Z"
                    fill="#808080"
                  />
                  <path
                    d="M20.0006 0.75C9.36704 0.75 0.75 9.36823 0.75 20.0032C0.75 30.6344 9.36712 39.25 20.0006 39.25C30.6316 39.25 39.25 30.6331 39.25 20.0032C39.2513 9.36821 30.6317 0.75 20.0006 0.75ZM20.0006 35.5794C11.3983 35.5794 4.42394 28.6078 4.42394 20.0032C4.42394 11.4021 11.3985 4.42183 20.0006 4.42183C28.6015 4.42183 35.5748 11.4009 35.5748 20.0032C35.576 28.6078 28.6017 35.5794 20.0006 35.5794Z"
                    fill="#808080"
                    stroke="#808080"
                    stroke-width="0.5"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
        <div className="right-side-flow">
          <div className="main-content">
            <div className="full-view-content">
              <Row>
                <Col lg={4} className="mb-3">
                  <div className="my-post-single">
                    <img
                      className="border-radius-0"
                      src={require("../Assets/images/post-img.png")}
                      alt="Product"
                    />
                  </div>
                </Col>{" "}
                <Col lg={4} className="mb-3">
                  <div className="my-post-single">
                    <img
                      className="border-radius-0"
                      src={require("../Assets/images/post-img.png")}
                      alt="Product"
                    />
                  </div>
                </Col>{" "}
                <Col lg={4} className="mb-3">
                  <div className="my-post-single">
                    <img
                      className="border-radius-0"
                      src={require("../Assets/images/post-img.png")}
                      alt="Product"
                    />
                  </div>
                </Col>{" "}
                <Col lg={4} className="mb-3">
                  <div className="my-post-single">
                    <img
                      className="border-radius-0"
                      src={require("../Assets/images/post-img.png")}
                      alt="Product"
                    />
                  </div>
                </Col>{" "}
                <Col lg={4} className="mb-3">
                  <div className="my-post-single">
                    <img
                      className="border-radius-0"
                      src={require("../Assets/images/post-img.png")}
                      alt="Product"
                    />
                  </div>
                </Col>{" "}
                <Col lg={4} className="mb-3">
                  <div className="my-post-single">
                    <img
                      className="border-radius-0"
                      src={require("../Assets/images/post-img.png")}
                      alt="Product"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
