import axios from "axios";
import userApi from "../../service/userApi";

// =========================================signup========================================

export const userSignUp = async (detail) => {
  const { data } = await userApi.post("user/signup", detail);
  return data;
};

// ========================================resend code====================================

export const resendOtp = async (detail) => {
  const { data } = await userApi.post("user/resend-otp", detail);
  return data;
};

// ==========================================verifyOTP=======================================

export const verifyOtp = async (detail) => {
  const { data } = await userApi.post("user/email-verify", detail);
  return data;
};

// ==========================================login=========================================

export const login = async (detail) => {
  const { data } = await userApi.post("user/login", detail);
  return data;
};

// ========================================forgetPassword=====================================

export const forgotPassword = async (detail) => {
  const { data } = await userApi.post("user/forgot-password", detail);
  return data;
};

// ========================================forgetOTP verify=======================================

export const forgotOTPverify = async (detail) => {
  const { data } = await userApi.post("user/forgot-otp-verify", detail);
  return data;
};

// =========================================reset password====================================

export const resetPassword = async (detail) => {
  const { data } = await userApi.post("user/set-password", detail);
  return data;
};

// =========================================socialLogin===========================================

export const socialLogin = async (detail) => {
  const { data } = await userApi.post("user/social-login", detail);
  return data;
};

// =======================================userProfile=============================================

export const profile = async (detail) => {
  const { data } = await userApi.get("user/profile");
  return data;
};

// ========================================edit profile==============================================

export const editprofile = async (detail) => {
  const { data } = await userApi.put("user/edit-profile", detail);
  return data;
};

// =======================================logout==================================================

export const logout = async (detail) => {
  const { data } = await userApi.patch("user/logout", detail);
  return data;
};

// ==================================hobbies-interests===========================================

export const hobbies = async (detail) => {
  const { data } = await userApi.get("user/hobbies-interests");
  return data;
};

// ==================================allUsers=============================================

export const userProfiles = async ({
  filterData,
  advancefilterData,
  friendShipfilterData,
  travelfilterData,
  jobfilterData,
  sportsfilterData,
  pagination,
}) => {
  console.log(
    {
      filterData,
      advancefilterData,
      friendShipfilterData,
      travelfilterData,
      jobfilterData,
      sportsfilterData,
      pagination,
    },
    "details"
  );

  let url = "home/user-profiles";
  let queryParams = `pagination=${pagination}`;

  // Create an object to hold filter parameters
  let obj = {};

  if (
    filterData ||
    advancefilterData ||
    friendShipfilterData ||
    travelfilterData ||
    jobfilterData ||
    sportsfilterData
  ) {
    // Conditionally add properties to `obj` only if they have values
    const filters = [
      filterData,
      advancefilterData,
      friendShipfilterData,
      travelfilterData,
      jobfilterData,
      sportsfilterData,
    ];
    filters.forEach((filter) => {
      if (filter) {
        if (filter.gender) obj.gender = filter.gender;
        if (filter.lookingFor) obj.looking_for = filter.lookingFor;
        if (filter.state) obj.state = filter.state;
        if (filter.city) obj.city = filter.city;
        if (filter.country) obj.country = filter.country;
        if (filter.max_distance || filter.distanceRange?.[1])
          obj.max_distance = filter.max_distance || filter.distanceRange?.[1];
        if (filter.min_distance || filter.distanceRange?.[0] !== undefined)
          obj.min_distance = filter.min_distance || filter.distanceRange?.[0];
        if (filter.max_age || filter.ageRange?.[1])
          obj.max_age = filter.max_age || filter.ageRange?.[1];
        if (filter.min_age || filter.ageRange?.[0] !== undefined)
          obj.min_age = filter.min_age || filter.ageRange?.[0];
        if (filter.bodyArt) obj.body_art = filter.bodyArt;
        if (filter.bodyType) obj.body_type = filter.bodyType;
        if (filter.drink) obj.drink = filter.drink;
        if (filter.education) obj.education = filter.education;
        if (filter.ethnicity) obj.ethnicity = filter.ethnicity;
        if (filter.eyeColor) obj.eye_color = filter.eyeColor;
        if (filter.hairColor) obj.hair_color = filter.hairColor;
        if (filter.hasChildren) obj.have_children = filter.hasChildren;
        if (filter.hasPets) obj.pets = filter.hasPets;
        if (filter.height) obj.height = filter.height;
        if (filter.languageAbility)
          obj.education_lang_ability = filter.languageAbility;
        if (filter.languageSpoken) obj.language_spoken = filter.languageSpoken;
        if (filter.nationality) obj.nationality = filter.nationality;
        if (filter.numberOfChildren)
          obj.no_of_children = filter.numberOfChildren;
        if (filter.oldestChild) obj.oldest_children = filter.oldestChild;
        if (filter.religion) obj.religion = filter.religion;
        if (filter.smoke) obj.smoke = filter.smoke;
        if (filter.starSign) obj.star_sign = filter.starSign;
        if (filter.wantMoreKids) obj.more_kids = filter.wantMoreKids;
        if (filter.weight) obj.weight = filter.weight;
        if (filter.willingToMove) obj.willing_to_move = filter.willingToMove;
        if (filter.youngest_children)
          obj.youngest_children = filter.youngest_children;
      }
    });

    // Convert obj to query string
    const filterParams = Object.entries(obj)
      .filter(([key, value]) => value !== undefined && value !== null)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    if (filterParams) {
      queryParams += `&${filterParams}`;
    }
  }

  // Append query string to URL if queryParams is not empty
  if (queryParams) {
    url += `?${queryParams}`;
  }

  console.log(url, "uuurrlll"); // Check URL output

  try {
    const { data } = await userApi.get(url);
    console.log(data); // Log the response data
    return data;
  } catch (error) {
    console.error("Error fetching user profiles:", error);
    throw error; // Rethrow error to handle it in the query hook
  }
};

// ===================================change password============================================

export const changePassword = async (detail) => {
  const { data } = await userApi.patch("user/change-password", detail);
  return data;
};

// ===========================delete user========================================

export const deleteAccount = async (detail) => {
  const { data } = await userApi.delete("user/account", detail);
  return data;
};

// ================================users search========================================

export const searchUser = async (detail) => {
  const { data } = await userApi.get(`home/search-users?search=${detail}`);
  return data;
};

// ==========================music listing===========================================

export const searchTracks = async ({ search, page, limit }) => {
  const options = {
    method: "GET",
    url: "https://deezerdevs-deezer.p.rapidapi.com/search",
    params: { q: search ? search : `tauba` },
    headers: {
      "x-rapidapi-key": "ca91b974c2msha6cc3bcf5142a2ap17c18bjsn33fd7c526f63",
      "x-rapidapi-host": "deezerdevs-deezer.p.rapidapi.com",
    },
  };

  try {
    const response = await axios.request(options);

    const fetchedMusicList = response.data.data.map((item) => ({
      ...item,
      isPlaying: false, // Add isPlaying property to each music item
    }));
    return fetchedMusicList; // Return array of songs
  } catch (error) {
    console.error("Error searching songs:", error);
    return []; // Return empty array on error
  }
};

// ==================================create post=========================================

export const createpost = async (detail) => {
  const { data } = await userApi.post("home/post", detail);
  return data;
};

// =================================get post=========================================

export const getPosts = async (detail) => {
  const { data } = await userApi.get(
    `home/post?type=${detail?.type}&pagination=${detail?.page}&limit=${detail?.limit}`
  );
  return data;
};

// ===============================like-dislike-post=====================================

export const lokeDislikePost = async (detail) => {
  const { data } = await userApi.post("home/post-like-dislike", detail);
  return data;
};
