import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import Slider from "react-slick";
import Tab from "react-bootstrap/Tab";
// import { useNavigate } from "react-router-dom";

export default function OccupationSettings() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/plans-for-you"); // Replace '/target-page' with your target route
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };
  return (
    <Layout>
      <div className="right-side">
        {/* <div className="commn-right-top">
          <div className="search-nav-bar">
            <input type="search" placeholder="Search here..." />
          </div>
          <div className="notify-bell">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="30"
              viewBox="0 0 28 30"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M14.3331 0.00156536C14.4077 0.0367861 14.4814 0.0735721 14.5568 0.105662C15.0768 0.324813 15.3733 0.684847 15.3935 1.20298C15.4084 1.59276 15.4057 1.9841 15.397 2.37387C15.3935 2.5398 15.4478 2.60946 15.6443 2.64155C19.7215 3.30292 22.7604 5.92177 23.6032 9.53463C23.7471 10.1498 23.7778 10.7924 23.7962 11.4232C23.8348 12.7397 23.7672 14.0617 23.8584 15.3742C24.0146 17.6197 25.0959 19.4935 26.9692 20.9947C27.5095 21.4275 27.9243 21.9104 27.9892 22.5741C28.1173 23.8906 27.092 24.9276 25.6055 24.9989C25.5178 25.0028 25.4309 25.002 25.3432 25.002C17.7851 25.002 10.2269 25.0004 2.66877 25.0043C1.79701 25.0051 1.05856 24.7656 0.512174 24.1387C-0.301701 23.2057 -0.132436 21.9245 0.926127 21.0972C1.84963 20.374 2.60211 19.5342 3.16516 18.5605C3.88081 17.3223 4.20618 16.0019 4.20881 14.6135C4.21145 13.4128 4.19303 12.2122 4.21846 11.0116C4.30441 6.90169 7.76513 3.32405 12.3037 2.64938C12.5616 2.61103 12.6317 2.52806 12.6159 2.31361C12.5984 2.08037 12.6326 1.84322 12.608 1.61154C12.5256 0.83747 12.8518 0.28881 13.6789 0C13.8955 0.00156536 14.1139 0.00156536 14.3331 0.00156536Z"
                  fill="black"
                />
                <path
                  d="M8.89035 26.259C12.3125 26.259 15.7092 26.259 19.1059 26.259C19.0348 28.0529 16.573 29.9251 14.2261 29.9971C11.3118 30.0863 9.22712 28.1225 8.89035 26.259Z"
                  fill="black"
                />
              </g>
            </svg>
          </div>
        </div> */}
        <div className="right-side-flow">
          <div className="main-content">
            <div className="full-view-content">
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link to="/settings" className="back-right-part">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    {/* <div className="online">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill="#3BAD45" />
                      </svg>
                      Online
                    </div> */}
                  </div>
                  <div className="follow-main-data">
                    <div className="commn-form-feild">
                      <div className="commn-form-title mb-4 ">
                        <h2>Occupation</h2>
                        <p>Define your skill set</p>
                      </div>
                      <Row>
                        <Col lg={12} className="mx-auto">
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Select Occupation</Form.Label>
                              <Form.Select aria-label="Default select example">
                                <option>Select</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </Form.Select>
                            </Form.Group>
                          </Form>
                        </Col>
                        <Col lg={12} className="mx-auto">
                          <Form>
                            <Form.Group
                              className="mb-3 detail-view-inputs"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label> Mention your occupation.</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Enter keyword"
                              />
                            </Form.Group>
                          </Form>
                        </Col>
                        <Col lg={12} className="mx-auto">
                          <div className="occupation-keyword">
                            <ul>
                              <li>
                                Advertising / Media
                                <svg
                                  width="55"
                                  height="45"
                                  viewBox="0 0 55 45"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g filter="url(#filter0_d_2919_33432)">
                                    <rect
                                      x="20"
                                      y="10"
                                      width="24.5989"
                                      height="24.572"
                                      rx="12.286"
                                      fill="white"
                                    />
                                    <path
                                      d="M36.3697 19.2997C36.4414 19.2306 36.4985 19.1478 36.5379 19.0563C36.5772 18.9649 36.598 18.8665 36.5989 18.7669C36.5998 18.6673 36.5809 18.5685 36.5432 18.4763C36.5055 18.3841 36.4499 18.3004 36.3795 18.2299C36.3091 18.1595 36.2254 18.1037 36.1333 18.066C36.0411 18.0282 35.9423 18.0092 35.8428 18.01C35.7432 18.0108 35.6448 18.0315 35.5532 18.0707C35.4617 18.11 35.3789 18.1671 35.3097 18.2387L32.3037 21.2437L29.2987 18.2387C29.23 18.165 29.1472 18.1059 29.0552 18.0649C28.9632 18.0239 28.8639 18.0019 28.7632 18.0001C28.6625 17.9983 28.5625 18.0169 28.4691 18.0546C28.3757 18.0923 28.2909 18.1485 28.2197 18.2197C28.1485 18.2909 28.0923 18.3757 28.0546 18.4691C28.0169 18.5625 27.9983 18.6625 28.0001 18.7632C28.0019 18.8639 28.0239 18.9632 28.0649 19.0552C28.1059 19.1472 28.165 19.23 28.2387 19.2987L31.2417 22.3047L28.2367 25.3097C28.1042 25.4519 28.0321 25.6399 28.0355 25.8342C28.039 26.0285 28.1177 26.2139 28.2551 26.3513C28.3925 26.4887 28.5779 26.5675 28.7722 26.5709C28.9665 26.5743 29.1545 26.5022 29.2967 26.3697L32.3037 23.3647L35.3087 26.3707C35.4509 26.5032 35.6389 26.5753 35.8332 26.5719C36.0275 26.5685 36.2129 26.4897 36.3503 26.3523C36.4877 26.2149 36.5665 26.0295 36.5699 25.8352C36.5733 25.6409 36.5012 25.4529 36.3687 25.3107L33.3657 22.3047L36.3697 19.2997Z"
                                      fill="black"
                                    />
                                  </g>
                                  <defs>
                                    <filter
                                      id="filter0_d_2919_33432"
                                      x="0"
                                      y="-9"
                                      width="64.6016"
                                      height="64.5723"
                                      filterUnits="userSpaceOnUse"
                                      color-interpolation-filters="sRGB"
                                    >
                                      <feFlood
                                        flood-opacity="0"
                                        result="BackgroundImageFix"
                                      />
                                      <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                      />
                                      <feOffset dy="1" />
                                      <feGaussianBlur stdDeviation="10" />
                                      <feComposite
                                        in2="hardAlpha"
                                        operator="out"
                                      />
                                      <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                                      />
                                      <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_2919_33432"
                                      />
                                      <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_2919_33432"
                                        result="shape"
                                      />
                                    </filter>
                                  </defs>
                                </svg>
                              </li>
                            </ul>
                            <p>
                              Your profile will be displayed whenever a person
                              makes a detailed search looking for your
                              profession. Write your keywords separated by a
                              comma so we can find them, like carpenter,
                              bricklayer, electrician, lawyer, accountant,
                              bartender, waiter.
                            </p>
                            <div className="occupation-checkbox">
                              <input type="checkbox" />
                              <label>
                                By selecting your occupation, you agree to
                                register as a <b>Job/Service Provider</b>
                              </label>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="common-form-btn">
                        <Button onClick={handleRedirect}>Continue</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
