import { Button, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { ProfileData } from "../redux/Reducers/profileSlice";

export default function TellUsAbout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state?.profileData?.profiledata);
  console.log(data, "prevData");
  // =========================================react states====================================

  const [about, setAbout] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (data?.images) {
      setImages(data?.images);
      setAbout(data?.about);
    }
  }, [data]);

  // ========================================handleImages==================================

  const handleImageChange = (e, i) => {
    let newImage = e.target.files;
    setImages((prevImages) => {
      const existingImage = prevImages?.find((img) => img.key === i);
      if (existingImage) {
        return prevImages?.map((img) =>
          img.key === i ? { key: i, image: newImage } : img
        );
      } else {
        return [...prevImages, { key: i, image: newImage }];
      }
    });
  };
  console.log(images, "images");

  return (
    <>
      <section className="Common-sign-flow">
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="text-center mb-3">
                <img
                  className="Common-sign-logo"
                  src={require("../Assets/images/logo.svg").default}
                  alt="logo"
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="Common-sign-boxout">
                <Row>
                  <Col lg={12}>
                    <div className="select-gender-box">
                      <div className="select-gender-head text-start">
                        <h2>Tell us about yourself!</h2>
                        <p className="text-start">
                          Let other users know you better!
                        </p>
                      </div>
                      <div className="about-yourself-type-box detail-view-inputs">
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Type here (max 50 words)"
                              value={about}
                              onChange={(e) => {
                                const wordCount = e.target.value
                                  .trim()
                                  .split(/\s+/).length;
                                console.log(wordCount, "wordcount");
                                if (wordCount > 50) {
                                  toast.error(
                                    "The description should not exceed 50 words"
                                  );
                                } else {
                                  setAbout(e.target.value);
                                }
                              }}
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} className=" mb-4">
                    <div className="select-gender-head text-start mt-4">
                      <h2>Add Recent Photos!</h2>
                      <p className="text-start">
                        Showcase your latest moments - add 2 recent photos now!
                      </p>
                    </div>
                    <div className="about-img-contain">
                      {Array.from({ length: 5 }, (_, i) => {
                        console.log(
                          images?.find((x) => x?.key === i)?.image?.length,
                          "iiiiiiiiiii"
                        );
                        return (
                          <div className="about-img-item">
                            <div
                              className="recent-photos-sign"
                              style={{ position: "relative" }}
                            >
                              <input
                                type="file"
                                className="file-input"
                                accept="image/*"
                                style={{
                                  opacity: 0,
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  cursor: "pointer",
                                }}
                                onChange={(e) => handleImageChange(e, i)}
                              />
                              {images?.find((x) => x?.key === i) &&
                              images?.find((x) => x?.key === i)?.image
                                ?.length == "1" ? (
                                <img
                                  src={URL?.createObjectURL(
                                    images.find((x) => x.key === i)?.image[0]
                                  )}
                                  key={images.find((x) => x.key === i)?.key}
                                  alt="imag"
                                />
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="47"
                                  height="46"
                                  viewBox="0 0 47 46"
                                  fill="none"
                                >
                                  <g opacity="0.3">
                                    <path
                                      d="M41.9101 26.2097C41.6601 26.0564 41.5087 25.9702 41.3645 25.8744C39.0908 24.373 36.8003 22.8955 34.5531 21.3558C33.4619 20.6087 32.4717 20.5752 31.3373 21.2696C28.2416 23.1709 25.1075 25.0076 21.9999 26.8921C21.6538 27.1029 21.4303 27.0813 21.1058 26.861C19.4546 25.7403 17.777 24.6627 16.1258 23.5421C15.3784 23.0344 14.6261 22.9195 13.7464 23.1613C9.59326 24.3083 5.42809 25.4218 1.26771 26.5473C1.18359 26.5713 1.09947 26.5904 0.955265 26.6287C0.943248 26.43 0.92402 26.2623 0.92402 26.0947C0.92402 19.7825 0.91681 13.4728 0.928827 7.16297C0.936037 3.68361 3.2794 0.929813 6.71393 0.328767C7.11531 0.259323 7.53111 0.254534 7.93969 0.254534C16.9695 0.249745 25.9992 0.24735 35.029 0.254534C38.4899 0.256929 41.2371 2.47194 41.9101 5.79805C42.011 6.29852 42.0422 6.82294 42.0447 7.33538C42.0543 13.2022 42.0519 19.069 42.0447 24.9357C42.0447 25.3308 41.9629 25.7283 41.9101 26.2097ZM13.5013 16.1739C15.3952 16.1667 16.9214 14.6222 16.907 12.728C16.8925 10.8626 15.3639 9.35164 13.4893 9.34685C11.5929 9.34446 10.0547 10.877 10.0571 12.7663C10.0595 14.6533 11.6025 16.1811 13.5013 16.1739Z"
                                      fill="#B4B4B4"
                                    />
                                    <path
                                      d="M0.991317 31.3413C3.18567 30.7403 5.39924 30.1344 7.61282 29.531C9.67498 28.9706 11.7347 28.4031 13.8041 27.8667C14.0396 27.8069 14.3737 27.8643 14.578 27.996C16.4022 29.1766 18.2144 30.3739 20.017 31.5879C20.964 32.2273 21.8869 32.2489 22.8651 31.6574C26.0233 29.7537 29.1934 27.8739 32.3467 25.9678C32.6952 25.7571 32.9332 25.7188 33.2865 25.9726C34.2647 26.6742 35.2813 27.3232 36.3292 28.0248C35.738 28.2786 35.202 28.463 34.7093 28.724C30.722 30.8289 28.5685 34.1167 28.3546 38.6233C28.2729 40.357 27.4148 41.2167 25.6627 41.2191C19.7575 41.2191 13.8498 41.2262 7.9445 41.2167C4.39701 41.2095 1.53209 38.8628 1.02497 35.4073C0.830286 34.1047 0.991317 32.7493 0.991317 31.3413Z"
                                      fill="#B4B4B4"
                                    />
                                    <path
                                      d="M42.0495 36.6597C42.8065 36.6597 43.4987 36.6573 44.1909 36.6597C45.5777 36.6669 46.5848 37.6104 46.5992 38.9059C46.6112 40.2109 45.6306 41.1831 44.2486 41.2167C43.5396 41.2334 42.8306 41.2191 42.0495 41.2191C42.0495 41.9925 42.0663 42.7133 42.0446 43.4365C42.0062 44.7655 41.0616 45.7281 39.807 45.7496C38.538 45.7712 37.5117 44.8062 37.4781 43.5346C37.4589 42.7923 37.4757 42.0476 37.4757 41.2191C36.7234 41.2191 36 41.231 35.2789 41.2167C34.0844 41.1927 33.1783 40.4719 32.9596 39.392C32.6808 38.0127 33.6854 36.7172 35.0963 36.6669C35.8606 36.6406 36.6273 36.6621 37.4733 36.6621C37.4733 35.8815 37.4589 35.1248 37.4757 34.3705C37.5069 32.8786 38.8505 31.8418 40.2973 32.177C41.3188 32.4141 42.0182 33.3073 42.0471 34.4471C42.0639 35.1559 42.0495 35.8671 42.0495 36.6597Z"
                                      fill="#B4B4B4"
                                    />
                                  </g>
                                </svg>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <p className="text-start mt-3">
                      You can upload maximum of 5 photos to your profile
                    </p>
                  </Col>
                  <Col lg={12}>
                    <div className="Common-sign-button">
                      <Link to="/select-gender" className="back-btn">
                        Back
                      </Link>
                      <Button
                        // to="/profile-preference"
                        onClick={() => {
                          if (!about) {
                            toast.error("Please tell something about you");
                          } else if (images.length < 2) {
                            toast.error("Please upload atleast two images");
                          } else {
                            console.log(images, about);

                            dispatch(
                              ProfileData({ ...data, images: images, about })
                            );
                            navigate("/profile-preference");
                          }
                        }}
                        className="continue-btn"
                      >
                        Continue
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
