import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Layout from "../Components/Layout";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../Components/Loader";
import useQueryHook from "../myHooks/queryHook";
import {
  createpost,
  SearchTrack,
  searchTracks,
} from "../redux/Actions/userActions";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaPlay, FaPause, FaStop } from "react-icons/fa";
import useMutationHook from "../myHooks/mutationHook";
import { createPostData } from "../redux/Reducers/createPostSlice";

export default function AddMusic() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const data = useSelector((state) => state?.postData?.createPostState);
  console.log(data, "postData");
  const currentTrackRef = useRef(null);

  

  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(0);
  const [limit] = useState(10);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  // State for playing audio
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [song, setSong] = useState(null);
  const [musicName, setMusicName] = useState(null);

  // =============================================== api==========================================

  const {
    mutate,
    isPending,
    isSuccess,
    data: createPostdata,
  } = useMutationHook(createpost);

  useEffect(() => {
    if (createPostdata?.status == 200) {
      console.log(data, "datdatadtad");
      dispatch(createPostData(null));
      currentTrack?.audio?.pause();
      navigate("/social-posts");
    }
  }, [isSuccess]);

  // =================================================GET PROFILE DATA========================================

  const fetchMoreData = async () => {
    try {
      const result = await searchTracks({ search, page, limit });
      console.log(result, "result");
      if (result?.length > 0) {
        setItems((prevItems) => [...prevItems, ...result]);
        setPage((prevPage) => prevPage + 1);
      } else {
        setHasMore(false); // No more data to load
      }
    } catch (error) {
      console.error(error);
      setHasMore(false); // Stop fetching on error
    }
  };

  const {
    data: trackdata,
    isLoading,
    refetch,
  } = useQueryHook(
    ["SearchTrack"],
    () => fetchMoreData(),
    { keepPreviousData: true },
    { enabled: page > 0 }
  );

  console.log(trackdata, "data");

  useEffect(() => {
    refetch();
  }, [search]);

  useEffect(() => {

    setItems([]); // Clear items on new search
    setPage(0); // Reset page on new search
    setHasMore(true); // Reset hasMore
    refetch(); // Trigger the initial fetch

   
  }, [search, refetch]);

  // ================================handleSubmit============================================

  const handleSelectSong = (track) => {
    console.log(track, "TRTRTRTR");

    setSong(track?.preview);
    setMusicName(track?.title);
    toast.success(`Your selected song is ${track?.title}`)
  };

  const handleSubmit = () => {
    let formdata = new FormData();
    formdata.append("type", 0);
    data?.images?.forEach((image)=>{
        console.log(image?.image)
    })
    data?.images && data?.images?.forEach((image)=>{
        formdata.append("images",image?.image[0])
    })
    song && formdata.append("music", song);
    musicName && formdata.append("music_name", musicName);
    data?.taggedUser && formdata.append("tagged_users", data?.taggedUser);
    data?.description && formdata.append("description", data?.description);

    mutate(formdata);
  };

  const playTrack = (track) => {
    console.log(track, "track");

    // If the track is currently playing and the same track is clicked, pause it
    if (currentTrack && currentTrack.id === track.id && isPlaying) {
      setIsPlaying(false);
      currentTrack?.audio?.pause();
    } else {
      // If another track is currently playing, stop it first
      if (currentTrack) {
        currentTrack?.audio?.pause();
      }

      // Create a new Audio object with the track's preview URL and play it
      const audio = new Audio(track.preview); // track.preview contains the audio URL
      setCurrentTrack({ id: track.id, audio });
      audio.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    // When the location (route) changes, pause the audio if it's playing
    return () => {
      if (currentTrackRef.current?.audio) {
        currentTrackRef?.current?.audio?.pause();
        setIsPlaying(false);
      }
    };
  }, [location]);

  useEffect(() => {
    currentTrackRef.current = currentTrack; // Keep the ref in sync with the latest track
  }, [currentTrack]);

  console.log(items, "==============================");

  return (
    <Layout>
      {(isLoading || isPending) && <Loader />}
      <div className="right-side">
        <div className="right-side-flow">
          <div className="main-content">
            <div className="full-view-content">
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link to="/create-new-post" className="back-right-part">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                  </div>
                  <div className="follow-main-data">
                    <div className="commn-form-feild">
                      <div className="commn-form-title mb-4">
                        <h2>Create New Post</h2>
                      </div>
                      <Form>
                        <Form.Group
                          className="mb-3 detail-view-inputs"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Search Music</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Search..."
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                          />
                        </Form.Group>
                      </Form>

                      <div className="likes-list-contain">
                        <InfiniteScroll
                          dataLength={items.length} // Length of the current items
                          next={fetchMoreData} // Function to fetch more data
                          hasMore={hasMore} // Whether there are more items to load
                          // loader={<h4>Loading...</h4>} // Loading indicator
                          endMessage={
                            <p style={{ textAlign: "center" }}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          } // End message
                        >
                          {items.length > 0 ? (
                            items.map((track) => {
                              return (
                                <div
                                  className="likes-list-card"
                                  key={track?.id}
                                >
                                  <div
                                    className="likes-list-pro"
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      onClick={() => handleSelectSong(track)}
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {" "}
                                      <img
                                        src={track?.album?.cover}
                                        alt="img"
                                      />
                                      <div>
                                        {track?.title}

                                        <p>{track?.artist?.name}</p>
                                      </div>
                                    </div>

                                    <div className="music-controls-ad">
                                      <button
                                        onClick={(e) => {
                                          //   e.stopPropagation();
                                          playTrack(track);
                                        }}
                                        className="play-btn"
                                      >
                                        {isPlaying &&
                                        currentTrack?.id === track?.id ? (
                                          <FaPause />
                                        ) : (
                                          <FaPlay />
                                        )}
                                      </button>
                                      {/* {isPlaying &&
                                        currentTrack?.id === track?.id && (
                                          <button
                                            onClick={() => {
                                              setIsPlaying(false);
                                              currentTrack?.audio.pause();
                                            }}
                                            className="stop-btn"
                                          >
                                            <FaStop />
                                          </button>
                                        )} */}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p
                              style={{ textAlign: "center", marginTop: "50px" }}
                            >
                              No song found
                            </p>
                          )}
                        </InfiniteScroll>
                      </div>

                      <div className="common-form-btn">
                        <Button onClick={handleSubmit}>Post</Button>
                      </div>
                      <div
                        onClick={() => {
                          navigate("/social-posts");
                          dispatch(createPostData(null));
                        }}
                        className="filters-clear"
                      >
                        <Link>Cancel</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
