import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Layout from "../Components/Layout";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import useQueryHook from "../myHooks/queryHook";
import { profile } from "../redux/Actions/userActions";
import { Field, Formik, Form as FormikForm } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { advanceFilter, filters } from "../redux/Reducers/fIlterSlice";
import toast from "react-hot-toast";
import RangeComponent from "../Components/RangeSlider";
import Seekingfor from "./Seekingfor";
import ProfilePicture from "../Components/ProfilePicture";
// import { useNavigate } from "react-router-dom";

export default function AdvanceFilters() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
 

  // =================================================GET PROFILE filterData========================================



  const filterData = useSelector(
    (state) => state?.filterData?.advancefilterState
  );
  console.log(filterData, "filterfilterData");

  // ========================================initialvalues====================================================

  const initialvalues = {
    advancegender: filterData?.advancegender ?? "",
    advancelookingFor: filterData?.advancelookingFor ?? "",
    advancecountry: filterData?.advancecountry ?? "",
    advancestate: filterData?.advancestate ?? "",
    advancecity: filterData?.advancecity ?? "",

    ageRange: (filterData?.ageRange && [
      filterData?.ageRange[0],
      filterData?.ageRange[1],
    ]) ?? [0, 80], // Default age range
    distanceRange: (filterData?.distanceRange && [
      filterData?.distanceRange[0],
      filterData?.distanceRange[1],
    ]) ?? [0, 100], // Default distance range
    maritalStatus: filterData?.maritalStatus ?? "",
    hasChildren: filterData?.hasChildren ?? "",
    numberOfChildren: filterData?.numberOfChildren ?? "",
    youngestChild: filterData?.youngestChild ?? "",
    oldestChild: filterData?.oldestChild ?? "",
    wantMoreKids: filterData?.wantMoreKids ?? "",
    drink: filterData?.drink ?? "",
    smoke: filterData?.smoke ?? "",
    hasPets: filterData?.hasPets ?? "",
    willingToMove: filterData?.willingToMove ?? "",
    occupation: filterData?.occupation ?? "",
    sport: filterData?.sport ?? "",
    height: filterData?.height ?? "",
    weight: filterData?.weight ?? "",
    bodyType: filterData?.bodyType ?? "",
    bodyArt: filterData?.bodyArt ?? "",
    eyeColor: filterData?.eyeColor ?? "",
    hairColor: filterData?.hairColor ?? "",
    ethnicity: filterData?.ethnicity ?? "",
    nationality: filterData?.nationality ?? "",
    education: filterData?.education ?? "",
    languageAbility: filterData?.languageAbility ?? "",
    languageSpoken: filterData?.languageSpoken ?? "",
    religion: filterData?.religion ?? "",
    starSign: filterData?.starSign ?? "",
  };

  return (
    <Layout>
      <div className="right-side">
        {/* <div className="commn-right-top">
          <div className="search-nav-bar">
            <input type="search" placeholder="Search here..." />
          </div>
          <div className="notify-bell">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="30"
              viewBox="0 0 28 30"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M14.3331 0.00156536C14.4077 0.0367861 14.4814 0.0735721 14.5568 0.105662C15.0768 0.324813 15.3733 0.684847 15.3935 1.20298C15.4084 1.59276 15.4057 1.9841 15.397 2.37387C15.3935 2.5398 15.4478 2.60946 15.6443 2.64155C19.7215 3.30292 22.7604 5.92177 23.6032 9.53463C23.7471 10.1498 23.7778 10.7924 23.7962 11.4232C23.8348 12.7397 23.7672 14.0617 23.8584 15.3742C24.0146 17.6197 25.0959 19.4935 26.9692 20.9947C27.5095 21.4275 27.9243 21.9104 27.9892 22.5741C28.1173 23.8906 27.092 24.9276 25.6055 24.9989C25.5178 25.0028 25.4309 25.002 25.3432 25.002C17.7851 25.002 10.2269 25.0004 2.66877 25.0043C1.79701 25.0051 1.05856 24.7656 0.512174 24.1387C-0.301701 23.2057 -0.132436 21.9245 0.926127 21.0972C1.84963 20.374 2.60211 19.5342 3.16516 18.5605C3.88081 17.3223 4.20618 16.0019 4.20881 14.6135C4.21145 13.4128 4.19303 12.2122 4.21846 11.0116C4.30441 6.90169 7.76513 3.32405 12.3037 2.64938C12.5616 2.61103 12.6317 2.52806 12.6159 2.31361C12.5984 2.08037 12.6326 1.84322 12.608 1.61154C12.5256 0.83747 12.8518 0.28881 13.6789 0C13.8955 0.00156536 14.1139 0.00156536 14.3331 0.00156536Z"
                  fill="black"
                />
                <path
                  d="M8.89035 26.259C12.3125 26.259 15.7092 26.259 19.1059 26.259C19.0348 28.0529 16.573 29.9251 14.2261 29.9971C11.3118 30.0863 9.22712 28.1225 8.89035 26.259Z"
                  fill="black"
                />
              </g>
            </svg>
          </div>
        </div> */}
        <div className="right-side-flow">
          <div className="main-content">
            <div className="main-content-contin">
              <div className="common-page-left-part">
              <ProfilePicture/>
               
              </div>
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link to="/filters" className="back-right-part">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    {/* <div className="online">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill="#3BAD45" />
                      </svg>
                      Online
                    </div> */}
                  </div>
                  <div className="follow-main-filterData">
                    <div className="commn-form-feild">
                      <div className="commn-form-title mb-4">
                        <h2>Advance Filters</h2>
                        <p>
                          Please select your preferences to
                          <br />
                          filter the cards
                        </p>
                      </div>
                      <div className="commn-form-feild">
                        <Formik
                          enableReinitialize={true}
                          initialValues={initialvalues}
                          onSubmit={(values) => {
                            const allValuesEmpty = Object.values(values).every(
                              (value) => {
                                // Check if the value is an empty string
                                if (value === "") return true;

                                // Check if it's ageRange and matches [0, 0]
                                if (
                                  Array.isArray(value) &&
                                  value.length === 2 &&
                                  
                                  value[1] === 0
                                ) {
                                  return true;
                                }

                                // Otherwise, value is considered not empty
                                return false;
                              }
                            );

                            console.log(allValuesEmpty, "{alllllll");

                            // Show error if all values are empty or specific arrays are [0, 0]
                            if (allValuesEmpty) {
                              toast.error("Please select at least one option");
                            } else {
                              // Filter out empty values from `values`
                              const filteredValues = Object.fromEntries(
                                Object.entries(values).filter(
                                  ([_, value]) =>
                                    value !== "" 
                                   
                                )
                              );

                              // Dispatch with only the non-empty values
                              dispatch(
                                advanceFilter({
                                  ...filterData,

                                  ...filteredValues,
                                })
                              );
                              navigate("/user-profile");
                            }
                          }}
                        >
                          {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => {
                            return (
                              <FormikForm>
                                <Form.Group className="mb-3" controlId="gender">
                                  <Form.Label>Preferred Gender</Form.Label>
                                  <Form.Select
                                    name="advancegender"
                                    onChange={handleChange}
                                    value={values.advancegender}
                                    aria-label="Default select example"
                                  >
                                    <option value="">Select</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>
                                    <option value="3">Other</option>
                                  </Form.Select>
                                </Form.Group>

                                {/* Looking For Field */}
                                <Form.Group
                                  className="mb-3"
                                  controlId="lookingFor"
                                >
                                  <Form.Label>Looking For</Form.Label>
                                  <Form.Select
                                    name="advancelookingFor"
                                    onChange={handleChange}
                                    value={values.advancelookingFor}
                                    aria-label="Default select example"
                                  >
                                    <option value="">Select</option>
                                    <option value="0">Divorced</option>
                                    <option value="1">Friendship</option>
                                    <option value="2">
                                      Fun with another Partner
                                    </option>
                                    <option value="3">Married</option>
                                    <option value="4">
                                      Serious Relationship
                                    </option>
                                    <option value="5">Single</option>
                                    <option value="6">Widowed</option>
                                  </Form.Select>
                                </Form.Group>

                                {/* Address Fields */}
                                <Form.Group
                                  className="mb-3"
                                  controlId="country"
                                >
                                  <Form.Label>Address</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Country"
                                    name="advancecountry"
                                    onChange={handleChange}
                                    value={values.advancecountry}
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="state">
                                  <Form.Control
                                    type="text"
                                    placeholder="State"
                                    name="advancestate"
                                    onChange={handleChange}
                                    value={values.advance}
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="city">
                                  <Form.Control
                                    type="text"
                                    placeholder="City"
                                    name="advancecity"
                                    onChange={handleChange}
                                    value={values.advance}
                                  />
                                </Form.Group>

                                {/* RangeComponent */}
                                <RangeComponent
                                  setAgeRange={(range) =>
                                    setFieldValue("ageRange", range)
                                  }
                                  ageRange={values.ageRange}
                                  setDistanceRange={(range) =>
                                    setFieldValue("distanceRange", range)
                                  }
                                  distanceRange={values.distanceRange}
                                />
                                <p className="basic-details-titles">
                                  He/she lifestyle
                                </p>

                                <Form.Group
                                  className="mb-3"
                                  controlId="maritalStatus"
                                >
                                  <Form.Label>Marital Status</Form.Label>
                                  <Form.Select
                                    name="maritalStatus"
                                    value={values.maritalStatus}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value="">
                                      Open this select menu
                                    </option>
                                    <option value="0">Divorced</option>
                                    <option value="1">Married</option>
                                    <option value="2">Single</option>
                                    <option value="3">Widowed</option>
                                    <option value="5">Prefer not to say</option>
                                  </Form.Select>
                                </Form.Group>

                                {/* <Form.Group
                                  className="mb-3"
                                  controlId="lifestyle"
                                >
                                  <Form.Label>
                                    What are you looking for?
                                  </Form.Label>
                                  <Form.Select
                                    name="lifestyle"
                                    value={values.lifestyle}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value="">
                                      Open this select menu
                                    </option>
                                    <option value="0">Divorced</option>
                                    <option value="1">Friendship</option>
                                    <option value="2">
                                      Fun with another Partner
                                    </option>
                                    <option value="3">Married</option>
                                    <option value="4">
                                      Serious Relationship
                                    </option>
                                    <option value="5">Single</option>
                                    <option value="6">Widowed</option>
                                  </Form.Select>
                                </Form.Group> */}

                                <Form.Group
                                  className="mb-3"
                                  controlId="hasChildren"
                                >
                                  <Form.Label>
                                    Does he/she has a children ?
                                  </Form.Label>
                                  <Form.Select
                                    name="hasChildren"
                                    value={values.hasChildren}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value="">
                                      Open this select menu
                                    </option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                  </Form.Select>
                                </Form.Group>

                                <Row>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="numberOfChildren"
                                    >
                                      <Form.Label>
                                        Number of children
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="numberOfChildren"
                                        value={values.numberOfChildren}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter here..."
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="youngestChild"
                                    >
                                      <Form.Label>Youngest child</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="youngestChild"
                                        value={values.youngestChild}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter here..."
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="oldestChild"
                                    >
                                      <Form.Label>Oldest child</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="oldestChild"
                                        value={values.oldestChild}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter here..."
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="wantMoreKids"
                                    >
                                      <Form.Label>
                                        Does he/she want (more) kids ?
                                      </Form.Label>
                                      <Form.Select
                                        name="wantMoreKids"
                                        value={values.wantMoreKids}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="drink"
                                    >
                                      <Form.Label>
                                        Does He/she drink?
                                      </Form.Label>
                                      <Form.Select
                                        name="drink"
                                        value={values.drink}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                        <option value="2">
                                          Prefer not to say
                                        </option>
                                        <option value="3">Not selected</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="smoke"
                                    >
                                      <Form.Label>
                                        Does he/she smoke?
                                      </Form.Label>
                                      <Form.Select
                                        name="smoke"
                                        value={values.smoke}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                        <option value="2">
                                          Prefer not to say
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Form.Group
                                  className="mb-3"
                                  controlId="hasPets"
                                >
                                  <Form.Label>
                                    Does he/she has a pets?
                                  </Form.Label>
                                  <Form.Select
                                    name="hasPets"
                                    value={values.hasPets}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value="">
                                      Open this select menu
                                    </option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                  </Form.Select>
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="willingToMove"
                                >
                                  <Form.Label>
                                    Are He/she a willing to move?
                                  </Form.Label>
                                  <Form.Select
                                    name="willingToMove"
                                    value={values.willingToMove}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value="">
                                      Open this select menu
                                    </option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                  </Form.Select>
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="occupation"
                                >
                                  <Form.Label>Occupation?</Form.Label>
                                  <Field
                                    as="input"
                                    type="text"
                                    name="occupation"
                                    placeholder="Enter here..."
                                    className="form-control"
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="sport">
                                  <Form.Label>Sports?</Form.Label>
                                  <Field
                                    as="select"
                                    name="sport"
                                    className="form-control"
                                  >
                                    <option value="">
                                      Open this select menu
                                    </option>
                                    <option value="0">Soccer (Football)</option>
                                    <option value="1">Basketball</option>
                                    <option value="2">Tennis</option>
                                    <option value="3">Golf</option>
                                    <option value="4">Cricket</option>
                                    <option value="5">Rugby</option>
                                    <option value="6">Baseball</option>
                                    <option value="7">Swimming</option>
                                    <option value="8">
                                      Athletics (Track and Field)
                                    </option>
                                    <option value="9">Boxing</option>
                                    <option value="10">Volleyball</option>
                                    <option value="11">Ice Hockey</option>
                                    <option value="12">Surfing</option>
                                    <option value="13">
                                      Skiing (Alpine, Cross-Country)
                                    </option>
                                    <option value="14">Gymnastics</option>
                                  </Field>
                                </Form.Group>

                                <Row>
                                  <p className="basic-details-titles">
                                    He/She appearance
                                  </p>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="height"
                                    >
                                      <Form.Label>Height (ft)</Form.Label>
                                      <Field
                                        as="input"
                                        type="text"
                                        name="height"
                                        placeholder="Enter here..."
                                        className="form-control"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="weight"
                                    >
                                      <Form.Label>Weight (Kgs)</Form.Label>
                                      <Field
                                        as="input"
                                        type="text"
                                        name="weight"
                                        placeholder="Enter here..."
                                        className="form-control"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="bodyType"
                                    >
                                      <Form.Label>Body Type</Form.Label>
                                      <Field
                                        as="select"
                                        name="bodyType"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Athletic</option>
                                        <option value="1">Average</option>
                                        <option value="2">Bodybuilder</option>
                                        <option value="3">Curvy</option>
                                        <option value="4">Full-figured</option>
                                        <option value="5">Muscular</option>
                                        <option value="6">Petite</option>
                                        <option value="7">Slim</option>
                                        <option value="8">Stocky</option>
                                        <option value="9">Thin</option>
                                        <option value="10">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="bodyArt"
                                    >
                                      <Form.Label>Body Art</Form.Label>
                                      <Field
                                        as="select"
                                        name="bodyArt"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Body jewelry</option>
                                        <option value="1">
                                          Body modifications
                                        </option>
                                        <option value="2">Body painting</option>
                                        <option value="3">Branding</option>
                                        <option value="4">Henna</option>
                                        <option value="5">Piercings</option>
                                        <option value="6">Scarification</option>
                                        <option value="7">Tattoos</option>
                                        <option value="8">
                                          Temporary tattoos
                                        </option>
                                        <option value="9">
                                          UV ink tattoos
                                        </option>
                                        <option value="10">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="eyeColor"
                                    >
                                      <Form.Label>Eye Color</Form.Label>
                                      <Field
                                        as="select"
                                        name="eyeColor"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Amber</option>
                                        <option value="1">Black</option>
                                        <option value="2">Blue</option>
                                        <option value="3">Brown</option>
                                        <option value="4">Gray</option>
                                        <option value="5">Green</option>
                                        <option value="6">Hazel</option>
                                        <option value="7">Henna</option>
                                        <option value="8">Other</option>
                                        <option value="9">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="hairColor"
                                    >
                                      <Form.Label>Hair Color</Form.Label>
                                      <Field
                                        as="select"
                                        name="hairColor"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Auburn</option>
                                        <option value="1">Black</option>
                                        <option value="2">Blonde</option>
                                        <option value="3">Brown</option>
                                        <option value="4">Chestnut</option>
                                        <option value="5">Gray/Silver</option>
                                        <option value="6">
                                          Platinum blonde
                                        </option>
                                        <option value="7">Red</option>
                                        <option value="8">
                                          Strawberry blonde
                                        </option>
                                        <option value="9">White</option>
                                      </Field>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={12}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="ethnicity"
                                    >
                                      <Form.Label>Ethnicity</Form.Label>
                                      <Field
                                        as="select"
                                        name="ethnicity"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">
                                          African American
                                        </option>
                                        <option value="1">Asian</option>
                                        <option value="2">Caucasian</option>
                                        <option value="3">
                                          Hispanic/Latino
                                        </option>
                                        <option value="4">
                                          Middle Eastern
                                        </option>
                                        <option value="5">Mixed</option>
                                        <option value="6">
                                          Native American/Indigenous
                                        </option>
                                        <option value="7">Other</option>
                                        <option value="8">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  {/* <p className="basic-details-titles">
                                    Your background/Cultural value
                                  </p> */}
                                  <Col lg={12}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="nationality"
                                    >
                                      <Form.Label>Nationality</Form.Label>
                                      <Field
                                        as="select"
                                        name="nationality"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">American</option>
                                        <option value="1">Australian</option>
                                        <option value="2">Belgian</option>
                                        <option value="3">Brazilian</option>
                                        <option value="4">British</option>
                                        <option value="5">Canadian</option>
                                        <option value="6">Chinese</option>
                                        <option value="7">Dutch</option>
                                        <option value="8">French</option>
                                        <option value="9">German</option>
                                        <option value="10">Indian</option>
                                        <option value="11">Italian</option>
                                        <option value="12">Japanese</option>
                                        <option value="13">Korean</option>
                                        <option value="14">Mexican</option>
                                        <option value="15">Norwegian</option>
                                        <option value="16">Russian</option>
                                        <option value="17">Spanish</option>
                                        <option value="18">Swedish</option>
                                        <option value="19">Swiss</option>
                                        <option value="20">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={12}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="education"
                                    >
                                      <Form.Label>Education</Form.Label>
                                      <Field
                                        as="select"
                                        name="education"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">
                                          Associate's degree
                                        </option>
                                        <option value="1">
                                          Bachelor's degree
                                        </option>
                                        <option value="2">
                                          Doctorate degree
                                        </option>
                                        <option value="3">
                                          High school diploma
                                        </option>
                                        <option value="4">
                                          High school/GED
                                        </option>
                                        <option value="5">
                                          Master's degree
                                        </option>
                                        <option value="6">
                                          Professional certification
                                        </option>
                                        <option value="7">
                                          Some college, no degree
                                        </option>
                                        <option value="8">
                                          Vocational training
                                        </option>
                                        <option value="9">Other</option>
                                        <option value="10">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                    </Form.Group>
                                  </Col>

                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="languageAbility"
                                    >
                                      <Form.Label>Language Ability</Form.Label>
                                      <Field
                                        as="select"
                                        name="languageAbility"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Basic</option>
                                        <option value="1">Bilingual</option>
                                        <option value="2">Fluent</option>
                                        <option value="3">Intermediate</option>
                                        <option value="4">Limited</option>
                                        <option value="5">Multilingual</option>
                                        <option value="6">
                                          Native Speaker
                                        </option>
                                        <option value="7">None</option>
                                        <option value="8">Other</option>
                                        <option value="9">Proficient</option>
                                        <option value="10">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="languageSpoken"
                                    >
                                      <Form.Label>Language Spoken</Form.Label>
                                      <Field
                                        as="select"
                                        name="languageSpoken"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Arabic</option>
                                        <option value="1">Chinese</option>
                                        <option value="2">English</option>
                                        <option value="3">French</option>
                                        <option value="4">German</option>
                                        <option value="5">Hindi</option>

                                        <option value="6">Japanese</option>

                                        <option value="7">Portuguese</option>
                                        <option value="8">Russian</option>
                                        <option value="9">Spanish</option>
                                      </Field>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={12}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="religion"
                                    >
                                      <Form.Label>Religion</Form.Label>
                                      <Field
                                        as="select"
                                        name="religion"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Agnosticism</option>
                                        <option value="1">Atheism</option>
                                        <option value="2">Bahá'í Faith</option>
                                        <option value="3">Buddhism</option>
                                        <option value="4">Christianity</option>
                                        <option value="5">Hinduism</option>
                                        <option value="6">Islam</option>
                                        <option value="7">Jainism</option>
                                        <option value="8">Judaism</option>
                                        <option value="9">No religion</option>
                                        <option value="10">
                                          Other (Specify)
                                        </option>
                                        <option value="11">Shintoism</option>
                                        <option value="12">Sikhism</option>
                                        <option value="13">
                                          Spiritual but not religious
                                        </option>
                                        <option value="14">Taoism</option>
                                        <option value="15">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={12}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="starSign"
                                    >
                                      <Form.Label>Star Sign</Form.Label>
                                      <Field
                                        as="select"
                                        name="starSign"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Aquarius</option>
                                        <option value="1">Aries</option>
                                        <option value="2">Cancer</option>
                                        <option value="3">Capricorn</option>
                                        <option value="4">Gemini</option>
                                        <option value="5">Leo</option>
                                        <option value="6">Libra</option>
                                        <option value="7">Pisces</option>
                                        <option value="8">Sagittarius</option>
                                        <option value="9">Scorpio</option>
                                        <option value="10">Taurus</option>
                                        <option value="11">Virgo</option>
                                        <option value="12">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <div className="common-form-btn">
                                  <Button
                                    type="submit"
                                    // onClick={() => navigate("/account-preferences")}
                                  >
                                    Apply
                                  </Button>
                                </div>
                                <div
                                  onClick={() => {
                                    dispatch(advanceFilter(null));
                                    dispatch(filters(null));
                                    navigate("/user-profile");
                                  }}
                                  className="filters-clear"
                                >
                                  <Link>Clear Filter</Link>
                                </div>
                              </FormikForm>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
