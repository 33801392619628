import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import ProfileDetails from "./pages/ProfileDetails";
import SelectGender from "./pages/SelectGender";
import TellUsAbout from "./pages/TellUsAbout";
import ProfilePreference from "./pages/ProfilePreference";
import ProfilePreferenceTwo from "./pages/ProfilePreferenceTwo";
import CompleteYourProfile from "./pages/CompleteYourProfile";
import ProfilePreferenceKids from "./pages/ProfilePreferenceKids";
import SubscriptionPlans from "./pages/SubscriptionPlans";
import IdentifyYourself from "./pages/IdentifyYourself";
import CompanyDetails from "./pages/CompanyDetails";
import AllowLocation from "./pages/AllowLocation";
import Occupation from "./pages/Occupation";
import YourPlace from "./pages/YourPlace";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Signup from "./pages/Signup";
import OTPVerification from "./pages/OTPVerification";
import Dashboard from "./pages/Dashboard";
import ViewProfile from "./pages/ViewProfile";
import FollowingFollowers from "./pages/FollowingFollowers";
import Reviews from "./pages/Reviews";
import ProfileDetailExpanded from "./pages/ProfileDetailExpanded";
import PictureExpanded from "./pages/PictureExpanded";
import RestrictPerson from "./pages/RestrictPerson";
import UserProfile from "./pages/UserProfile";
import Seekingfor from "./pages/Seekingfor";
import CompleteProfile from "./pages/CompleteProfile";
import HobbiesInterests from "./pages/HobbiesInterests";
import BasicDetails from "./pages/BasicDetails";
import AccountPreferences from "./pages/AccountPreferences";
import PlansForYou from "./pages/PlansForYou";
import Filters from "./pages/Filters";
import AdvanceFilters from "./pages/AdvanceFilters";
import Friendship from "./pages/Friendship";
import TravelCompanian from "./pages/TravelCompanian";
import JobService from "./pages/JobService";
import SportsCompanion from "./pages/SportsCompanion";
import SocialPosts from "./pages/SocialPosts";
import Likes from "./pages/Likes";
import ProfileDetailView from "./pages/ProfileDetailView";
import Notifications from "./pages/Notifications";
import Settings from "./pages/Settings";
import Support from "./pages/Support";
import MySubscription from "./pages/MySubscription";
import SwitchMode from "./pages/SwitchMode";
import OccupationSettings from "./pages/OccupationSettings";
import CompanySettings from "./pages/CompanySettings";
import ChangePasswordSettings from "./pages/ChangePasswordSettings";
import UserProfileScreen from "./pages/UserProfileScreen";
import ProfilePreferences from "./pages/ProfilePreferences";
import AccountPreferencePlans from "./pages/AccountPreferencePlans";
import PlansForJobs from "./pages/PlansForJobs";
import PlansForAdvertising from "./pages/PlansForAdvertising";
import MyPosts from "./pages/MyPosts";
import CreateNewPost from "./pages/CreateNewPost";
import TagFriends from "./pages/TagFriends";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import PublicProtectedRoutes from "./Components/PublicProtectedRoute";
import AddMusic from "./pages/AddMusic";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route element={<PublicProtectedRoutes />}> */}
          <Route path="/" element={<Landing />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password/:id" element={<ResetPassword />}></Route>
          <Route path="/sign-up" element={<Signup />}></Route>
          <Route path="/otp-verification" element={<OTPVerification />}></Route>
        {/* </Route> */}
        <Route element={<ProtectedRoutes />}>
          <Route path="/profile-details" element={<ProfileDetails />}></Route>
          <Route path="/select-gender" element={<SelectGender />}></Route>
          <Route path="/tell-us-about" element={<TellUsAbout />}></Route>
          <Route
            path="/profile-preference"
            element={<ProfilePreference />}
          ></Route>
          <Route
            path="/profile-preference-two"
            element={<ProfilePreferenceTwo />}
          ></Route>
          <Route
            path="/complete-your-profile"
            element={<CompleteYourProfile />}
          ></Route>
          <Route
            path="/profile-preference-kids"
            element={<ProfilePreferenceKids />}
          ></Route>
          <Route
            path="/subscription-plans"
            element={<SubscriptionPlans />}
          ></Route>
          <Route
            path="/identify-yourself"
            element={<IdentifyYourself />}
          ></Route>
          <Route path="/company-details" element={<CompanyDetails />}></Route>
          <Route path="/allow-location" element={<AllowLocation />}></Route>
          <Route path="/occupation" element={<Occupation />}></Route>
          {/* <Route path="/your-place" element={<YourPlace />}></Route> */}

          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/view-profile" element={<ViewProfile />}></Route>
          <Route
            path="/following-followers"
            element={<FollowingFollowers />}
          ></Route>
          <Route path="/reviews" element={<Reviews />}></Route>
          <Route
            path="/profile-see-more"
            element={<ProfileDetailExpanded />}
          ></Route>
          <Route path="/picture-expanded" element={<PictureExpanded />}></Route>
          <Route path="/restrict-person" element={<RestrictPerson />}></Route>
          <Route path="/user-profile" element={<UserProfile />}></Route>
          <Route path="/seeking-for" element={<Seekingfor />}></Route>
          <Route path="/complete-profile" element={<CompleteProfile />}></Route>
          <Route
            path="/hobbies-interests"
            element={<HobbiesInterests />}
          ></Route>
          <Route path="/basic-details" element={<BasicDetails />}></Route>
          <Route
            path="/account-preferences"
            element={<AccountPreferences />}
          ></Route>
          <Route path="/plans-for-you" element={<PlansForYou />}></Route>
          <Route path="/filters" element={<Filters />}></Route>
          <Route path="/advance-filters" element={<AdvanceFilters />}></Route>
          <Route path="/friendship" element={<Friendship />}></Route>
          <Route path="/travel-companian" element={<TravelCompanian />}></Route>
          <Route path="/job-service" element={<JobService />}></Route>
          <Route path="/sports-companion" element={<SportsCompanion />}></Route>
          <Route path="/social-posts" element={<SocialPosts />}></Route>
          <Route path="/likes" element={<Likes />}></Route>
          <Route
            path="/profile-detail-view"
            element={<ProfileDetailView />}
          ></Route>
          <Route path="/notifications" element={<Notifications />}></Route>
          <Route path="/settings" element={<Settings />}></Route>
          <Route path="/support" element={<Support />}></Route>
          <Route path="/my-subscription" element={<MySubscription />}></Route>
          <Route path="/switch-mode" element={<SwitchMode />}></Route>
          <Route
            path="/occupation-settings"
            element={<OccupationSettings />}
          ></Route>
          <Route path="/company-settings" element={<CompanySettings />}></Route>
          <Route
            path="/change-password-settings"
            element={<ChangePasswordSettings />}
          ></Route>
          <Route
            path="/user-profile-screen"
            element={<UserProfileScreen />}
          ></Route>
          <Route
            path="/profile-preferences"
            element={<ProfilePreferences />}
          ></Route>
          <Route
            path="/account-preference-plans"
            element={<AccountPreferencePlans />}
          ></Route>
          <Route path="/plans-for-jobs" element={<PlansForJobs />}></Route>
          <Route
            path="/plans-for-advertising"
            element={<PlansForAdvertising />}
          ></Route>
          <Route path="/my-posts" element={<MyPosts />}></Route>
          <Route path="/create-new-post" element={<CreateNewPost />}></Route>
          <Route path="/tag-friends" element={<TagFriends />}></Route>
          <Route path="/add-music" element={<AddMusic />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
