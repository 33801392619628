import { Button, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { ProfileData } from "../redux/Reducers/profileSlice";
import useMutationHook from "../myHooks/mutationHook";
import { editprofile } from "../redux/Actions/userActions";
import Loader from "../Components/Loader";
import LocationSearch from "../Components/LocationSearch";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";

export default function AllowLocation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state?.profileData?.profiledata);
  console.log(data, "prevData");

  // ===============================================editProfile api==========================================

  const {
    mutate,
    isPending,
    isSuccess,
    data: editProfileData,
  } = useMutationHook(editprofile);

  useEffect(() => {
    if (editProfileData?.status === 200) {
      console.log(editProfileData, "datdatadtad");
      // sessionStorage.setItem("token", data?.data?.token);

      // navigate("/dashboard");
      window.location.href = "/dashboard";
      dispatch(ProfileData(null));
    }
  }, [isSuccess]);

  // ===========================================search address========================================

  const [searchVal, setSearchVal] = useState({
    label: "",
    value: {
      description: "",
      place_id: "",
    },
  });
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const handleLocationSelect = async (selected) => {
    console.log("Selected Object: ", selected);

    if (selected && selected.value && selected.value.place_id) {
      const placeId = selected.value.place_id;

      // Update searchVal with label and place_id
      setSearchVal({
        label: selected.label,
        value: {
          description: selected.value.description || "",
          place_id: placeId,
        },
      });

      // Fetch the place details to get coordinates
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${selected?.label}&key=AIzaSyBY7WePV2Eg7cigEfbsFvjs1GzEHx6GXVA`
        );
        const data = await response.json();
        console.log(data?.results[0]?.geometry?.location, "datdatdatdatdata");
        setLat(data?.results[0]?.geometry?.location?.lat);
        setLng(data?.results[0]?.geometry?.location?.lng);
      } catch (error) {
        console.error("Error fetching place details: ", error);
      }
    } else {
      console.error("Invalid or missing place_id:", selected);
    }
  };

  console.log(searchVal, "search");
  console.log(lat, lng, "lllaaatttt");

  // ==========================================allow location============================================

  const handleAllowLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("latitude, longitude ", latitude, longitude);
          if (latitude && longitude) {
            let formData = new FormData();

            formData.append("profile_completed", true);
            formData.append("sur_name", data?.lastName);
            formData.append("nick_name", data?.nickName);
            formData.append("image", data?.image);

            data?.images?.forEach((x) =>
              formData.append(`images`, x?.image[0])
            );
            data?.images?.forEach((x) => console.log(`images`, x?.image[0]));

            formData.append("name", data?.firstName);
            formData.append("country", data?.country);
            formData.append("state", data?.state);
            formData.append("city", data?.city);
            formData.append("gender", data?.gender);
            formData.append("show_gender", data?.showGender);
            formData.append("looking_for_soulmate", data?.lookingForSoulmate);
            formData.append("about", data?.about);
            formData.append("profile_preference", data?.profilePreference);
            formData.append("lng", searchVal?.label ? lng : longitude);
            formData.append("lat", searchVal?.label ? lat : latitude);
            formData.append("allow_location", searchVal?.label ? 2 : 1);
            if (data?.identityNumber) {
              formData.append("identity_number", data?.identityNumber);
            }

            if (data?.companyName) {
              formData.append("company_name", data?.companyName);
              formData.append("company_city", data?.companyCity);
              formData.append("company_state", data?.companyState);
              formData.append("company_type", data?.companyType);
              formData.append("industry", data?.industry);
              formData.append("website", data?.website);
              formData.append("company_number", data?.number);
              formData.append("company_address", data?.companyAddress);
            }
            if (data?.occupation) {
              formData.append("occupation", data?.occupation);
              formData.append("occupation_types", data?.occupationArr);
            }

            mutate(formData);
          }
        },
        (error) => {
          toast.error(error.message);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };

  // // ============================================handleSaveLocation==================================

  // const handleSaveLocation=()=>{
  //   let formData = new FormData();

  //   formData.append("profile_completed", true);
  //   formData.append("sur_name", data?.lastName);
  //   formData.append("nick_name", data?.nickName);
  //   formData.append("image", data?.image);

  //   data?.images?.forEach((x) =>
  //     formData.append(`images`, x?.image[0])
  //   );
  //   data?.images?.forEach((x) => console.log(`images`, x?.image[0]));

  //   formData.append("name", data?.firstName);
  //   formData.append("country", data?.country);
  //   formData.append("state", data?.state);
  //   formData.append("city", data?.city);
  //   formData.append("gender", data?.gender);
  //   formData.append("show_gender", data?.showGender);
  //   formData.append("looking_for_soulmate", data?.lookingForSoulmate);
  //   formData.append("about", data?.about);
  //   formData.append("profile_preference", data?.profilePreference);
  //   formData.append("lng", lng);
  //   formData.append("lat", lat);
  //   formData.append("allow_location", 2);

  //   mutate(formData);

  // }

  return (
    <>
      {isPending && <Loader />}
      <section className="Common-sign-flow">
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="text-center mb-3">
                <img
                  className="Common-sign-logo"
                  src={require("../Assets/images/logo.svg").default}
                  alt="logo"
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="page-back-icon">
                <Link to="/profile-preference">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                      fill="black"
                    />
                  </svg>
                  Back
                </Link>
              </div>
              <div className="Common-sign-boxout">
                <Row>
                  <Col lg={12}>
                    <div className="select-gender-box">
                      <div className="select-gender-head ">
                        <h2>Allow Location</h2>

                        <p className="">
                          Discover nearby and global connections! Set your
                          <br /> location to make meaningful matches don’t miss
                          out!
                        </p>
                      </div>
                      <div className="allow-Location-box detail-view-inputs">
                        <Row>
                          <Col lg={5} className="mx-auto">
                            <div className="allow-Location-img">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="390"
                                height="390"
                                viewBox="0 0 390 390"
                                fill="none"
                              >
                                <g filter="url(#filter0_d_2919_33480)">
                                  <rect
                                    x="68"
                                    y="62"
                                    width="250"
                                    height="250"
                                    rx="125"
                                    fill="white"
                                  />
                                </g>
                                <g opacity="0.3">
                                  <path
                                    d="M164.599 170.719C164.706 156.986 174.861 145.404 188.737 143.219C203.482 140.894 217.917 150.894 220.807 165.299C221.998 171.23 220.958 176.811 218.735 182.302C216.182 188.611 212.811 194.515 208.846 200.061C204.758 205.782 200.369 211.301 196.102 216.903C195.887 217.183 195.643 217.449 195.4 217.708C193.994 219.193 191.893 219.249 190.58 217.68C181.975 207.47 173.957 196.868 168.306 184.746C166.535 180.943 165.165 177.015 164.728 172.82C164.663 172.127 164.642 171.426 164.599 170.719ZM192.954 181.875C199.344 181.896 204.572 176.811 204.557 170.6C204.543 164.416 199.351 159.346 193.026 159.332C186.651 159.318 181.416 164.416 181.423 170.628C181.437 176.804 186.608 181.853 192.954 181.875Z"
                                    fill="#B4B4B4"
                                  />
                                  <path
                                    d="M192.983 225.075C200.441 224.928 207.792 224.214 214.891 221.833C217.315 221.021 219.66 220.019 221.661 218.401C224.063 216.462 224.049 214.956 221.618 213.044C219.136 211.104 216.232 210.047 213.249 209.123C211.607 208.618 210.753 207.372 210.904 205.782C211.033 204.382 212.159 203.268 213.672 203.093C214.052 203.051 214.468 203.072 214.834 203.177C218.62 204.284 222.263 205.712 225.404 208.128C227.627 209.844 229.305 211.917 229.693 214.774C230.022 217.218 229.183 219.319 227.649 221.189C225.282 224.06 222.048 225.684 218.634 226.994C213.227 229.067 207.569 230.131 201.803 230.684C194.819 231.35 187.841 231.273 180.892 230.292C174.632 229.41 168.522 228.009 162.957 224.921C161.056 223.864 159.364 222.561 158.059 220.824C155.369 217.246 155.671 213.044 158.848 209.704C161.279 207.155 164.377 205.635 167.618 204.361C168.651 203.955 169.712 203.618 170.773 203.289C172.674 202.701 174.431 203.513 174.983 205.208C175.535 206.903 174.553 208.45 172.674 209.172C170.336 210.068 168.02 211.048 165.761 212.113C164.9 212.519 164.14 213.212 163.452 213.885C162.24 215.068 162.232 216.189 163.344 217.449C164.778 219.067 166.664 220.033 168.629 220.873C173.549 222.981 178.748 223.983 184.055 224.501C187.016 224.788 190 224.886 192.983 225.075Z"
                                    fill="#B4B4B4"
                                  />
                                </g>
                                <defs>
                                  <filter
                                    id="filter0_d_2919_33480"
                                    x="0"
                                    y="0"
                                    width="390"
                                    height="390"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      result="hardAlpha"
                                    />
                                    <feOffset dx="2" dy="8" />
                                    <feGaussianBlur stdDeviation="35" />
                                    <feComposite
                                      in2="hardAlpha"
                                      operator="out"
                                    />
                                    <feColorMatrix
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in2="BackgroundImageFix"
                                      result="effect1_dropShadow_2919_33480"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in="SourceGraphic"
                                      in2="effect1_dropShadow_2919_33480"
                                      result="shape"
                                    />
                                  </filter>
                                </defs>
                              </svg>
                            </div>
                            <Form>
                              {/* <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                
                                <Form.Control
                                  type="search"
                                  placeholder="Search new location"
                                />
                              </Form.Group> */}
                              <GooglePlacesAutocomplete
                                apiKey="AIzaSyBY7WePV2Eg7cigEfbsFvjs1GzEHx6GXVA"
                                selectProps={{
                                  // onChange: handleLocationSelect,
                                  onChange: handleLocationSelect,
                                  value: searchVal,
                                  isClearable: false,
                                  placeholder: "Enter new location",
                                }}
                              />
                            </Form>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="Common-sign-button">
                      {/* <Link to="/tell-us-about" className="back-btn">
                        Back
                      </Link> */}
                      <Button
                        onClick={handleAllowLocation}
                        className="continue-btn"
                      >
                        {searchVal?.label ? "Save" : "Submit"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
