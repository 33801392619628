import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import Slider from "react-slick";

export default function Dashboard() {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };
  return (
    <Layout>
      <div className="right-side">
        <div className="commn-right-top">
          <div className="search-nav-bar">
            <input type="search" placeholder="Search here..." />
          </div>
          <div className="notify-bell">
            <Link to="/notifications">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="30"
                viewBox="0 0 28 30"
                fill="none"
              >
                <g opacity="0.5">
                  <path
                    d="M14.3331 0.00156536C14.4077 0.0367861 14.4814 0.0735721 14.5568 0.105662C15.0768 0.324813 15.3733 0.684847 15.3935 1.20298C15.4084 1.59276 15.4057 1.9841 15.397 2.37387C15.3935 2.5398 15.4478 2.60946 15.6443 2.64155C19.7215 3.30292 22.7604 5.92177 23.6032 9.53463C23.7471 10.1498 23.7778 10.7924 23.7962 11.4232C23.8348 12.7397 23.7672 14.0617 23.8584 15.3742C24.0146 17.6197 25.0959 19.4935 26.9692 20.9947C27.5095 21.4275 27.9243 21.9104 27.9892 22.5741C28.1173 23.8906 27.092 24.9276 25.6055 24.9989C25.5178 25.0028 25.4309 25.002 25.3432 25.002C17.7851 25.002 10.2269 25.0004 2.66877 25.0043C1.79701 25.0051 1.05856 24.7656 0.512174 24.1387C-0.301701 23.2057 -0.132436 21.9245 0.926127 21.0972C1.84963 20.374 2.60211 19.5342 3.16516 18.5605C3.88081 17.3223 4.20618 16.0019 4.20881 14.6135C4.21145 13.4128 4.19303 12.2122 4.21846 11.0116C4.30441 6.90169 7.76513 3.32405 12.3037 2.64938C12.5616 2.61103 12.6317 2.52806 12.6159 2.31361C12.5984 2.08037 12.6326 1.84322 12.608 1.61154C12.5256 0.83747 12.8518 0.28881 13.6789 0C13.8955 0.00156536 14.1139 0.00156536 14.3331 0.00156536Z"
                    fill="black"
                  />
                  <path
                    d="M8.89035 26.259C12.3125 26.259 15.7092 26.259 19.1059 26.259C19.0348 28.0529 16.573 29.9251 14.2261 29.9971C11.3118 30.0863 9.22712 28.1225 8.89035 26.259Z"
                    fill="black"
                  />
                </g>
              </svg>
            </Link>
          </div>
        </div>
        <div className="right-side-flow">
          <div className="main-content">
            <div className="main-content-contin">
              <div className="common-page-left-part">
                <div className="new-match-contain">
                  <p>New Matches</p>
                  <div className="new-match-lists">
                    <Slider {...settings}>
                      <div className="story-img">
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>{" "}
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                    </Slider>
                    {/* <ul>
                      <li>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </li>
                    </ul> */}
                  </div>
                </div>
                <div className="message-contain">
                  <p>Messages</p>
                  <div className="message-lists">
                    <ul>
                      <li>
                        <div className="message-box-list active">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              {/* <span>1</span> */}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>{" "}
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>{" "}
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="common-page-right-part">
                <div className="page-right-part">
                  <div className="chat-top-profile">
                    <div className="">
                      <img src={require("../Assets/images/story-image.png")} />
                      <div className="chat-top-name">
                        <div className="">Devid,23</div>
                        <p>Chandigarh,23</p>
                      </div>
                    </div>
                    <Link to="/view-profile">View Profile</Link>
                  </div>
                  <div className="main-chat-box">
                    <div className="chat-box-show">
                      <div className="left-chat-side">
                        <div className="">
                          <p>Hi</p>
                        </div>
                        <div className="">
                          <p>How are you?</p>
                        </div>
                        <span>04:35 am</span>
                      </div>
                      <div className="right-chat-side">
                        <div className="">
                          <p>Hii</p>
                        </div>
                        <div className="">
                          <p>How are you?</p>
                        </div>
                        <span>04:35 am</span>
                      </div>
                    </div>
                    <div className="main-chat-typing">
                      <input type="text" placeholder="Type message.." />
                      <button>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="18"
                          viewBox="0 0 16 18"
                          fill="none"
                        >
                          <path
                            d="M0.523716 17.2572C0.110234 16.7692 -0.162488 16.0958 0.108768 15.3141C0.544243 14.0606 1.01344 12.8205 1.47531 11.576C1.69671 10.9782 1.93864 10.3879 2.16005 9.79155C2.20697 9.66398 2.26562 9.62838 2.39611 9.62986C4.24651 9.63431 6.09838 9.63134 7.94879 9.63431C8.17312 9.63431 8.3696 9.58536 8.51476 9.39994C8.67018 9.20266 8.70244 8.98609 8.60273 8.75765C8.50743 8.5396 8.33588 8.41055 8.09834 8.38533C8.02796 8.37791 7.95612 8.38088 7.88574 8.38088C6.06026 8.38088 4.23479 8.3794 2.41077 8.38533C2.26268 8.38533 2.19964 8.33786 2.14832 8.20139C1.4929 6.46736 0.828695 4.73629 0.174749 3.00225C-0.361897 1.57972 0.531047 0.117134 1.99729 0.00588286C2.4489 -0.0282342 2.86531 0.0874672 3.25533 0.315903C7.12476 2.57505 10.9956 4.83271 14.8651 7.09185C16.1803 7.86022 16.3914 9.58388 15.2932 10.6059C15.1671 10.7246 15.0205 10.8254 14.8709 10.913C11.0015 13.1736 7.13209 15.4327 3.2612 17.6904C2.36239 18.211 1.33308 18.0805 0.523716 17.2572Z"
                            fill="#2D5FE5"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
