import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import Slider from "react-slick";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import { Rating } from "react-simple-star-rating";
export default function Reviews() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  var settingsTwo = {
    dots: true,
    infinite: false,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };
  return (
    <Layout>
      <div className="right-side">
        <div className="commn-right-top">
          <div className="search-nav-bar">
            <input type="search" placeholder="Search here..." />
          </div>
          <div className="notify-bell">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="30"
              viewBox="0 0 28 30"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M14.3331 0.00156536C14.4077 0.0367861 14.4814 0.0735721 14.5568 0.105662C15.0768 0.324813 15.3733 0.684847 15.3935 1.20298C15.4084 1.59276 15.4057 1.9841 15.397 2.37387C15.3935 2.5398 15.4478 2.60946 15.6443 2.64155C19.7215 3.30292 22.7604 5.92177 23.6032 9.53463C23.7471 10.1498 23.7778 10.7924 23.7962 11.4232C23.8348 12.7397 23.7672 14.0617 23.8584 15.3742C24.0146 17.6197 25.0959 19.4935 26.9692 20.9947C27.5095 21.4275 27.9243 21.9104 27.9892 22.5741C28.1173 23.8906 27.092 24.9276 25.6055 24.9989C25.5178 25.0028 25.4309 25.002 25.3432 25.002C17.7851 25.002 10.2269 25.0004 2.66877 25.0043C1.79701 25.0051 1.05856 24.7656 0.512174 24.1387C-0.301701 23.2057 -0.132436 21.9245 0.926127 21.0972C1.84963 20.374 2.60211 19.5342 3.16516 18.5605C3.88081 17.3223 4.20618 16.0019 4.20881 14.6135C4.21145 13.4128 4.19303 12.2122 4.21846 11.0116C4.30441 6.90169 7.76513 3.32405 12.3037 2.64938C12.5616 2.61103 12.6317 2.52806 12.6159 2.31361C12.5984 2.08037 12.6326 1.84322 12.608 1.61154C12.5256 0.83747 12.8518 0.28881 13.6789 0C13.8955 0.00156536 14.1139 0.00156536 14.3331 0.00156536Z"
                  fill="black"
                />
                <path
                  d="M8.89035 26.259C12.3125 26.259 15.7092 26.259 19.1059 26.259C19.0348 28.0529 16.573 29.9251 14.2261 29.9971C11.3118 30.0863 9.22712 28.1225 8.89035 26.259Z"
                  fill="black"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="right-side-flow">
          <div className="main-content">
            <div className="main-content-contin">
              <div className="common-page-left-part">
                <div className="new-match-contain">
                  <p>New Matches</p>
                  <div className="new-match-lists">
                    <Slider {...settings}>
                      <div className="story-img">
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>{" "}
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                    </Slider>
                    {/* <ul>
                      <li>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </li>
                   
                    </ul> */}
                  </div>
                </div>
                <div className="message-contain">
                  <p>Messages</p>
                  <div className="message-lists">
                    <ul>
                      <li>
                        <div className="message-box-list active">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              {/* <span>1</span> */}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>{" "}
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>{" "}
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link to="/view-profile" className="back-right-part">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    {/* <div className="online">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill="#3BAD45" />
                      </svg>
                      Online
                    </div> */}
                  </div>
                  <div className="follow-main-data">
                    <h2>Reviews</h2>
                    <div className="reviews-list">
                      <div className="follow-btn-profile">
                        <button onClick={handleShow}>Add Reviews</button>
                      </div>
                      <div className="review-list-box">
                        <div className="review-list-item">
                          <div className="review-pro">
                            <img
                              src={require("../Assets/images/story-image.png")}
                            />
                            <div className="review-prof-details">
                              <h3>Devid,23</h3>
                              <ul>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>
                              </ul>
                              <p>
                                Lorem Ipsum is simply dummy text for printing.
                              </p>
                            </div>
                          </div>
                          <div className="review-time">
                            <p>12 mints ago</p>
                          </div>
                        </div>
                        <div className="review-list-item">
                          <div className="review-pro">
                            <img
                              src={require("../Assets/images/story-image.png")}
                            />
                            <div className="review-prof-details">
                              <h3>Devid,23</h3>
                              <ul>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>
                              </ul>
                              <p>
                                Lorem Ipsum is simply dummy text for printing.
                              </p>
                            </div>
                          </div>
                          <div className="review-time">
                            <p>12 mints ago</p>
                          </div>
                        </div>{" "}
                        <div className="review-list-item">
                          <div className="review-pro">
                            <img
                              src={require("../Assets/images/story-image.png")}
                            />
                            <div className="review-prof-details">
                              <h3>Devid,23</h3>
                              <ul>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>
                              </ul>
                              <p>
                                Lorem Ipsum is simply dummy text for printing.
                              </p>
                            </div>
                          </div>
                          <div className="review-time">
                            <p>12 mints ago</p>
                          </div>
                        </div>{" "}
                        <div className="review-list-item">
                          <div className="review-pro">
                            <img
                              src={require("../Assets/images/story-image.png")}
                            />
                            <div className="review-prof-details">
                              <h3>Devid,23</h3>
                              <ul>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>
                              </ul>
                              <p>
                                Lorem Ipsum is simply dummy text for printing.
                              </p>
                            </div>
                          </div>
                          <div className="review-time">
                            <p>12 mints ago</p>
                          </div>
                        </div>{" "}
                        <div className="review-list-item">
                          <div className="review-pro">
                            <img
                              src={require("../Assets/images/story-image.png")}
                            />
                            <div className="review-prof-details">
                              <h3>Devid,23</h3>
                              <ul>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>{" "}
                                <li>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="20"
                                    viewBox="0 0 22 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.4981 0C10.9331 0.00111198 11.154 0.175693 11.3054 0.4826C12.2046 2.30291 13.114 4.11989 14.0144 5.93909C14.0816 6.07475 14.1556 6.14147 14.3193 6.16482C16.3286 6.44615 18.3368 6.74083 20.345 7.02883C20.9112 7.11001 21.2162 7.40691 21.14 7.8706C21.1097 8.05185 20.9953 8.24756 20.8619 8.37878C19.3998 9.8099 17.9264 11.231 16.452 12.651C16.3421 12.7566 16.304 12.8489 16.332 13.0102C16.6886 15.0384 17.0339 17.0689 17.3849 19.0983C17.4387 19.4085 17.3523 19.6665 17.0989 19.8522C16.8366 20.0446 16.5551 20.0401 16.267 19.8911C14.4438 18.9393 12.6195 17.9919 10.7997 17.0344C10.6338 16.9466 10.5138 16.9544 10.3534 17.0389C8.55382 17.9841 6.7486 18.9204 4.94673 19.8622C4.68436 19.999 4.42535 20.0546 4.15064 19.9089C3.8636 19.7566 3.71223 19.4652 3.76829 19.1361C4.0273 17.6138 4.29192 16.0915 4.55429 14.5692C4.64848 14.0243 4.75051 13.4805 4.83124 12.9323C4.84358 12.8501 4.77967 12.73 4.71351 12.6655C3.3467 11.3355 1.97316 10.0101 0.601856 8.68457C0.479639 8.5667 0.358543 8.44772 0.236325 8.32985C0.0188011 8.11746 -0.0507169 7.85726 0.0367413 7.57037C0.125321 7.28125 0.333875 7.10667 0.638857 7.06219C1.78815 6.89428 2.93856 6.7286 4.08785 6.56291C4.99943 6.43059 5.91102 6.29492 6.82484 6.17149C6.99079 6.14925 7.07713 6.08365 7.15001 5.93464C8.04926 4.11433 8.9586 2.29846 9.85897 0.47704C10.0115 0.167909 10.2357 0 10.4981 0Z"
                                      fill="#FFA800"
                                    />
                                  </svg>
                                </li>
                              </ul>
                              <p>
                                Lorem Ipsum is simply dummy text for printing.
                              </p>
                            </div>
                          </div>
                          <div className="review-time">
                            <p>12 mints ago</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton className="border-none">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="add-review-modal">
            <div className="d-flex justify-content-center mb-3">
              <Rating />
            </div>
            <Form>
              <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Add Comment"
                />
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none common-modal-thene-btn mb-3">
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button variant="primary" onClick={handleClose}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
