import React, { useState } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css"; // Import default styles

const RangeComponent = ({
  ageRange,
  setAgeRange,
  distanceRange,
  setDistanceRange,
}) => {
  // Initialize state with default ranges
  console.log(ageRange,"age")

  

  return (
    <div>
      <div className="mb-5 input-bg-re" controlId="ageRangeSlider">
        <label className="mb-2">Age Range</label>
        <RangeSlider
          min={0}
          max={80}
          step={1}
          value={ageRange}
          onInput={setAgeRange} // Directly pass the setter function
        />
        <div className="mt-2">
          Selected Age Range: {ageRange[0] ? ageRange[0] :0 } - {ageRange[1] ? ageRange[1] : 80}
        </div>
      </div>

      <div className="mb-5 input-bg-re" controlId="distanceRangeSlider">
        <label className="mb-2">Distance Range</label>
        <RangeSlider
          min={0}
          max={100}
          step={1}
          value={distanceRange}
          onInput={setDistanceRange} // Directly pass the setter function
        />
        <div className="mt-2">
          Selected Distance Range: {distanceRange[0] ? distanceRange[0] : 0} - {distanceRange[1] ? distanceRange[1] : 100} km
        </div>
      </div>
    </div>
  );
};

export default RangeComponent;
