import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import useQueryHook from "../myHooks/queryHook";
import { editprofile, profile } from "../redux/Actions/userActions";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { EditProfileData } from "../redux/Reducers/profileSlice";
import Loader from "../Components/Loader";
import useMutationHook from "../myHooks/mutationHook";

// import { useNavigate } from "react-router-dom";

export default function BasicDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "loc");
  const dispatch = useDispatch();
  let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const data = useSelector((state) => state?.profileData?.editprofiledata);
  console.log(data, "updatedData");

  const [purpose, setPurpose] = useState(null);

  useEffect(() => {
    if (location?.state) {
      setPurpose(location?.state?.type);
    }
  }, [location]);

  // =================================================GET PROFILE DATA========================================

  const { data: profiledata, isPending } = useQueryHook(
    ["getUserById"],
    profile
  );

  console.log(profiledata, "data");

  // ===============================================editProfile api==========================================

  const {
    mutate,
    isPending: editPending,
    isSuccess,
    data: editProfileData,
  } = useMutationHook(editprofile);

  useEffect(() => {
    if (editProfileData?.status === 200) {
      console.log(editProfileData, "datdatadtad");
      // sessionStorage.setItem("token", data?.data?.token);
      // if (!data?.user_detail?.profile_completed) {
      navigate("/user-profile-screen");
      dispatch(EditProfileData(null));
      // }
    }
  }, [isSuccess]);

  // ===============================================validation===============================================

  const validateForm = (values) => {
    const errors = {};

    // Custom validation rules
    const isEmpty = (value) =>
      value === undefined || value === "" || value === null;

    // Custom validation rules
    if (isEmpty(values.maritalStatus)) {
      errors.maritalStatus = "Marital status is required";
    }

    if (isEmpty(values.lifestyle)) {
      errors.lifestyle = "Lifestyle is required";
    }

    if (isEmpty(values.hasChildren)) {
      errors.hasChildren = "Has children is required";
    }

    if (values.hasChildren === "1") {
      if (isEmpty(values.numberOfChildren)) {
        errors.numberOfChildren = "Number of children is required";
      } else if (values.numberOfChildren < 0) {
        errors.numberOfChildren = "Number must be positive";
      }

      if (isEmpty(values.youngestChild)) {
        errors.youngestChild = "Youngest child age is required";
      } else if (values.youngestChild < 0) {
        errors.youngestChild = "Number must be positive";
      }

      if (isEmpty(values.oldestChild)) {
        errors.oldestChild = "Oldest child age is required";
      } else if (values.oldestChild < 0) {
        errors.oldestChild = "Number must be positive";
      }
    }

    if (isEmpty(values.wantMoreKids)) {
      errors.wantMoreKids = "Desire for more kids is required";
    }

    if (isEmpty(values.drink)) {
      errors.drink = "Drinking habits are required";
    }

    if (isEmpty(values.smoke)) {
      errors.smoke = "Smoking habits are required";
    }

    if (isEmpty(values.hasPets)) {
      errors.hasPets = "Pet ownership status is required";
    }

    if (isEmpty(values.willingToMove)) {
      errors.willingToMove = "Willingness to move is required";
    }

    if (isEmpty(values.occupation)) {
      errors.occupation = "Occupation is required";
    }

    if (isEmpty(values.sport)) {
      errors.sport = "Sport is required";
    }

    if (isEmpty(values.height)) {
      errors.height = "Height is required";
    } else if (values.height < 0) {
      errors.height = "Height must be positive";
    }

    if (isEmpty(values.weight)) {
      errors.weight = "Weight is required";
    } else if (values.weight < 0) {
      errors.weight = "Weight must be positive";
    }

    if (isEmpty(values.bodyType)) {
      errors.bodyType = "Body type is required";
    }

    if (isEmpty(values.bodyArt)) {
      errors.bodyArt = "Body art is required";
    }

    if (isEmpty(values.eyeColor)) {
      errors.eyeColor = "Eye color is required";
    }

    if (isEmpty(values.hairColor)) {
      errors.hairColor = "Hair color is required";
    }

    if (isEmpty(values.ethnicity)) {
      errors.ethnicity = "Ethnicity is required";
    }

    if (isEmpty(values.nationality)) {
      errors.nationality = "Nationality is required";
    }

    if (isEmpty(values.education)) {
      errors.education = "Education is required";
    }

    if (isEmpty(values.languageAbility)) {
      errors.languageAbility = "Language ability is required";
    }

    if (isEmpty(values.languageSpoken)) {
      errors.languageSpoken = "Language spoken is required";
    }

    if (isEmpty(values.religion)) {
      errors.religion = "Religion is required";
    }

    if (isEmpty(values.starSign)) {
      errors.starSign = "Star sign is required";
    }

    return errors;
  };

  const initialvalues = {
    maritalStatus:
      profiledata?.data?.marital_status ?? data?.maritalStatus ?? "",
    showOnProfile:
      profiledata?.data?.show_marital_status ?? data?.showOnProfile ?? false,
    lifestyle: profiledata?.data?.looking_for ?? data?.lifestyle ?? "",
    hasChildren:
      profiledata?.data?.children !== undefined
        ? profiledata.data.children
        : data?.hasChildren ?? "",
    numberOfChildren:
      profiledata?.data?.number_of_children !== undefined
        ? profiledata.data.number_of_children
        : data?.numberOfChildren ?? "",
    youngestChild:
      profiledata?.data?.youngest_children !== undefined
        ? profiledata.data.youngest_children
        : data?.youngestChild ?? "",
    oldestChild:
      profiledata?.data?.oldest_children !== undefined
        ? profiledata.data.oldest_children
        : data?.oldestChild ?? "",
    wantMoreKids: profiledata?.data?.more_kids ?? data?.wantMoreKids ?? "",
    drink: profiledata?.data?.drink ?? data?.drink ?? "",
    smoke: profiledata?.data?.smoke ?? data?.smoke ?? "",
    hasPets: profiledata?.data?.pets ?? data?.hasPets ?? "",
    willingToMove:
      profiledata?.data?.willing_to_move ?? data?.willingToMove ?? "",
    occupation: profiledata?.data?.basic_occupation ?? data?.occupation ?? "",
    sport: profiledata?.data?.sport ?? data?.sport ?? "",
    height: profiledata?.data?.height ?? data?.height ?? "",
    weight: profiledata?.data?.weight ?? data?.weight ?? "",
    bodyType: profiledata?.data?.body_type ?? data?.bodyType ?? "",
    bodyArt: profiledata?.data?.body_art ?? data?.bodyArt ?? "",
    eyeColor: profiledata?.data?.eye_color ?? data?.eyeColor ?? "",
    hairColor: profiledata?.data?.hair_color ?? data?.hairColor ?? "",
    ethnicity: profiledata?.data?.ethnicity ?? data?.ethnicity ?? "",
    nationality: profiledata?.data?.nationality ?? data?.nationality ?? "",
    education: profiledata?.data?.education ?? data?.education ?? "",
    languageAbility:
      profiledata?.data?.education_lang_ability ?? data?.languageAbility ?? "",
    languageSpoken:
      profiledata?.data?.language_spoken ?? data?.languageSpoken ?? "",
    religion: profiledata?.data?.religion ?? data?.religion ?? "",
    starSign: profiledata?.data?.star_sign ?? data?.starSign ?? "",
  };
  console.log(initialvalues, "inininini");

  return (
    <Layout>
      {(isPending || editPending) && <Loader />}
      <div className="right-side">
        <div className="right-side-flow">
          <div className="main-content">
            <div className="main-content-contin">
              <div className="common-page-left-part">
                <div className="commn-profile-show">
                  <img
                    style={{ borderRadius: "50%" }}
                    src={`${BASE_URL}public/${profiledata?.data?.image}`}
                    alt="logo"
                  />
                  <h2>
                  {profiledata?.data?.name} {" "} {profiledata?.data?.sur_name}{profiledata?.data?.age ? ", "+ profiledata?.data?.age : ""}
                  </h2>
                  {/* <div className="">
                    Change Profile Picture
                    <input type="file" />
                  </div> */}
                </div>
              </div>
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link
                      to={data?.seekingFor ? "/hobbies-interests":

                        purpose == "edit"
                          ? "/user-profile-screen"
                          : "/profile-preferences"
                      }
                      className="back-right-part"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    {/* <div className="online">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill="#3BAD45" />
                      </svg>
                      Online
                    </div> */}
                  </div>
                  <div className="follow-main-data">
                    <div className="commn-form-feild">
                      <div className="commn-form-title mb-4">
                        <h2>Basic Details</h2>
                        <p>More about you</p>
                      </div>
                      <div className="commn-form-feild">
                        <Formik
                          enableReinitialize={true}
                          initialValues={initialvalues}
                          validate={validateForm}
                          onSubmit={(values) => {
                            if (purpose == "edit") {
                              let formData = new FormData();

                              // formData.append("about", data?.about);
                              formData.append("body_art", values?.bodyArt);
                              formData.append("body_type", values?.bodyType);
                              // formData.append("city", data?.city);
                              // formData.append("country", data?.country);
                              formData.append("drink", values?.drink);
                              formData.append("education", values?.education);
                              formData.append("ethnicity", values?.ethnicity);
                              formData.append("eye_color", values?.eyeColor);
                              formData.append("hair_color", values?.hairColor);
                              formData.append("children", values?.hasChildren);
                              formData.append("pets", values?.hasPets);
                              formData.append("height", values?.height);
                              // formData.append(
                              //   "interest_hobbies",
                              //   data?.hobbyArr
                              // );
                              // formData.append("image", data?.image);
                              // formData.append("images", data?.images);
                              formData.append(
                                "education_lang_ability",
                                values?.languageAbility
                              );
                              formData.append(
                                "language_spoken",
                                values?.languageSpoken
                              );
                              formData.append("looking_for", values?.lifestyle);
                              formData.append(
                                "marital_status",
                                +values?.maritalStatus
                              );

                              // formData.append(
                              //   "preference_receive_message",
                              //   data?.messagePreference
                              // );
                              // formData.append("name", data?.name);
                              formData.append(
                                "nationality",
                                values?.nationality
                              );
                              // formData.append("nick_name", data?.nickName);
                              formData.append(
                                "number_of_children",
                                values?.numberOfChildren
                              );
                              formData.append(
                                "basic_occupation",
                                values?.occupation
                              );
                              formData.append(
                                "oldest_children",
                                values?.oldestChild
                              );
                              // formData.append("post_see", data?.postSee);
                              // if (data?.profilePreference) {
                              //   formData.append(
                              //     "profile_preference",
                              //     data?.profilePreference
                              //   );
                              // }

                              formData.append("religion", values?.religion);
                              formData.append(
                                "show_marital_status",
                                values?.showOnProfile
                              );
                              formData.append("smoke", values?.smoke);
                              formData.append("sport", values?.sport);
                              formData.append("star_sign", values?.starSign);
                              // formData.append("state", data?.state);
                              formData.append(
                                "more_kids",
                                values?.wantMoreKids
                              );
                              formData.append("weight", values?.weight);
                              formData.append(
                                "willing_to_move",
                                values?.willingToMove
                              );
                              formData.append(
                                "youngest_children",
                                values?.youngestChild
                              );

                              mutate(formData);
                            } else {
                              dispatch(EditProfileData({ ...data, ...values }));
                              data?.seekingFor ? navigate("/profile-preferences") :
                              navigate("/account-preference-plans");
                            }
                          }}
                        >
                          {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => {
                            console.log(values, "errors");
                            return (
                              <FormikForm>
                                <Form.Group
                                  className="mb-3"
                                  controlId="maritalStatus"
                                >
                                  <Form.Label>Marital Status</Form.Label>
                                  <Form.Select
                                    name="maritalStatus"
                                    value={values.maritalStatus}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value="">
                                      Open this select menu
                                    </option>
                                    <option value="0">Divorced</option>
                                    <option value="1">Married</option>
                                    <option value="2">Single</option>
                                    <option value="3">Widowed</option>
                                    <option value="5">Prefer not to say</option>
                                  </Form.Select>
                                  <ErrorMessage
                                    name="maritalStatus"
                                    component="div"
                                    className="text-danger"
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="showOnProfile"
                                >
                                  <Form.Check
                                    type="checkbox"
                                    label="Show marital status on my profile"
                                    name="showOnProfile"
                                    checked={values.showOnProfile == "1"}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "showOnProfile",
                                        e.target.checked == true ? 1 : 0
                                      )
                                    }
                                    onBlur={handleBlur}
                                    value={values.showOnProfile}
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="lifestyle"
                                >
                                  <Form.Label>
                                    What are you looking for?
                                  </Form.Label>
                                  <Form.Select
                                    name="lifestyle"
                                    value={values.lifestyle}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value="">
                                      Open this select menu
                                    </option>
                                    <option value="0">Divorced</option>
                                    <option value="1">Friendship</option>
                                    <option value="2">
                                      Fun with another Partner
                                    </option>
                                    <option value="3">Married</option>
                                    <option value="4">
                                      Serious Relationship
                                    </option>
                                    <option value="5">Single</option>
                                    <option value="6">Widowed</option>
                                  </Form.Select>
                                  <ErrorMessage
                                    name="lifestyle"
                                    component="div"
                                    className="text-danger"
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="hasChildren"
                                >
                                  <Form.Label>Do you have children?</Form.Label>
                                  <Form.Select
                                    name="hasChildren"
                                    value={values.hasChildren}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value="">
                                      Open this select menu
                                    </option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                  </Form.Select>
                                  <ErrorMessage
                                    name="hasChildren"
                                    component="div"
                                    className="text-danger"
                                  />
                                </Form.Group>

                                <Row>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="numberOfChildren"
                                    >
                                      <Form.Label>
                                        Number of children
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="numberOfChildren"
                                        value={values.numberOfChildren}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter here..."
                                      />
                                      <ErrorMessage
                                        name="numberOfChildren"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="youngestChild"
                                    >
                                      <Form.Label>Youngest child</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="youngestChild"
                                        value={values.youngestChild}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter here..."
                                      />
                                      <ErrorMessage
                                        name="youngestChild"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="oldestChild"
                                    >
                                      <Form.Label>Oldest child</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="oldestChild"
                                        value={values.oldestChild}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter here..."
                                      />
                                      <ErrorMessage
                                        name="oldestChild"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="wantMoreKids"
                                    >
                                      <Form.Label>
                                        Do you want more kids?
                                      </Form.Label>
                                      <Form.Select
                                        name="wantMoreKids"
                                        value={values.wantMoreKids}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                      </Form.Select>
                                      <ErrorMessage
                                        name="wantMoreKids"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="drink"
                                    >
                                      <Form.Label>Do you drink?</Form.Label>
                                      <Form.Select
                                        name="drink"
                                        value={values.drink}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                        <option value="2">
                                          Prefer not to say
                                        </option>
                                        <option value="3">Not selected</option>
                                      </Form.Select>
                                      <ErrorMessage
                                        name="drink"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="smoke"
                                    >
                                      <Form.Label>Do you smoke?</Form.Label>
                                      <Form.Select
                                        name="smoke"
                                        value={values.smoke}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                        <option value="2">
                                          Prefer not to say
                                        </option>
                                      </Form.Select>
                                      <ErrorMessage
                                        name="smoke"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Form.Group
                                  className="mb-3"
                                  controlId="hasPets"
                                >
                                  <Form.Label>Do you have pets?</Form.Label>
                                  <Form.Select
                                    name="hasPets"
                                    value={values.hasPets}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value="">
                                      Open this select menu
                                    </option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                  </Form.Select>
                                  <ErrorMessage
                                    name="hasPets"
                                    component="div"
                                    className="text-danger"
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="willingToMove"
                                >
                                  <Form.Label>
                                    Are you willing to move?
                                  </Form.Label>
                                  <Form.Select
                                    name="willingToMove"
                                    value={values.willingToMove}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value="">
                                      Open this select menu
                                    </option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                  </Form.Select>
                                  <ErrorMessage
                                    name="willingToMove"
                                    component="div"
                                    className="text-danger"
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="occupation"
                                >
                                  <Form.Label>Occupation?</Form.Label>
                                  <Field
                                    as="input"
                                    type="text"
                                    name="occupation"
                                    placeholder="Enter here..."
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="occupation"
                                    component="div"
                                    className="text-danger"
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="sport">
                                  <Form.Label>Sports?</Form.Label>
                                  <Field
                                    as="select"
                                    name="sport"
                                    className="form-control"
                                  >
                                    <option value="">
                                      Open this select menu
                                    </option>
                                    <option value="0">Soccer (Football)</option>
                                    <option value="1">Basketball</option>
                                    <option value="2">Tennis</option>
                                    <option value="3">Golf</option>
                                    <option value="4">Cricket</option>
                                    <option value="5">Rugby</option>
                                    <option value="6">Baseball</option>
                                    <option value="7">Swimming</option>
                                    <option value="8">
                                      Athletics (Track and Field)
                                    </option>
                                    <option value="9">Boxing</option>
                                    <option value="10">Volleyball</option>
                                    <option value="11">Ice Hockey</option>
                                    <option value="12">Surfing</option>
                                    <option value="13">
                                      Skiing (Alpine, Cross-Country)
                                    </option>
                                    <option value="14">Gymnastics</option>
                                  </Field>

                                  <ErrorMessage
                                    name="sport"
                                    component="div"
                                    className="text-danger"
                                  />
                                </Form.Group>

                                <Row>
                                  <p className="basic-details-titles">
                                    Your appearance
                                  </p>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="height"
                                    >
                                      <Form.Label>Height (ft)</Form.Label>
                                      <Field
                                        as="input"
                                        type="text"
                                        name="height"
                                        placeholder="Enter here..."
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name="height"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="weight"
                                    >
                                      <Form.Label>Weight (Kgs)</Form.Label>
                                      <Field
                                        as="input"
                                        type="text"
                                        name="weight"
                                        placeholder="Enter here..."
                                        className="form-control"
                                      />
                                      <ErrorMessage
                                        name="weight"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="bodyType"
                                    >
                                      <Form.Label>Body Type</Form.Label>
                                      <Field
                                        as="select"
                                        name="bodyType"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Athletic</option>
                                        <option value="1">Average</option>
                                        <option value="2">Bodybuilder</option>
                                        <option value="3">Curvy</option>
                                        <option value="4">Full-figured</option>
                                        <option value="5">Muscular</option>
                                        <option value="6">Petite</option>
                                        <option value="7">Slim</option>
                                        <option value="8">Stocky</option>
                                        <option value="9">Thin</option>
                                        <option value="10">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                      <ErrorMessage
                                        name="bodyType"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="bodyArt"
                                    >
                                      <Form.Label>Body Art</Form.Label>
                                      <Field
                                        as="select"
                                        name="bodyArt"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Body jewelry</option>
                                        <option value="1">
                                          Body modifications
                                        </option>
                                        <option value="2">Body painting</option>
                                        <option value="3">Branding</option>
                                        <option value="4">Henna</option>
                                        <option value="5">Piercings</option>
                                        <option value="6">Scarification</option>
                                        <option value="7">Tattoos</option>
                                        <option value="8">
                                          Temporary tattoos
                                        </option>
                                        <option value="9">
                                          UV ink tattoos
                                        </option>
                                        <option value="10">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                      <ErrorMessage
                                        name="bodyArt"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="eyeColor"
                                    >
                                      <Form.Label>Eye Color</Form.Label>
                                      <Field
                                        as="select"
                                        name="eyeColor"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Amber</option>
                                        <option value="1">Black</option>
                                        <option value="2">Blue</option>
                                        <option value="3">Brown</option>
                                        <option value="4">Gray</option>
                                        <option value="5">Green</option>
                                        <option value="6">Hazel</option>
                                        <option value="7">Henna</option>
                                        <option value="8">Other</option>
                                        <option value="9">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                      <ErrorMessage
                                        name="eyeColor"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="hairColor"
                                    >
                                      <Form.Label>Hair Color</Form.Label>
                                      <Field
                                        as="select"
                                        name="hairColor"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Auburn</option>
                                        <option value="1">Black</option>
                                        <option value="2">Blonde</option>
                                        <option value="3">Brown</option>
                                        <option value="4">Chestnut</option>
                                        <option value="5">Gray/Silver</option>
                                        <option value="6">
                                          Platinum blonde
                                        </option>
                                        <option value="7">Red</option>
                                        <option value="8">
                                          Strawberry blonde
                                        </option>
                                        <option value="9">White</option>
                                      </Field>
                                      <ErrorMessage
                                        name="hairColor"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={12}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="ethnicity"
                                    >
                                      <Form.Label>Ethnicity</Form.Label>
                                      <Field
                                        as="select"
                                        name="ethnicity"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">
                                          African American
                                        </option>
                                        <option value="1">Asian</option>
                                        <option value="2">Caucasian</option>
                                        <option value="3">
                                          Hispanic/Latino
                                        </option>
                                        <option value="4">
                                          Middle Eastern
                                        </option>
                                        <option value="5">Mixed</option>
                                        <option value="6">
                                          Native American/Indigenous
                                        </option>
                                        <option value="7">Other</option>
                                        <option value="8">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                      <ErrorMessage
                                        name="ethnicity"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <p className="basic-details-titles">
                                    Your background/Cultural value
                                  </p>
                                  <Col lg={12}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="nationality"
                                    >
                                      <Form.Label>Nationality</Form.Label>
                                      <Field
                                        as="select"
                                        name="nationality"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">American</option>
                                        <option value="1">Australian</option>
                                        <option value="2">Belgian</option>
                                        <option value="3">Brazilian</option>
                                        <option value="4">British</option>
                                        <option value="5">Canadian</option>
                                        <option value="6">Chinese</option>
                                        <option value="7">Dutch</option>
                                        <option value="8">French</option>
                                        <option value="9">German</option>
                                        <option value="10">Indian</option>
                                        <option value="11">Italian</option>
                                        <option value="12">Japanese</option>
                                        <option value="13">Korean</option>
                                        <option value="14">Mexican</option>
                                        <option value="15">Norwegian</option>
                                        <option value="16">Russian</option>
                                        <option value="17">Spanish</option>
                                        <option value="18">Swedish</option>
                                        <option value="19">Swiss</option>
                                        <option value="20">
                                          Prefer not to say
                                        </option>
                                      </Field>

                                      <ErrorMessage
                                        name="nationality"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={12}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="education"
                                    >
                                      <Form.Label>Education</Form.Label>
                                      <Field
                                        as="select"
                                        name="education"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">
                                          Associate's degree
                                        </option>
                                        <option value="1">
                                          Bachelor's degree
                                        </option>
                                        <option value="2">
                                          Doctorate degree
                                        </option>
                                        <option value="3">
                                          High school diploma
                                        </option>
                                        <option value="4">
                                          High school/GED
                                        </option>
                                        <option value="5">
                                          Master's degree
                                        </option>
                                        <option value="6">
                                          Professional certification
                                        </option>
                                        <option value="7">
                                          Some college, no degree
                                        </option>
                                        <option value="8">
                                          Vocational training
                                        </option>
                                        <option value="9">Other</option>
                                        <option value="10">
                                          Prefer not to say
                                        </option>
                                      </Field>
                                      <ErrorMessage
                                        name="education"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="languageAbility"
                                    >
                                      <Form.Label>Language Ability</Form.Label>
                                      <Field
                                        as="select"
                                        name="languageAbility"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Basic</option>
                                        <option value="1">Bilingual</option>
                                        <option value="2">Fluent</option>
                                        <option value="3">Intermediate</option>
                                        <option value="4">Limited</option>
                                        <option value="5">Multilingual</option>
                                        <option value="6">
                                          Native Speaker
                                        </option>
                                        <option value="7">None</option>
                                        <option value="8">Other</option>
                                        <option value="9">Proficient</option>
                                        <option value="10">
                                          Prefer not to say
                                        </option>
                                      </Field>

                                      <ErrorMessage
                                        name="languageAbility"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="languageSpoken"
                                    >
                                      <Form.Label>Language Spoken</Form.Label>
                                      <Field
                                        as="select"
                                        name="languageSpoken"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Arabic</option>
                                        <option value="1">Chinese</option>
                                        <option value="2">English</option>
                                        <option value="3">French</option>
                                        <option value="4">German</option>
                                        <option value="5">Hindi</option>

                                        <option value="6">Japanese</option>

                                        <option value="7">Portuguese</option>
                                        <option value="8">Russian</option>
                                        <option value="9">Spanish</option>
                                      </Field>
                                      <ErrorMessage
                                        name="languageSpoken"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={12}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="religion"
                                    >
                                      <Form.Label>Religion</Form.Label>
                                      <Field
                                        as="select"
                                        name="religion"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Agnosticism</option>
                                        <option value="1">Atheism</option>
                                        <option value="2">Bahá'í Faith</option>
                                        <option value="3">Buddhism</option>
                                        <option value="4">Christianity</option>
                                        <option value="5">Hinduism</option>
                                        <option value="6">Islam</option>
                                        <option value="7">Jainism</option>
                                        <option value="8">Judaism</option>
                                        <option value="9">No religion</option>
                                        <option value="10">
                                          Other (Specify)
                                        </option>
                                        <option value="11">Shintoism</option>
                                        <option value="12">Sikhism</option>
                                        <option value="13">
                                          Spiritual but not religious
                                        </option>
                                        <option value="14">Taoism</option>
                                        <option value="15">
                                          Prefer not to say
                                        </option>
                                      </Field>

                                      <ErrorMessage
                                        name="religion"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={12}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="starSign"
                                    >
                                      <Form.Label>Star Sign</Form.Label>
                                      <Field
                                        as="select"
                                        name="starSign"
                                        className="form-control"
                                      >
                                        <option value="">
                                          Open this select menu
                                        </option>
                                        <option value="0">Aquarius</option>
                                        <option value="1">Aries</option>
                                        <option value="2">Cancer</option>
                                        <option value="3">Capricorn</option>
                                        <option value="4">Gemini</option>
                                        <option value="5">Leo</option>
                                        <option value="6">Libra</option>
                                        <option value="7">Pisces</option>
                                        <option value="8">Sagittarius</option>
                                        <option value="9">Scorpio</option>
                                        <option value="10">Taurus</option>
                                        <option value="11">Virgo</option>
                                        <option value="12">
                                          Prefer not to say
                                        </option>
                                      </Field>

                                      <ErrorMessage
                                        name="starSign"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <div className="common-form-btn">
                                  <Button
                                    type="submit"
                                    // onClick={() => {
                                    //   // navigate("/account-preferences");
                                    // }}
                                  >
                                    {purpose == "edit" ? "Submit" : "Continue"}
                                  </Button>
                                </div>
                              </FormikForm>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
