import { Button, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import { ProfileData } from "../redux/Reducers/profileSlice";
import useMutationHook from "../myHooks/mutationHook";
import { editprofile } from "../redux/Actions/userActions";
import Loader from "../Components/Loader";

export default function CompleteYourProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.profileData?.profiledata);
  console.log(data, "prevData");

  // =============================================react state===================================================
  const [file, setFile] = useState();

  // ============================================initialvalues===================================================

  const initialvalues = {
    parentimage: data?.parentimage || null,
    parentfirstName: data?.parentfirstName || "",
    parentlastName: data?.parentlastName || "",
    parentemail: data?.parentemail || "",
  };

  // ==========================================validationSchema====================================================

  const validationSchema = yup.object().shape({
    parentfirstName: yup.string().required("First name is required"),
    parentlastName: yup.string().required("Last name is required"),
    parentemail: yup
      .string()
      .email("Invalid email")
      .required("Email is required"),
    parentimage: yup.mixed().required("Image is required"),
  });


  // ===============================================editProfile api==========================================

  const { mutate, isPending, isSuccess, data:editProfileData } = useMutationHook(editprofile);

  useEffect(() => {
    if (editProfileData?.status === 200) {
      console.log(editProfileData, "datdatadtad");
      // sessionStorage.setItem("token", data?.data?.token);
      if (!data?.user_detail?.profile_completed) {
        // navigate("/dashboard");
        dispatch(ProfileData(null))
      } 
    }
  }, [isSuccess]);



  return (
    <>
    {isPending && <Loader />}
      <section className="Common-sign-flow">
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="text-center mb-3">
                <img
                  className="Common-sign-logo"
                  src={require("../Assets/images/logo.svg").default}
                  alt="logo"
                />
              </div>
            </Col>
            <Formik
              initialValues={initialvalues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log(values)
                // dispatch(ProfileData({...data,values}));
                let formData = new FormData()
                formData.append("profile_completed",true)
                formData.append("sur_name",data?.lastName)
                formData.append("nick_name",data?.nickName)
                formData.append("image",data?.image)

                data?.images?.forEach((x)=> (
                  formData.append(`images`,x?.image[0]
                )))
                data?.images?.forEach((x)=> (
                  console.log(`images`,x?.image[0])))

                formData.append("name",data?.firstName)
                formData.append("country",data?.country)
                formData.append("state",data?.state)
                formData.append("city",data?.city)
                formData.append("gender",data?.gender)
                formData.append("show_gender",data?.showGender)
                formData.append("looking_for_soulmate",data?.lookingForSoulmate)
                formData.append("about",data?.about)
                formData.append("profile_preference",data?.profilePreference)
                formData.append("parent_name",values?.parentfirstName)
                formData.append("parent_surname",values?.parentlastName)
                formData.append("parent_image",values?.parentimage)
                formData.append("parent_email",values?.parentemail)
               
                mutate(formData)


                // navigate("/select-gender")
                console.log(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                setFieldValue,
              }) => {
                return (
                  <FormikForm>
                    <Col lg={12}>
                      <div className="Common-sign-boxout">
                        <Row>
                          <Col lg={5}>
                            <div className="profile-view-upld">
                              <h2>Profile Details</h2>
                              <p>
                                Dear {data?.firstName} , If you register as a
                                minor we require your parental control. Please
                                enter the name and surname and email address of
                                one of your parents.
                              </p>
                              <ErrorMessage
                                name="parentimage"
                                component="div"
                                className="text-danger"
                              />
                              <div className="user-profile-update">
                                <img
                                  src={require("../Assets/images/user-profile-img.png")}
                                  alt="logo"
                                />
                                <img className="view-img" src={file} />
                              </div>
                              <button className="upload-btn">
                                <input
                                  type="file"
                                  onChange={(e) => {
                                    setFieldValue("parentimage", e?.target?.files[0]);
                                    setFile(
                                      URL.createObjectURL(e?.target?.files[0])
                                    );
                                  }}
                                  // onChange={handleChange}
                                />
                                Add Profile Picture
                              </button>
                            </div>
                          </Col>
                          <Col lg={7}>
                            <div className="detail-view-inputs">
                              <Row>
                                <Col lg={12}>
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label>First Name</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Type here.."
                                        name="parentfirstName"
                                        onChange={handleChange}
                                        value={values?.parentfirstName}
                                      />
                                       <ErrorMessage
                                        name="parentfirstName"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg={12}>
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label>Last Name</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Type here.."
                                        name="parentlastName"
                                        onChange={handleChange}
                                        value={values?.parentlastName}
                                      />
                                       <ErrorMessage
                                        name="parentlastName"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg={12}>
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label>Email</Form.Label>
                                      <Form.Control
                                        type="email"
                                        placeholder="Type here.."
                                        name="parentemail"
                                        onChange={handleChange}
                                        value={values?.parentemail}

                                      />
                                       <ErrorMessage
                                        name="parentemail"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg={12}>
                                  <div className="Common-sign-button">
                                    {/* <Link to="" className="back-btn">Back</Link> */}
                                    <Button style={{width:"322px"}}
                                      // to="/select-gender"
                                      className="continue-btn"
                                       type="submit"
                                    >
                                      Complete Your Profile
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </FormikForm>
                );
              }}
            </Formik>
          </Row>
        </Container>
      </section>
    </>
  );
}
