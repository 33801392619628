import { Button, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { EditProfileData, ProfileData } from "../redux/Reducers/profileSlice";
import useQueryHook from "../myHooks/queryHook";
import { editprofile, profile } from "../redux/Actions/userActions";
import useMutationHook from "../myHooks/mutationHook";
import Loader from "../Components/Loader";

export default function CompanyDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(
    (companyState) => companyState?.profileData?.profiledata
  );
  console.log(data, "prevData");

  const updatedData = useSelector(
    (state) => state?.profileData?.editprofiledata
  );
  console.log(updatedData, "updatedData");

  // =====================================================react state============================================

  const [identityData, setIdentityData] = useState(null);

  // =================================================GET PROFILE DATA========================================

  const { data: profiledata } = useQueryHook(["getUserById"], profile);

  console.log(profiledata, "data");

  useEffect(() => {
    if (profiledata) {
      profiledata?.data?.identity_data?.map((identityData) => {
        if (identityData?.identity === profiledata?.data?.identity_number) {
          setIdentityData(identityData);
        }
      });
    }
  }, [profiledata]);

  console.log(identityData, "identityu");

  // ===============================================editProfile api==========================================

  const {
    mutate,
    isPending,
    isSuccess,
    data: editProfileData,
  } = useMutationHook(editprofile);

  useEffect(() => {
    if (editProfileData?.status === 200) {
      console.log(editProfileData, "datdatadtad");
      // sessionStorage.setItem("token", data?.data?.token);
      // if (!data?.user_detail?.profile_completed) {
      navigate("/dashboard");
      dispatch(EditProfileData(null));
      // }
    }
  }, [isSuccess]);

  // Define validation schema using Yup
  const validationSchema = Yup.object({
    companyName: Yup.string().required("Company Name is required"),
    companyAddress: Yup.string().required("Company Address is required"),
    companyState: Yup.string().required("State is required"),
    companyCity: Yup.string().required("City is required"),
    number: Yup.number()
      .required("Number is required")
      .typeError("Please enter number"),
    industry: Yup.string().required("Industry is required"),
    website: Yup.string().required("Website is required"),
    companyType: Yup.string().required("Type of Company is required"),
  });

  const initialValues = {
    companyName:
      updatedData?.companyName ||
      identityData?.company_name ||
      data?.companyName ||
      "",
    companyAddress:
      updatedData?.companyAddress ||
      identityData?.address ||
      data?.companyAddress ||
      "",
    companyState:
      updatedData?.companyState ||
      identityData?.state ||
      data?.companyState ||
      "",
    companyCity:
      updatedData?.companyCity || identityData?.city || data?.companyCity || "",
    number: updatedData?.number || identityData?.number || data?.number || "",
    industry:
      updatedData?.industry || identityData?.industry || data?.industry || "",
    website:
      updatedData?.website || identityData?.website || data?.website || "",
    companyType:
      updatedData?.companyType ||
      identityData?.company_type ||
      data?.companyType ||
      "",
  };

  console.log(initialValues, "initial");

  return (
    <>
      {isPending && <Loader />}
      <section className="Common-sign-flow">
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="text-center mb-3">
                <img
                  className="Common-sign-logo"
                  src={require("../Assets/images/logo.svg").default}
                  alt="logo"
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="page-back-icon">
                <Link to="/identify-yourself">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                      fill="black"
                    />
                  </svg>
                  Back
                </Link>
              </div>
              <div className="Common-sign-boxout">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    if (data) {
                      dispatch(
                        ProfileData({
                          ...data,
                          companyName: values?.companyName,
                          companyAddress: values?.companyAddress,
                          companyState: values?.companyState,
                          companyCity: values?.companyCity,
                          number: values?.number,
                          industry: values?.industry,
                          website: values?.website,
                          companyType: values.companyType,
                        })
                      );
                      navigate("/allow-location");
                    }

                    if (updatedData) {
                      let formData = new FormData();
                      // formData.append("without_advertise",withoutAds)
                    

                      // Append fields from updatedData if they are present
                      if (updatedData?.about)
                        formData.append("about", updatedData.about);
                      if (updatedData?.bodyArt)
                        formData.append("body_art", updatedData.bodyArt);
                      if (updatedData?.bodyType)
                        formData.append("body_type", updatedData.bodyType);
                      if (updatedData?.city)
                        formData.append("city", updatedData.city);
                      if (updatedData?.country)
                        formData.append("country", updatedData.country);
                      if (updatedData?.drink)
                        formData.append("drink", updatedData.drink);
                      if (updatedData?.education)
                        formData.append("education", updatedData.education);
                      if (updatedData?.ethnicity)
                        formData.append("ethnicity", updatedData.ethnicity);
                      if (updatedData?.eyeColor)
                        formData.append("eye_color", updatedData.eyeColor);
                      if (updatedData?.hairColor)
                        formData.append("hair_color", updatedData.hairColor);
                      if (updatedData?.hasChildren !== undefined)
                        formData.append("children", updatedData.hasChildren);
                      if (updatedData?.hasPets !== undefined)
                        formData.append("pets", updatedData.hasPets);
                      if (updatedData?.height)
                        formData.append("height", updatedData.height);
                      if (updatedData?.hobbyArr)
                        formData.append(
                          "interest_hobbies",
                          updatedData.hobbyArr
                        );
                      if (updatedData?.image)
                        formData.append("image", updatedData.image);
                      if (updatedData?.images)
                                updatedData?.images?.forEach((x) =>
                                  formData.append(`images`, x)
                                );
                      if (updatedData?.languageAbility)
                        formData.append(
                          "education_lang_ability",
                          updatedData.languageAbility
                        );
                      if (updatedData?.languageSpoken)
                        formData.append(
                          "language_spoken",
                          updatedData.languageSpoken
                        );
                      if (updatedData?.lifestyle)
                        formData.append("looking_for", updatedData.lifestyle);
                      if (updatedData?.maritalStatus !== undefined)
                        formData.append(
                          "marital_status",
                          +updatedData.maritalStatus
                        );
                      if (updatedData?.messagePreference)
                        formData.append(
                          "preference_receive_message",
                          updatedData.messagePreference
                        );
                      if (updatedData?.name)
                        formData.append("name", updatedData.name);
                      if (updatedData?.nationality)
                        formData.append("nationality", updatedData.nationality);
                      if (updatedData?.nickName)
                        formData.append("nick_name", updatedData.nickName);
                      if (updatedData?.numberOfChildren !== undefined)
                        formData.append(
                          "number_of_children",
                          updatedData.numberOfChildren
                        );
                      if (updatedData?.occupation)
                        formData.append(
                          "basic_occupation",
                          updatedData.occupation
                        );
                      if (updatedData?.oldestChild)
                        formData.append(
                          "oldest_children",
                          updatedData.oldestChild
                        );
                      if (updatedData?.postSee)
                        formData.append("post_see", updatedData.postSee);
                      if (updatedData?.profilePreference)
                        formData.append(
                          "profile_preference",
                          updatedData.profilePreference
                        );
                      if (updatedData?.religion)
                        formData.append("religion", updatedData.religion);
                      if (updatedData?.showOnProfile !== undefined)
                        formData.append(
                          "show_marital_status",
                          updatedData.showOnProfile
                        );
                      if (updatedData?.smoke)
                        formData.append("smoke", updatedData.smoke);
                      if (updatedData?.sport)
                        formData.append("sport", updatedData.sport);
                      if (updatedData?.starSign)
                        formData.append("star_sign", updatedData.starSign);
                      if (updatedData?.state)
                        formData.append("state", updatedData.state);
                      if (updatedData?.wantMoreKids !== undefined)
                        formData.append("more_kids", updatedData.wantMoreKids);
                      if (updatedData?.weight)
                        formData.append("weight", updatedData.weight);
                      if (updatedData?.willingToMove)
                        formData.append(
                          "willing_to_move",
                          updatedData.willingToMove
                        );
                      if (updatedData?.youngestChild)
                        formData.append(
                          "youngest_children",
                          updatedData.youngestChild
                        );
                      if (updatedData?.identityNumber)
                        formData.append(
                          "identity_number",
                          updatedData.identityNumber
                        );

                      // Append company fields if they are present
                      if (values?.companyName)
                        formData.append("company_name", values.companyName);
                      if (values?.companyCity)
                        formData.append("company_city", values.companyCity);
                      if (values?.companyState)
                        formData.append("company_state", values.companyState);
                      if (values?.companyType)
                        formData.append("company_type", values.companyType);
                      if (values?.industry)
                        formData.append("industry", values.industry);
                      if (values?.website)
                        formData.append("website", values.website);
                      if (values?.number)
                        formData.append("company_number", values.number);
                      if (values?.companyAddress)
                        formData.append(
                          "company_address",
                          values.companyAddress
                        );

                      // dispatch(
                      //   EditProfileData({
                      //     ...updatedData,
                      //     companyName: values?.companyName,
                      //     companyAddress: values?.companyAddress,
                      //     companyState: values?.companyState,
                      //     companyCity: values?.companyCity,
                      //     number: values?.number,
                      //     industry: values?.industry,
                      //     website: values?.website,
                      //     companyType: values.companyType,
                      //   })
                      // );
                      mutate(formData);
                    }
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur }) => (
                    <FormikForm>
                      <Row>
                        <Col lg={12}>
                          <div className="select-gender-box">
                            <div className="select-gender-head">
                              <h2>Company</h2>
                              <p>Fill company details</p>
                            </div>
                            <div className="profile-preference-optn detail-view-inputs">
                              <Row>
                                <Col lg={8} className="mx-auto">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="companyName"
                                      className="form-label"
                                    >
                                      Company Name
                                    </label>
                                    <input
                                      type="text"
                                      id="companyName"
                                      name="companyName"
                                      value={values.companyName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={`form-control ${
                                        errors.companyName &&
                                        touched.companyName
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Type here.."
                                    />
                                    {errors.companyName &&
                                      touched.companyName && (
                                        <div className="invalid-feedback">
                                          {errors.companyName}
                                        </div>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={8} className="mx-auto">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="companyAddress"
                                      className="form-label"
                                    >
                                      Company’s Address
                                    </label>
                                    <input
                                      type="text"
                                      id="companyAddress"
                                      name="companyAddress"
                                      value={values.companyAddress}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={`form-control ${
                                        errors.companyAddress &&
                                        touched.companyAddress
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Type here.."
                                    />
                                    {errors.companyAddress &&
                                      touched.companyAddress && (
                                        <div className="invalid-feedback">
                                          {errors.companyAddress}
                                        </div>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={8} className="mx-auto">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="companyState"
                                      className="form-label"
                                    >
                                      State
                                    </label>
                                    <input
                                      type="text"
                                      id="companyState"
                                      name="companyState"
                                      value={values.companyState}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={`form-control ${
                                        errors.companyState &&
                                        touched.companyState
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Type here.."
                                    />
                                    {errors.companyState &&
                                      touched.companyState && (
                                        <div className="invalid-feedback">
                                          {errors.companyState}
                                        </div>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={8} className="mx-auto">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="companyCity"
                                      className="form-label"
                                    >
                                      City
                                    </label>
                                    <input
                                      type="text"
                                      id="companyCity"
                                      name="companyCity"
                                      value={values.companyCity}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={`form-control ${
                                        errors.companyCity &&
                                        touched.companyCity
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Type here.."
                                    />
                                    {errors.companyCity &&
                                      touched.companyCity && (
                                        <div className="invalid-feedback">
                                          {errors.companyCity}
                                        </div>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={8} className="mx-auto">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="number"
                                      className="form-label"
                                    >
                                      Number
                                    </label>
                                    <input
                                      type="text"
                                      id="number"
                                      name="number"
                                      value={values.number}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={`form-control ${
                                        errors.number && touched.number
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Type here.."
                                    />
                                    {errors.number && touched.number && (
                                      <div className="invalid-feedback">
                                        {errors.number}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                                <Col lg={8} className="mx-auto">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="industry"
                                      className="form-label"
                                    >
                                      Industry
                                    </label>
                                    <input
                                      type="text"
                                      id="industry"
                                      name="industry"
                                      value={values.industry}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={`form-control ${
                                        errors.industry && touched.industry
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Type here.."
                                    />
                                    {errors.industry && touched.industry && (
                                      <div className="invalid-feedback">
                                        {errors.industry}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                                <Col lg={8} className="mx-auto">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="website"
                                      className="form-label"
                                    >
                                      Website
                                    </label>
                                    <input
                                      type="text"
                                      id="website"
                                      name="website"
                                      value={values.website}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={`form-control ${
                                        errors.website && touched.website
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Type here.."
                                    />
                                    {errors.website && touched.website && (
                                      <div className="invalid-feedback">
                                        {errors.website}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                                <Col lg={8} className="mx-auto">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="companyType"
                                      className="form-label"
                                    >
                                      Type of Company
                                    </label>
                                    <input
                                      type="text"
                                      id="companyType"
                                      name="companyType"
                                      value={values.companyType}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className={`form-control ${
                                        errors.companyType &&
                                        touched.companyType
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Type here.."
                                    />
                                    {errors.companyType &&
                                      touched.companyType && (
                                        <div className="invalid-feedback">
                                          {errors.companyType}
                                        </div>
                                      )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="Common-sign-button">
                            <Button type="submit" className="continue-btn">
                              {data ? "Continue" : "Save"}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </FormikForm>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
