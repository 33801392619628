import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const createPostSlice = createSlice({
  name: "filterSlice",
  initialState: {
    createPostState: null,
  },
  reducers: {
    createPostData: (state, action) => {
       
      state.createPostState = action.payload;
    },
  },
});

export const { createPostData } = createPostSlice.actions;

export default createPostSlice;
