import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

export default function Likes() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/seeking-for"); // Replace '/target-page' with your target route
  };
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Layout>
      <div className="right-side">
        <div className="commn-right-top justify-content-center">
          <div className="post-top-tab">
            <ul>
              <li>
                <Link className="active">Liked Me</Link>
              </li>
              <li>
                <Link>My Matches</Link>
              </li>
              <li>
                <Link>MY Likes</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="right-side-flow">
          <div className="main-content">
            <div className="full-view-content">
              <div className="likes-list-contain">
                <div className="likes-list-card">
                  <div className="likes-list-pro">
                    <img src={require("../Assets/images/story-image.png")} />
                    <div className="">
                      <h5>Devid,23</h5>
                      <p>Chandigarh,India</p>
                    </div>
                  </div>
                </div>
                <div className="likes-list-card">
                  <div className="likes-list-pro">
                    <img src={require("../Assets/images/story-image.png")} />
                    <div className="">
                      <h5>Devid,23</h5>
                      <p>Chandigarh,India</p>
                    </div>
                  </div>
                </div>{" "}
                <div className="likes-list-card">
                  <div className="likes-list-pro">
                    <img src={require("../Assets/images/story-image.png")} />
                    <div className="">
                      <h5>Devid,23</h5>
                      <p>Chandigarh,India</p>
                    </div>
                  </div>
                </div>{" "}
                <div className="likes-list-card">
                  <div className="likes-list-pro">
                    <img src={require("../Assets/images/story-image.png")} />
                    <div className="">
                      <h5>Devid,23</h5>
                      <p>Chandigarh,India</p>
                    </div>
                  </div>
                </div>{" "}
                <div className="likes-list-card">
                  <div className="likes-list-pro">
                    <img src={require("../Assets/images/story-image.png")} />
                    <div className="">
                      <h5>Devid,23</h5>
                      <p>Chandigarh,India</p>
                    </div>
                  </div>
                </div>{" "}
                <div className="likes-list-card">
                  <div className="likes-list-pro">
                    <img src={require("../Assets/images/story-image.png")} />
                    <div className="">
                      <h5>Devid,23</h5>
                      <p>Chandigarh,India</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
