import axios from "axios";

const userApi = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
  headers: {
    token: sessionStorage.getItem("token") ? sessionStorage.getItem("token") :  sessionStorage.getItem("token1") ,
  },
});

userApi.interceptors.request.use(
  (respose) => {
    return respose;
  },
  (error) => {
    return error.respose;
  }
);

export default userApi
