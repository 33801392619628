import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";



const useMutationHook = (mutationFn) => {
 

  const { mutate, isPending, isError, isSuccess,data } = useMutation({
    mutationFn,
    onSuccess: (res) => {
      console.log(res,"res")
       
      if (res?.status===200) {
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error("Something went wrong");
    },
  });

  return { mutate, isPending, isError, isSuccess,data };
};

export default useMutationHook;