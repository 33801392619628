import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import useQueryHook from "../myHooks/queryHook";
import { editprofile, hobbies, profile } from "../redux/Actions/userActions";
import Loader from "../Components/Loader";
import toast from "react-hot-toast";
import { EditProfileData } from "../redux/Reducers/profileSlice";
import useMutationHook from "../myHooks/mutationHook";
import { computeHeadingLevel } from "@testing-library/react";
import ProfilePicture from "../Components/ProfilePicture";

export default function HobbiesInterests() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location, "loc");
  let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const data = useSelector((state) => state?.profileData?.editprofiledata);
  console.log(data, "updatedData");

  const [purpose, setPurpose] = useState(null);

  useEffect(() => {
    if (location?.state) {
      setPurpose(location?.state?.type);
    }
  }, [location]);

  // =================================================GET PROFILE DATA========================================

  const { data: profiledata } = useQueryHook(["getUserById"], profile);

  console.log(profiledata, "data");

  useEffect(() => {
    // profiledata?.data?.interest_hobbies?.map((hobby)=>console.log(hobby?._id))
    if (data || profiledata) {
      setHobbyArr(
        (profiledata?.data?.interest_hobbies?.length > 0 &&
          profiledata?.data?.interest_hobbies?.map((hobby) => hobby?._id)) ||
          data?.hobbyArr ||
          []
      );
    }
  }, [data, profiledata]);

  // =================================================GET hobbies DATA========================================

  const { data: hobbiesdata, isLoading } = useQueryHook(
    ["gethobbies"],
    hobbies
  );

  // ===============================================editProfile api==========================================

  const {
    mutate,
    isPending: editPending,
    isSuccess,
    data: editProfileData,
  } = useMutationHook(editprofile);

  useEffect(() => {
    if (editProfileData?.status === 200) {
      console.log(editProfileData, "datdatadtad");
      // sessionStorage.setItem("token", data?.data?.token);
      // if (!data?.user_detail?.profile_completed) {
      navigate("/user-profile-screen");
      dispatch(EditProfileData(null));
      // }
    }
  }, [isSuccess]);

  // ==================================================react state==============================================

  const [hobbyArr, setHobbyArr] = useState([]);

  const handleHobbyArr = (hobby) => {
    setHobbyArr((prevHobbies) =>
      prevHobbies.includes(hobby)
        ? prevHobbies.filter((h) => h !== hobby)
        : [...prevHobbies, hobby]
    );
  };

  console.log(hobbyArr?.length, "arrr");

  return (
    <Layout>
      {(isLoading || editPending) && <Loader />}
      <div className="right-side">
        <div className="right-side-flow">
          <div className="main-content">
            <div className="main-content-contin">
              <div className="common-page-left-part">
              <ProfilePicture/>
                {/* <div className="commn-profile-show">
                  <img
                    style={{ borderRadius: "50%" }}
                    src={`${BASE_URL}public/${profiledata?.data?.image}`}
                    alt="logo"
                  />
                  <h2>
                  {profiledata?.data?.name} {" "} {profiledata?.data?.sur_name}{profiledata?.data?.age ? ", "+ profiledata?.data?.age : ""}
                  </h2>
                </div> */}
              </div>
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link
                      to={
                        purpose == "edit"
                          ? "/user-profile-screen"
                          : "/complete-profile"
                      }
                      className="back-right-part"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    {/* <div className="online">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill="#3BAD45" />
                      </svg>
                      Online
                    </div> */}
                  </div>
                  <div className="follow-main-data">
                    <div className="commn-form-feild">
                      <div className="commn-form-title mb-4">
                        <h2>Hobbies & Interests</h2>
                        <p>
                          Let other’s know your interest and help us to connect{" "}
                          <br />
                          Select 5-10 options
                        </p>
                      </div>
                      <Row className="mb-3">
                        {hobbiesdata?.data?.length > 0 &&
                          hobbiesdata?.data?.map((hobbyCat, i) => {
                            return (
                              <Col lg={12}>
                                <p
                                  style={{ marginTop: "10px" }}
                                  className="hobbies-interest-title"
                                >
                                  {hobbyCat?._id}
                                </p>
                                <div className="hobbies-interest-tags">
                                  <ul>
                                    {hobbyCat?.titles?.map((hobby) => {
                                      const isSelected = hobbyArr.includes(
                                        hobby?._id
                                      );
                                      return (
                                        <li
                                          onClick={() =>
                                            handleHobbyArr(hobby?._id)
                                          }
                                          key={hobby?._id}
                                          className={isSelected ? "active" : ""}
                                          // style={{
                                          //   cursor: "pointer",
                                          //   background: isSelected ? "active" : "none",
                                          //   border:"2px solid transparent"

                                          // }}
                                        >
                                          {hobby?.name}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </Col>
                            );
                          })}
                      </Row>

                      <div className="common-form-btn">
                        <Button
                          onClick={() => {
                            //clg
                          console.log(hobbyArr?.length < 5 , hobbyArr.length < 10)
                            if (hobbyArr.length < 5 || hobbyArr.length > 10) {
                              toast.error("Hobbies & Interests must be in between 5 to 10");

                            } else {
                              if (purpose == "edit") {
                                let formData = new FormData();
                                formData.append("interest_hobbies", hobbyArr);

                                mutate(formData);
                              } else {
                                dispatch(
                                  EditProfileData({ ...data, hobbyArr })
                                );
                                data?.seekingFor ? navigate("/basic-details") : 
                                navigate("/profile-preferences");
                              }
                            }
                          }}
                        >
                          {purpose == "edit" ? "Submit" : "Continue"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
