import { Button, Container, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ProfileData } from "../redux/Reducers/profileSlice";
import { useNavigate } from "react-router-dom";


export default function ProfileDetails() {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const [file, setFile] = useState();
  const data = useSelector((state)=>state?.profileData?.profiledata)
  console.log(data,"prevData")

  useEffect(()=>{
    if(data?.image?.name){
      setFile(URL?.createObjectURL(data?.image))
    }

  },[data])

  // function handleChange(e) {
  //   console.log(e.target.files);
  //   setFile(URL.createObjectURL(e.target.files[0]));
  // }

  // ============================================initialvalues===================================================

  const initialvalues = {
    image:data?.image || null,
    firstName:data?.firstName || "",
    lastName:data?.lastName || "",
    nickName:data?.nickName || "",
    city:data?.city || "",
    state:data?.state || "",
    country:data?.country || "",
  };

  // ==========================================validationSchema====================================================

  const validationSchema = yup.object().shape({
    firstName: yup
    .string()
    .required("First name is required")
    .matches(/^[A-Za-z]+$/, "First name must only contain letters"),
    lastName: yup
    .string()
    .required("Last name is required")
    .matches(/^[A-Za-z]+$/, "Last name must only contain letters"),
    nickName: yup.string().required("Nick name is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    country: yup.string().required("Country is required"),
    image: yup.mixed().required("Image is required"),
  });

  return (
    <>
      <section className="Common-sign-flow">
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="text-center mb-3">
                <img
                  className="Common-sign-logo"
                  src={require("../Assets/images/logo.svg").default}
                  alt="logo"
                />
              </div>
            </Col>
            <Formik
              initialValues={initialvalues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                dispatch(ProfileData(values));
                navigate("/select-gender")

              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                setFieldValue,
              }) => {
                console.log(values);
                return (
                  <FormikForm>
                    {" "}
                    <Col lg={12}>
                      <div className="Common-sign-boxout">
                        <Row>
                          <Col lg={5}>
                            <div className="profile-view-upld">
                              <h2>Profile Details</h2>
                              {/* <p>Login to pick up exactly where you left off</p> */}
                              <ErrorMessage
                                name="image"
                                component="div"
                                className="text-danger"
                              />
                              <div className="user-profile-update">
                                <img
                                  className="stat-image"
                                  src={require("../Assets/images/user-profile-img1.png")}
                                  alt="userImage"
                                  
                                />
                                {file &&  <img className="view-img" src={file} alt="userImage" /> }

                               
                              </div>

                              <button className="upload-btn">
                                <input
                                  type="file"
                                  onChange={(e) => {
                                    setFieldValue("image", e?.target?.files[0]);
                                    setFile(
                                      URL.createObjectURL(e?.target?.files[0])
                                    );
                                  }}
                                  // onChange={handleChange}
                                />
                                Add Profile Picture
                              </button>
                            </div>
                          </Col>
                          <Col lg={7}>
                            <div className="detail-view-inputs">
                              <Row>
                                <Col lg={6}>
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label>First Name</Form.Label>
                                      <Form.Control
                                        type="email"
                                        placeholder="Type here.."
                                        name="firstName"
                                        onChange={handleChange}
                                        value={values?.firstName}
                                      />
                                      <ErrorMessage
                                        name="firstName"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg={6}>
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label>Last Name</Form.Label>
                                      <Form.Control
                                        type="email"
                                        placeholder="Type here.."
                                        name="lastName"
                                        onChange={handleChange}
                                        value={values?.lastName}
                                      />
                                      <ErrorMessage
                                        name="lastName"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg={6}>
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label>Nick Name</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Type here.."
                                        name="nickName"
                                        onChange={handleChange}
                                        value={values?.nickName}
                                      />

                                      <ErrorMessage
                                        name="nickName"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg={6}>
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label>City</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Type here.."
                                        name="city"
                                        onChange={handleChange}
                                        value={values?.city}
                                      />
                                      <ErrorMessage
                                        name="city"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg={6}>
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label>State</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Type here.."
                                        name="state"
                                        onChange={handleChange}
                                        value={values?.state}
                                      />
                                      <ErrorMessage
                                        name="state"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg={6}>
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label>Country</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Type here.."
                                        name="country"
                                        onChange={handleChange}
                                        value={values?.country}
                                      />
                                      <ErrorMessage
                                        name="country"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg={12}>
                                  <div className="Common-sign-button">
                                    {/* <Link to="" className="back-btn">Back</Link> */}
                                    <Button
                                      type="submit"
                                      // to="/select-gender"
                                      className="continue-btn"
                                    >
                                      Continue
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </FormikForm>
                );
              }}
            </Formik>
          </Row>
        </Container>
      </section>
    </>
  );
}
