import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import Slider from "react-slick";
import Tab from "react-bootstrap/Tab";
// import { useNavigate } from "react-router-dom";

export default function MySubscription() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/filters"); // Replace '/target-page' with your target route
  };
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Layout>
      <div className="right-side">
        <div className="right-side-flow">
          <div className="main-content">
            <div className="full-view-content">
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link to="/settings" className="back-right-part">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    {/* <div className="online">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill="#3BAD45" />
                      </svg>
                      Online
                    </div> */}
                  </div>
                  <div className="follow-main-data">
                    <div className="commn-form-feild">
                      <div className="commn-form-title mb-4">
                        <h2>My Subscription</h2>
                      </div>
                      <Row className="mb-3">
                        <Col lg={12}>
                          <div className="premium-plan">
                            <div className="premium-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="226"
                                height="226"
                                viewBox="0 0 226 226"
                                fill="none"
                              >
                                <circle
                                  cx="113"
                                  cy="113"
                                  r="113"
                                  fill="url(#paint0_linear_2919_28564)"
                                />
                                <circle
                                  cx="112.999"
                                  cy="93.265"
                                  r="39.4603"
                                  fill="white"
                                />
                                <g filter="url(#filter0_d_2919_28564)">
                                  <path
                                    d="M110.367 69.7392C110.635 69.2576 111.02 68.8579 111.482 68.5802C111.945 68.3024 112.469 68.1562 113.003 68.1562C113.536 68.1562 114.06 68.3024 114.523 68.5802C114.985 68.8579 115.37 69.2576 115.639 69.7392L122.461 81.9766L135.71 84.976C136.231 85.0944 136.713 85.3534 137.109 85.7273C137.504 86.1011 137.799 86.5768 137.964 87.1068C138.128 87.6368 138.157 88.2027 138.047 88.7481C137.938 89.2934 137.693 89.7991 137.338 90.2148L128.307 100.777L129.674 114.868C129.727 115.423 129.641 115.983 129.423 116.492C129.205 117.001 128.863 117.442 128.431 117.77C128 118.097 127.493 118.301 126.962 118.36C126.432 118.42 125.895 118.332 125.407 118.107L113.003 112.394L100.598 118.107C100.11 118.332 99.5737 118.42 99.0429 118.36C98.5122 118.301 98.0057 118.097 97.5739 117.77C97.142 117.442 96.8001 117.001 96.5821 116.492C96.3642 115.983 96.2779 115.423 96.3318 114.868L97.6986 100.777L88.6676 90.2174C88.3118 89.8017 88.0665 89.2958 87.9564 88.7501C87.8462 88.2044 87.8749 87.6381 88.0397 87.1077C88.2044 86.5772 88.4995 86.1012 88.8953 85.7272C89.2912 85.3531 89.774 85.0941 90.2956 84.976L103.544 81.9766L110.367 69.7392Z"
                                    fill="url(#paint1_linear_2919_28564)"
                                  />
                                </g>
                                <path
                                  d="M111.643 78.1426C111.819 77.833 112.072 77.5761 112.377 77.3975C112.681 77.219 113.026 77.125 113.377 77.125C113.727 77.125 114.072 77.219 114.376 77.3975C114.681 77.5761 114.934 77.833 115.11 78.1426L119.598 86.0095L128.312 87.9377C128.655 88.0138 128.972 88.1803 129.232 88.4206C129.492 88.661 129.686 88.9668 129.794 89.3075C129.903 89.6482 129.922 90.012 129.849 90.3626C129.777 90.7132 129.616 91.0383 129.383 91.3055L123.443 98.0952L124.342 107.154C124.377 107.511 124.32 107.871 124.177 108.198C124.034 108.525 123.809 108.809 123.525 109.019C123.241 109.23 122.907 109.361 122.558 109.399C122.209 109.437 121.857 109.381 121.535 109.236L113.377 105.564L105.218 109.236C104.897 109.381 104.544 109.437 104.195 109.399C103.846 109.361 103.512 109.23 103.228 109.019C102.944 108.809 102.719 108.525 102.576 108.198C102.433 107.871 102.376 107.511 102.411 107.154L103.31 98.0952L97.3704 91.3072C97.1364 91.0399 96.9751 90.7147 96.9026 90.3639C96.8301 90.0131 96.849 89.6491 96.9574 89.3081C97.0658 88.967 97.2598 88.661 97.5202 88.4206C97.7806 88.1801 98.0982 88.0136 98.4412 87.9377L107.155 86.0095L111.643 78.1426Z"
                                  fill="white"
                                />
                                <path
                                  d="M133.748 137.723C131.633 135.676 128.215 136.777 125.416 137.688L121.768 138.875C116.142 140.707 110.081 140.709 104.453 138.882L100.755 137.681C97.962 136.774 94.634 135.736 92.3997 137.641C91.8222 138.134 91.4766 138.727 91.4766 139.358V170.148C91.4766 170.7 91.7211 171.161 92.1153 171.516C92.3443 171.724 92.65 171.893 93.0061 172.01C93.3622 172.126 93.7581 172.186 94.1595 172.185C94.929 172.185 95.7482 171.97 96.5048 171.564L111.314 163.657C111.772 163.412 112.429 163.271 113.112 163.271C113.795 163.271 114.45 163.412 114.909 163.658L129.669 171.562C130.428 171.97 131.191 172.186 131.959 172.186C133.258 172.186 134.524 171.556 134.524 170.148V139.358C134.524 138.762 134.239 138.199 133.748 137.723Z"
                                  fill="white"
                                />
                                <defs>
                                  <filter
                                    id="filter0_d_2919_28564"
                                    x="19.8906"
                                    y="6.15625"
                                    width="190.219"
                                    height="190.219"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      result="hardAlpha"
                                    />
                                    <feOffset dx="2" dy="8" />
                                    <feGaussianBlur stdDeviation="35" />
                                    <feComposite
                                      in2="hardAlpha"
                                      operator="out"
                                    />
                                    <feColorMatrix
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in2="BackgroundImageFix"
                                      result="effect1_dropShadow_2919_28564"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in="SourceGraphic"
                                      in2="effect1_dropShadow_2919_28564"
                                      result="shape"
                                    />
                                  </filter>
                                  <linearGradient
                                    id="paint0_linear_2919_28564"
                                    x1="0"
                                    y1="0"
                                    x2="231.066"
                                    y2="5.30456"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#2D5FE5" />
                                    <stop offset="0.505" stop-color="#EF117E" />
                                    <stop offset="1" stop-color="#3BAD45" />
                                  </linearGradient>
                                  <linearGradient
                                    id="paint1_linear_2919_28564"
                                    x1="87.8906"
                                    y1="68.1562"
                                    x2="139.239"
                                    y2="69.335"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#6916AE" />
                                    <stop offset="1" stop-color="#E03D84" />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </div>
                            <p className="active-plan">Active Plan</p>
                            <h2>Premium Plan</h2>
                            <p>
                              Your Premium Plan will expire on <br />
                              March 22, 2024, 05.30 PM
                            </p>
                          </div>
                          <div className="upgrade-plan">
                            <div className="">
                              <p>Upgrade Plan</p>
                            </div>
                            <hr />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="plans-feed-box">
                            <Slider {...settings}>
                              <div className="plans-select-box enjoy-color-slide">
                                <h3>Enjoy</h3>
                                <p>Plan for advertising</p>
                                <div className="subscribe-plan-price-box">
                                  <div>
                                    <h2>$10.99</h2>
                                    <div>for 1 month</div>
                                  </div>
                                </div>
                              </div>
                              <div className="plans-select-box essential-color-slide">
                                <h3>Essential</h3>
                                <p>Plan for work</p>
                                <div className="subscribe-plan-price-box">
                                  <div>
                                    <h2>$10.99</h2>
                                    <div>for 1 month</div>
                                  </div>
                                </div>
                              </div>
                              <div className="plans-select-box recommended-color-slide">
                                <h3>Recommended</h3>
                                <p>Plan for work</p>
                                <div className="subscribe-plan-price-box">
                                  <div>
                                    <h2>$10.99</h2>
                                    <div>for 1 month</div>
                                  </div>
                                </div>
                              </div>
                              <div className="plans-select-box cupid-color-slide">
                                <h3>Cupid</h3>
                                <p>Plan for Dating</p>
                                <div className="subscribe-plan-price-box">
                                  <div>
                                    <h2>$10.99</h2>
                                    <div>for 1 month</div>
                                  </div>
                                </div>
                              </div>
                            </Slider>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={12}>
                          <div className="subscribe-plan-desc">
                            <div>What you get</div>
                            <p>
                              The plan allows to be at the top of search
                              profiles and also has 3d scan for profile
                              certification, remove advertising
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <div className="common-form-btn">
                        <Button onClick={handleRedirect}>Continue</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
