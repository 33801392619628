import { createSlice } from "@reduxjs/toolkit";

const RegistrationSlice = createSlice({
    name:"RegistrationSlice",
    initialState:{
        registerdata:null,
       

    },
    reducers:{
        RegisterData:(state,action)=>{
            
            state.registerdata=action.payload
        },
        
    }
})

export const { RegisterData} = RegistrationSlice.actions


export default RegistrationSlice