import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import "react-range-slider-input/dist/style.css";
import Modal from "react-bootstrap/Modal";
// import { useNavigate } from "react-router-dom";

export default function Support() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showtwo, setShowtwo] = useState(false);
  const handleClosetwo = () => setShowtwo(false);
  const handleShowtwo = () => setShowtwo(true);
  return (
    <Layout>
      <div className="right-side">
        <div className="right-side-flow">
          <div className="main-content">
            <div className="full-view-content">
              <div className="common-page-right-part support-chat-bg">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link to="/settings" className="back-right-part">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                  </div>
                  <div className="follow-main-data">
                    <div className="commn-form-feild">
                      <div className="commn-form-title mb-4">
                        <h2>Support </h2>
                      </div>

                      <Row className="justify-content-center ">
                        <Col lg={12}>
                          <div className="support-chat-box-main">
                            <div className="support-inner">
                              {/* <h2>Chat</h2> */}
                              <div className="support-msg-box">
                                <div className="user-box">
                                  <div className="user-msg-box">
                                    <p>Hey I'm John Smith</p>
                                  </div>
                                  <div className="user-top-hdng">
                                    <h4>08:13 pm</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <form>
                              <div class="send-box">
                                <div class="mb-3 input-group chat-msg-bar">
                                  <input
                                    placeholder="Type message.."
                                    aria-label="send"
                                    aria-describedby="basic-addon2"
                                    name="message"
                                    type="text"
                                    class="send-feild form-control"
                                    value=""
                                  />
                                  <button
                                    class="input-group-text"
                                    id="basic-addon2"
                                    type="submit"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="18"
                                      viewBox="0 0 16 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M0.523716 17.2572C0.110234 16.7692 -0.162488 16.0958 0.108768 15.3141C0.544243 14.0606 1.01344 12.8205 1.47531 11.576C1.69671 10.9782 1.93864 10.3879 2.16005 9.79155C2.20697 9.66398 2.26562 9.62838 2.39611 9.62986C4.24651 9.63431 6.09838 9.63134 7.94879 9.63431C8.17312 9.63431 8.3696 9.58536 8.51476 9.39994C8.67018 9.20266 8.70244 8.98609 8.60273 8.75765C8.50743 8.5396 8.33588 8.41055 8.09834 8.38533C8.02796 8.37791 7.95612 8.38088 7.88574 8.38088C6.06026 8.38088 4.23479 8.3794 2.41077 8.38533C2.26268 8.38533 2.19964 8.33786 2.14832 8.20139C1.4929 6.46736 0.828695 4.73629 0.174749 3.00225C-0.361897 1.57972 0.531047 0.117134 1.99729 0.00588286C2.4489 -0.0282342 2.86531 0.0874672 3.25533 0.315903C7.12476 2.57505 10.9956 4.83271 14.8651 7.09185C16.1803 7.86022 16.3914 9.58388 15.2932 10.6059C15.1671 10.7246 15.0205 10.8254 14.8709 10.913C11.0015 13.1736 7.13209 15.4327 3.2612 17.6904C2.36239 18.211 1.33308 18.0805 0.523716 17.2572Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton className="border-none">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="setting-modal">
            <h2>Logout?</h2>
            <p>Are you sure want to logout?</p>
            <div className="setting-modal-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="90"
                height="100"
                viewBox="0 0 90 100"
                fill="none"
              >
                <path
                  d="M61.5187 0C62.1447 0.165851 62.7767 0.313933 63.3908 0.503477C67.952 1.90137 71.0345 6.01211 71.1001 10.9343C71.1598 15.4597 71.1299 19.9851 71.1061 24.5045C71.0942 26.6488 69.532 28.2421 67.5227 28.2658C65.4776 28.2895 63.8917 26.6547 63.8857 24.469C63.8738 20.1095 63.8857 15.744 63.8797 11.3845C63.8797 8.61835 62.4309 7.17307 59.6405 7.17307C45.8795 7.16715 32.1184 7.17307 18.3573 7.17307C18.0771 7.17307 17.8028 7.20269 17.2841 7.2323C17.7432 7.51662 18.0056 7.68247 18.2739 7.83648C24.9338 11.6629 31.5758 15.513 38.2536 19.298C42.3378 21.6081 44.2875 25.0139 44.2696 29.6933C44.2219 44.9576 44.2517 60.2218 44.2517 75.492C44.2517 75.9125 44.2517 76.3331 44.2517 76.8721C44.6512 76.8899 44.997 76.9254 45.3369 76.9254C50.0889 76.9313 54.8408 76.9313 59.5869 76.9254C62.3892 76.9195 63.8678 75.4387 63.8678 72.6251C63.8738 68.7868 63.8499 64.9426 63.8797 61.1044C63.8917 59.3037 65.1259 57.8762 66.8251 57.586C68.5482 57.2898 70.1819 58.1487 70.7901 59.7361C70.9809 60.2396 71.0822 60.8082 71.0882 61.3532C71.1121 65.227 71.1359 69.1008 71.0942 72.9746C71.0286 79.1762 66.5807 83.826 60.3441 84.0155C55.4013 84.1696 50.4526 84.057 45.5038 84.0689C45.1222 84.0689 44.7407 84.0689 44.2517 84.0689C44.2517 85.7214 44.2219 87.2437 44.2577 88.766C44.4247 95.9746 38.2715 100.228 32.8816 99.9906C31.081 99.9136 29.4055 99.4101 27.8494 98.5157C20.3905 94.245 12.9257 89.9921 5.49062 85.6859C1.88341 83.595 0.0231663 80.3905 0.0172039 76.262C-0.00664539 54.5177 -0.00664539 32.7734 0.0231663 11.0291C0.0291286 5.46717 3.7377 1.1965 9.26478 0.154005C9.38403 0.130312 9.49731 0.0533094 9.6106 0C26.9133 0 44.216 0 61.5187 0Z"
                  fill="url(#paint0_linear_2919_28978)"
                />
                <path
                  d="M77.7004 38.4597C76.3172 37.0915 75.1366 35.9305 73.968 34.7577C72.3403 33.1288 72.233 30.9372 73.6997 29.4623C75.1843 27.9696 77.3844 28.0407 79.03 29.6696C82.2616 32.8563 85.4753 36.0608 88.689 39.2653C90.43 41.0008 90.436 43.0503 88.7129 44.768C85.5051 47.9784 82.2795 51.177 79.0598 54.3696C77.4083 56.0044 75.2261 56.1051 73.7355 54.6362C72.221 53.1494 72.3343 50.946 74.0038 49.2697C75.1068 48.162 76.2158 47.0544 77.3188 45.9408C77.3546 45.9053 77.3546 45.8283 77.4142 45.621C77.0088 45.621 76.6332 45.621 76.2635 45.621C68.0116 45.621 59.7538 45.6269 51.5019 45.615C49.0395 45.615 47.4535 44.1165 47.5131 41.9071C47.5668 40.059 49.0097 38.5782 50.8699 38.4716C51.2277 38.4538 51.5914 38.4597 51.9491 38.4597C60.0698 38.4597 68.1965 38.4597 76.3172 38.4597C76.6749 38.4597 77.0327 38.4597 77.7004 38.4597Z"
                  fill="url(#paint1_linear_2919_28978)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2919_28978"
                    x1="0"
                    y1="0"
                    x2="92.0268"
                    y2="1.90138"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2D5FE5" />
                    <stop offset="0.505" stop-color="#EF117E" />
                    <stop offset="1" stop-color="#3BAD45" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2919_28978"
                    x1="0"
                    y1="0"
                    x2="92.0268"
                    y2="1.90138"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2D5FE5" />
                    <stop offset="0.505" stop-color="#EF117E" />
                    <stop offset="1" stop-color="#3BAD45" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none setting-modal-btn mb-3">
          <Button
            variant="secondary"
            onClick={handleClose}
            className="untheme-btn"
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose} className="theme-btn">
            Logout
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showtwo} onHide={handleClosetwo} animation={false}>
        <Modal.Header closeButton className="border-none">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="setting-modal">
            <h2>Delete Account</h2>
            <p>Are you sure want to delete account?</p>
            <div className="setting-modal-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none"
              >
                <path
                  d="M14.9972 24.965C11.7718 24.965 8.73183 24.9783 5.69189 24.9584C4.99648 24.9518 4.26796 24.9452 3.60566 24.7664C1.18166 24.1108 -0.248902 21.8659 0.0358851 19.2965C0.294181 16.9722 2.29432 15.1378 4.73156 15.0187C5.12232 14.9988 5.51307 15.0054 5.90383 15.0054C35.323 15.0054 64.7422 15.0054 94.1614 15.012C94.9694 15.012 95.8238 15.0385 96.5854 15.2769C98.9498 15.9987 100.281 18.2569 99.9499 20.7666C99.6519 23.0446 97.6716 24.8789 95.2873 24.9385C92.3997 25.0048 89.5055 24.965 86.6112 24.9717C86.1476 24.9717 85.6774 24.9717 84.9952 24.9717C84.9952 25.5279 84.9952 26.0113 84.9952 26.5014C84.9952 45.8379 84.9886 65.1677 85.0019 84.5042C85.0085 90.537 82.4189 95.0863 77.2398 98.1325C74.8621 99.5364 72.2328 100.007 69.4843 99.9999C59.3246 99.9734 49.165 99.9867 39.012 99.9867C36.1575 99.9867 33.3096 99.9933 30.4551 99.9867C21.4612 99.9536 15.017 93.5368 15.0104 84.5506C14.9972 65.2538 15.0038 45.9637 15.0038 26.6669C14.9972 26.157 14.9972 25.6537 14.9972 24.965ZM45.0124 57.5325C45.0124 54.8373 45.0257 52.1421 45.0058 49.4469C44.9925 47.692 45.0787 45.9173 44.8733 44.189C44.5621 41.6395 42.244 39.8316 39.7737 39.9773C37.1311 40.1362 35.0184 42.2023 35.0051 44.8379C34.9654 53.2745 34.9786 61.7044 34.9985 70.1409C35.0051 71.6706 35.6674 72.9421 36.886 73.8758C38.5153 75.134 40.3167 75.3591 42.1645 74.4585C44.1117 73.5116 44.9925 71.8362 45.0058 69.7105C45.019 65.6578 45.0058 61.5984 45.0124 57.5325ZM65.0005 57.6053C65.0005 53.5062 65.0601 49.4005 64.9608 45.3081C64.9343 44.2684 64.623 43.1228 64.0998 42.2222C63.0136 40.3349 60.689 39.58 58.5829 40.1826C56.4238 40.7984 54.9866 42.752 54.9866 45.2419C54.9667 53.4003 54.9667 61.5653 54.9932 69.7237C54.9932 70.3727 55.1124 71.0548 55.3177 71.6706C56.086 73.9089 58.3842 75.2863 60.6625 74.9287C63.1925 74.5314 64.9608 72.5779 64.9873 70.0217C65.027 65.8829 64.9939 61.7441 65.0005 57.6053Z"
                  fill="url(#paint0_linear_2919_29062)"
                />
                <path
                  d="M49.9399 9.94613C46.5423 9.94613 43.1447 9.99249 39.7538 9.93289C37.2967 9.88653 35.3098 7.98599 35.0184 5.56893C34.727 3.16511 36.2436 0.873866 38.5948 0.191791C38.9657 0.0858377 39.363 0.0196168 39.7472 0.0196168C46.5754 0.00637263 53.4103 -0.0201157 60.2386 0.0262389C62.7023 0.0461052 64.676 1.96651 64.974 4.38357C65.2654 6.78077 63.762 9.09188 61.3976 9.74747C60.7684 9.91964 60.0863 9.94613 59.4306 9.94613C56.2715 9.966 53.1057 9.95275 49.9465 9.95275C49.9399 9.95275 49.9399 9.95275 49.9399 9.94613Z"
                  fill="url(#paint1_linear_2919_29062)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2919_29062"
                    x1="0"
                    y1="0"
                    x2="102.242"
                    y2="2.34715"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2D5FE5" />
                    <stop offset="0.505" stop-color="#EF117E" />
                    <stop offset="1" stop-color="#3BAD45" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2919_29062"
                    x1="0"
                    y1="0"
                    x2="102.242"
                    y2="2.34715"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2D5FE5" />
                    <stop offset="0.505" stop-color="#EF117E" />
                    <stop offset="1" stop-color="#3BAD45" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none setting-modal-btn mb-3">
          <Button
            variant="secondary"
            onClick={handleClosetwo}
            className="untheme-btn"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleClosetwo}
            className="theme-btn"
          >
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
