import React, { useState } from "react";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Sidebar state

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar state
  };

  return (
    <div
      className={`main-layout ${
        isSidebarOpen ? "sidebar-closed" : "sidebar-open"
      }`}
    >
      <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
        {/* Button text or icon for toggling */}
        {isSidebarOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="18"
            viewBox="0 0 23 18"
            fill="none"
          >
            <path
              d="M21 3H1.5C1.10218 3 0.720644 2.84196 0.43934 2.56066C0.158035 2.27936 0 1.89782 0 1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0H21C21.3978 0 21.7794 0.158035 22.0607 0.43934C22.342 0.720644 22.5 1.10218 22.5 1.5C22.5 1.89782 22.342 2.27936 22.0607 2.56066C21.7794 2.84196 21.3978 3 21 3ZM21 18H1.5C1.10218 18 0.720644 17.842 0.43934 17.5607C0.158035 17.2794 0 16.8978 0 16.5C0 16.1022 0.158035 15.7206 0.43934 15.4393C0.720644 15.158 1.10218 15 1.5 15H21C21.3978 15 21.7794 15.158 22.0607 15.4393C22.342 15.7206 22.5 16.1022 22.5 16.5C22.5 16.8978 22.342 17.2794 22.0607 17.5607C21.7794 17.842 21.3978 18 21 18ZM21 10.5H1.5C1.10218 10.5 0.720644 10.342 0.43934 10.0607C0.158035 9.77936 0 9.39782 0 9C0 8.60218 0.158035 8.22064 0.43934 7.93934C0.720644 7.65804 1.10218 7.5 1.5 7.5H21C21.3978 7.5 21.7794 7.65804 22.0607 7.93934C22.342 8.22064 22.5 8.60218 22.5 9C22.5 9.39782 22.342 9.77936 22.0607 10.0607C21.7794 10.342 21.3978 10.5 21 10.5Z"
              fill="black"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M0.385714 17.6143C0.642857 17.8714 0.9 18 1.28571 18C1.67143 18 1.92857 17.8714 2.18571 17.6143L9 10.8L15.8143 17.6143C16.0714 17.8714 16.4571 18 16.7143 18C16.9714 18 17.3571 17.8714 17.6143 17.6143C18.1286 17.1 18.1286 16.3286 17.6143 15.8143L10.8 9L17.6143 2.18571C18.1286 1.67143 18.1286 0.9 17.6143 0.385714C17.1 -0.128571 16.3286 -0.128571 15.8143 0.385714L9 7.2L2.18571 0.385714C1.67143 -0.128571 0.9 -0.128571 0.385714 0.385714C-0.128571 0.9 -0.128571 1.67143 0.385714 2.18571L7.2 9L0.385714 15.8143C-0.128571 16.3286 -0.128571 17.1 0.385714 17.6143Z"
              fill="black"
            />
          </svg>
        )}
      </button>
      <Sidebar />
      {children}
    </div>
  );
};

export default Layout;
