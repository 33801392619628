import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { createPostData } from "../redux/Reducers/createPostSlice";

export default function CreateNewPost() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.postData?.createPostState);
  console.log(data, "postData");

  const [images, setImages] = useState([]);
  const [description, setDescription] = useState(null);
  const [audience, setAudience] = useState(null);

  // ========================================handleImages==================================

  const handleImageChange = (e, i) => {
    let newImage = e.target.files;
    setImages((prevImages) => {
      const existingImage = prevImages?.find((img) => img.key === i);
      if (existingImage) {
        return prevImages?.map((img) =>
          img.key === i ? { key: i, image: newImage } : img
        );
      } else {
        return [...prevImages, { key: i, image: newImage }];
      }
    });
  };

  console.log(images, "images");

  // ================================handleSubmit============================================

  const handleSubmit = () => {
    if (images?.length == 0) {
      toast.error("Please upload Image");
    } else if (!description) {
      toast.error("Please add description");
    } else if (!audience) {
      toast.error("Please choose if this post suitable for a younger audience");
    } else {
      dispatch(createPostData({ ...data, images, description, audience }));
      navigate("/tag-friends");
    }
  };

  useEffect(() => {
    if (data?.images) {
      setImages(data?.images);
      setDescription(data?.description);
      setAudience(data?.audience);
    }
  }, [data]);

  return (
    <Layout>
      <div className="right-side">
        {/* <div className="commn-right-top">
          <div className="search-nav-bar">
            <input type="search" placeholder="Search here..." />
          </div>
          <div className="notify-bell">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="30"
              viewBox="0 0 28 30"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M14.3331 0.00156536C14.4077 0.0367861 14.4814 0.0735721 14.5568 0.105662C15.0768 0.324813 15.3733 0.684847 15.3935 1.20298C15.4084 1.59276 15.4057 1.9841 15.397 2.37387C15.3935 2.5398 15.4478 2.60946 15.6443 2.64155C19.7215 3.30292 22.7604 5.92177 23.6032 9.53463C23.7471 10.1498 23.7778 10.7924 23.7962 11.4232C23.8348 12.7397 23.7672 14.0617 23.8584 15.3742C24.0146 17.6197 25.0959 19.4935 26.9692 20.9947C27.5095 21.4275 27.9243 21.9104 27.9892 22.5741C28.1173 23.8906 27.092 24.9276 25.6055 24.9989C25.5178 25.0028 25.4309 25.002 25.3432 25.002C17.7851 25.002 10.2269 25.0004 2.66877 25.0043C1.79701 25.0051 1.05856 24.7656 0.512174 24.1387C-0.301701 23.2057 -0.132436 21.9245 0.926127 21.0972C1.84963 20.374 2.60211 19.5342 3.16516 18.5605C3.88081 17.3223 4.20618 16.0019 4.20881 14.6135C4.21145 13.4128 4.19303 12.2122 4.21846 11.0116C4.30441 6.90169 7.76513 3.32405 12.3037 2.64938C12.5616 2.61103 12.6317 2.52806 12.6159 2.31361C12.5984 2.08037 12.6326 1.84322 12.608 1.61154C12.5256 0.83747 12.8518 0.28881 13.6789 0C13.8955 0.00156536 14.1139 0.00156536 14.3331 0.00156536Z"
                  fill="black"
                />
                <path
                  d="M8.89035 26.259C12.3125 26.259 15.7092 26.259 19.1059 26.259C19.0348 28.0529 16.573 29.9251 14.2261 29.9971C11.3118 30.0863 9.22712 28.1225 8.89035 26.259Z"
                  fill="black"
                />
              </g>
            </svg>
          </div>
        </div> */}
        <div className="right-side-flow">
          <div className="main-content">
            <div className="full-view-content">
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link to="/social-posts" className="back-right-part">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    {/* <div className="online">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill="#3BAD45" />
                      </svg>
                      Online
                    </div> */}
                  </div>
                  <div className="follow-main-data">
                    <div className="commn-form-feild">
                      <div class="commn-form-title mb-4">
                        <h2>Create New Post</h2>
                      </div>
                      <Form className="mt-3">
                        <Row>
                          <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>Upload photo/video here</Form.Label>
                              <div className="row img-upd-prof-list">
                                {Array.from({ length: 5 }, (_, i) => {
                                  console.log(
                                    images?.find((x) => x?.key === i)?.image
                                      ?.length,
                                    "iiiiiiiiiii"
                                  );
                                  return (
                                    <div className="col-md-4 ">
                                      <div
                                        className="upd-profl-img"
                                        // style={{ position: "relative" }}
                                      >
                                        {/* <input
                                          type="file"
                                          className="file-input"
                                          accept="image/*"
                                          style={{
                                            opacity: 0,
                                            position: "absolute",
                                            height: "100%",
                                            width: "100%",
                                            cursor: "pointer",
                                          }}
                                        /> */}
                                        {images?.find((x) => x?.key === i) &&
                                        images?.find((x) => x?.key === i)?.image
                                          ?.length == "1" ? (
                                          <>
                                            <img
                                              className="upd-profl-img"
                                              src={URL?.createObjectURL(
                                                images.find((x) => x.key === i)
                                                  ?.image[0]
                                              )}
                                              key={
                                                images.find((x) => x.key === i)
                                                  ?.key
                                              }
                                              alt="imag"
                                            />
                                            <Form.Control
                                              onChange={(e) =>
                                                handleImageChange(e, i)
                                              }
                                              type="file"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="30"
                                              height="30"
                                              viewBox="0 0 30 30"
                                              fill="none"
                                            >
                                              <g opacity="0.3">
                                                <path
                                                  d="M26.9202 17.1163C26.756 17.0152 26.6566 16.9584 26.5619 16.8952C25.0686 15.9053 23.5642 14.9311 22.0883 13.9159C21.3716 13.4233 20.7213 13.4012 19.9762 13.8591C17.9431 15.1127 15.8846 16.3237 13.8436 17.5662C13.6163 17.7052 13.4695 17.691 13.2564 17.5457C12.1719 16.8068 11.0701 16.0963 9.98566 15.3574C9.49473 15.0227 9.00065 14.9469 8.4229 15.1064C5.69519 15.8626 2.95959 16.5968 0.22714 17.3389C0.171891 17.3547 0.116642 17.3673 0.0219299 17.3926C0.0140372 17.2615 0.00140894 17.151 0.00140894 17.0405C0.00140894 12.8786 -0.00332667 8.7183 0.00456602 4.558C0.00930163 2.26392 1.54838 0.448228 3.8041 0.0519341C4.06772 0.00614713 4.34081 0.0029894 4.60916 0.0029894C10.5397 -0.000168321 16.4703 -0.00174718 22.4009 0.0029894C24.6739 0.00456826 26.4782 1.46501 26.9202 3.65805C26.9865 3.98803 27.007 4.3338 27.0086 4.67168C27.0149 8.53989 27.0133 12.4081 27.0086 16.2763C27.0086 16.5368 26.9549 16.7989 26.9202 17.1163ZM8.26189 10.4993C9.50578 10.4945 10.5082 9.47616 10.4987 8.22728C10.4892 6.99734 9.48526 6.00108 8.254 5.99792C7.00853 5.99635 5.99827 7.00682 5.99985 8.25254C6.00143 9.49668 7.01485 10.504 8.26189 10.4993Z"
                                                  fill="#B4B4B4"
                                                />
                                                <path
                                                  d="M0.045608 20.4998C1.48681 20.1035 2.94065 19.704 4.39448 19.3061C5.74886 18.9367 7.10167 18.5625 8.46079 18.2088C8.61549 18.1694 8.8349 18.2073 8.96908 18.2941C10.1672 19.0725 11.3574 19.8619 12.5413 20.6624C13.1633 21.0839 13.7694 21.0981 14.4119 20.7082C16.4861 19.453 18.5682 18.2136 20.6392 16.9568C20.8681 16.8179 21.0244 16.7926 21.2564 16.96C21.8989 17.4226 22.5666 17.8504 23.2548 18.313C22.8665 18.4804 22.5145 18.602 22.1909 18.7741C19.5721 20.1619 18.1577 22.3297 18.0173 25.3011C17.9636 26.4442 17.4 27.011 16.2493 27.0126C12.3708 27.0126 8.49078 27.0173 4.61232 27.011C2.28239 27.0062 0.400779 25.459 0.0677075 23.1807C-0.060154 22.3218 0.045608 21.4281 0.045608 20.4998Z"
                                                  fill="#B4B4B4"
                                                />
                                                <path
                                                  d="M27.0118 24.0064C27.509 24.0064 27.9636 24.0048 28.4182 24.0064C29.329 24.0111 29.9905 24.6332 29.9999 25.4874C30.0078 26.3479 29.3638 26.9889 28.4561 27.011C27.9904 27.022 27.5248 27.0126 27.0118 27.0126C27.0118 27.5225 27.0228 27.9978 27.0086 28.4746C26.9833 29.3509 26.363 29.9856 25.539 29.9998C24.7055 30.014 24.0315 29.3777 24.0094 28.5393C23.9968 28.0499 24.0078 27.5588 24.0078 27.0126C23.5137 27.0126 23.0386 27.0205 22.565 27.011C21.7805 26.9952 21.1854 26.52 21.0417 25.8079C20.8586 24.8985 21.5184 24.0443 22.445 24.0111C22.947 23.9938 23.4506 24.008 24.0062 24.008C24.0062 23.4933 23.9967 22.9944 24.0078 22.497C24.0283 21.5134 24.9107 20.8297 25.861 21.0508C26.5319 21.2071 26.9912 21.796 27.0102 22.5475C27.0212 23.0149 27.0118 23.4838 27.0118 24.0064Z"
                                                  fill="#B4B4B4"
                                                />
                                              </g>
                                            </svg>
                                            <p>Photo/Video</p>
                                            <Form.Control
                                              onChange={(e) =>
                                                handleImageChange(e, i)
                                              }
                                              type="file"
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Write description</Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Write here..."
                            rows={3}
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                          />
                        </Form.Group>
                        <div className="commn-center-part">
                          <p className="commn-center-title">
                            Will you help us protect our kids? Make sure you
                            create posts that are
                            <br /> appropriate for everyone. Is this post
                            suitable for a younger audience?
                          </p>
                          <div className="yes-no-btn">
                            <div className="mb-4 commn-radio-check">
                              <input
                                class="form-check-input m-1"
                                // value="1"
                                name="audience"
                                type="radio"
                                onChange={() => setAudience("1")}
                                checked={audience == "1"}
                              />
                              <label>Yes</label>
                            </div>

                            <div className="mb-4 commn-radio-check ">
                              <input
                                class="form-check-input m-1"
                                // value="1"
                                name="audience"
                                type="radio"
                                onChange={() => setAudience("0")}
                                checked={audience == "0"}
                              />
                              <label>No</label>
                            </div>
                          </div>

                          <p className="commn-center-title">
                            The post will still go live but will <br /> be
                            shared with a suitable <br /> audience.
                          </p>

                          <p className="commn-center-title">
                            Thank you for your cooperation.
                          </p>
                        </div>
                      </Form>
                      <div className="common-form-btn">
                        <Button onClick={handleSubmit}>Next</Button>
                      </div>
                      <div
                        onClick={() => {
                          navigate("/social-posts");
                          dispatch(createPostData(null));
                        }}
                        className="filters-clear"
                      >
                        <Link>Cancel</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
