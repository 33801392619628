

import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import ProfileSlice from "./Reducers/profileSlice";
import RegistrationSlice from "./Reducers/RegistrationSlice";
import filterSlice from "./Reducers/fIlterSlice";
import createPostSlice from "./Reducers/createPostSlice";


// Combine reducers
const rootReducer = combineReducers({
  profileData:ProfileSlice.reducer,
  registerdata:RegistrationSlice.reducer,
  filterData:filterSlice.reducer,
  postData:createPostSlice.reducer
 
});

// Persist config
const persistConfig = {
  key: "root",
  storage: storage,
  // Optionally, you can blacklist or whitelist specific reducers
  // blacklist: ['meditationData'], // Reducers to exclude from persistence
  // whitelist: ['homedata', 'registerUserdata'], // Reducers to persist
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
export const store = configureStore({
  reducer: persistedReducer,
  // Optionally, you can provide middleware, enhancers, etc.
  // middleware: [/* your middleware here */],
  // devTools: process.env.NODE_ENV !== 'production',
});

// Create a persistor object to persist the store
export const persistor = persistStore(store);
