import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoutes = () => {
  
  const adminToken = window.sessionStorage.getItem("token");
  const location = useLocation();

  useEffect(() => {
    if (!adminToken) {
      window.history.replaceState({}, "", "/login");
    } else {
      console.log(adminToken, "Sdfsdfsdfsdfsdf");
    }
  }, [location, adminToken]);

  return adminToken ? <Outlet /> : <Navigate to="/login" replace />;
};
export default ProtectedRoutes;
