import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import useMutationHook from "../myHooks/mutationHook";
import { logout } from "../redux/Actions/userActions";
import { Button, Modal } from "react-bootstrap";

import { persistor } from "../redux/Store";

export default function Sidebar() {
  const [show, setShow] = useState(false);

  const navigate=useNavigate()
  const handleClose = () => setShow(false);

  // ==================================================================================================== 
  const handleActive = () => {
    const element = document.getElementsByClassName("toggle-sidebar");
    if (element) {
      if (element[0]) {
        element[0].classList.remove("active");
      } else {
        element?.classList.remove("active");
      }
    }
  };



   // ==========================================logout=====================================================

   const {
    mutate,
    isSuccess: logoutSuccess,
    data: logoutData,
  } = useMutationHook(logout);

  useEffect(() => {
   
      if (logoutData?.status === 200) {
        navigate("/");
        sessionStorage.clear();
        persistor.flush()
      }
    
  }, [logoutSuccess]);


  return (
    <div className="sidebar">
      <div className="toggle-close-reps">
        {/* <button onClick={handleActive}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M12.4825 11.0262L20.7215 2.83225C21.1165 2.44125 21.1165 1.80825 20.7215 1.41825C20.3275 1.02725 19.6875 1.02725 19.2935 1.41825L11.0615 9.60525L2.7535 1.29625C2.3595 0.90125 1.7195 0.90125 1.3255 1.29625C0.9315 1.69225 0.9315 2.33325 1.3255 2.72825L9.6275 11.0312L1.2955 19.3173C0.9015 19.7083 0.9015 20.3413 1.2955 20.7313C1.6895 21.1223 2.3295 21.1223 2.7235 20.7313L11.0485 12.4523L19.3235 20.7283C19.7175 21.1233 20.3575 21.1233 20.7515 20.7283C21.1455 20.3323 21.1455 19.6913 20.7515 19.2963L12.4825 11.0262Z"
              fill="#121313"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.2935 1.41813C19.6875 1.02713 20.3275 1.02713 20.7215 1.41813C21.1165 1.80813 21.1165 2.44113 20.7215 2.83213L12.4825 11.0261L20.7515 19.2961C21.1455 19.6911 21.1455 20.3321 20.7515 20.7281C20.3575 21.1231 19.7175 21.1231 19.3235 20.7281L11.0485 12.4521L2.7235 20.7311C2.3295 21.1221 1.6895 21.1221 1.2955 20.7311C0.9015 20.3411 0.9015 19.7081 1.2955 19.3171L9.6275 11.0311L1.3255 2.72813C0.9315 2.33313 0.9315 1.69213 1.3255 1.29613C1.7195 0.901128 2.3595 0.901128 2.7535 1.29613L11.0615 9.60513L19.2935 1.41813ZM11.0625 8.89897L18.9409 1.06361C19.5298 0.479231 20.4845 0.478762 21.0734 1.06296C21.6661 1.64878 21.6655 2.60044 21.074 3.18671M11.0625 8.89897L3.1075 0.943023C3.10758 0.943104 3.10742 0.942942 3.1075 0.943023C2.51812 0.352407 1.56078 0.352244 0.971499 0.943023C0.383674 1.53383 0.382588 2.49083 0.971499 3.08123L8.91944 11.0301L0.9433 18.9622C0.352637 19.5484 0.351829 20.5001 0.9433 21.086C1.53225 21.6705 2.48675 21.6705 3.0757 21.086L11.0475 13.1583L18.9695 21.0812C19.5589 21.6721 20.5161 21.6721 21.1055 21.0812C21.6933 20.4904 21.6944 19.5334 21.1055 18.943L13.1906 11.0271L21.0732 3.18748"
              fill="#121313"
            />
          </svg>
        </button> */}
      </div>
      <div className="logo-side">
        <div className="main-logo-here">
          <img src={require("../Assets/images/logo.svg").default} />
        </div>
      </div>
      <hr />
      <div className="side-menu">
        <div>
          <div className="side-btm-space">
            <Link
              className={
                window.location.pathname == "/dashboard"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
              to="/dashboard"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M23.9984 14.9529C23.8122 15.3603 23.4922 15.4923 23.0544 15.4748C22.44 15.4494 21.824 15.4685 21.2159 15.4685C21.1506 14.956 21.114 14.4579 21.0185 13.971C20.6253 11.9881 19.6288 10.341 18.1548 8.9819C16.3433 7.31092 14.1895 6.35926 11.7635 5.9996C9.77534 5.70519 7.82534 5.87706 5.91195 6.48339C5.8817 6.49294 5.85305 6.50408 5.82281 6.50885C5.81007 6.51203 5.79574 6.50249 5.72093 6.48339C5.87215 6.03302 5.98995 5.57311 6.17619 5.14343C6.92913 3.39288 8.22648 2.14522 9.87562 1.24289C11.1523 0.544261 12.526 0.154366 13.9762 0.0397852C14.0367 0.0350109 14.0956 0.0143227 14.1561 0C14.6098 0 15.0618 0 15.5155 0C15.584 0.0127313 15.6508 0.0334195 15.7209 0.0381938C17.4973 0.18142 19.1401 0.717724 20.611 1.73145C22.1789 2.81202 23.298 4.22996 23.7883 6.09509C23.8854 6.4627 23.9316 6.84305 24 7.21703C24 7.56077 24 7.90452 24 8.24826C23.9857 8.3151 23.965 8.38194 23.957 8.45037C23.8408 9.52298 23.4795 10.5112 22.8937 11.4136C22.6788 11.7446 22.432 12.0565 22.1901 12.3907C22.4241 12.6644 22.666 12.9381 22.8984 13.2198C23.2693 13.6686 23.6323 14.1237 23.9984 14.5757C23.9984 14.703 23.9984 14.8271 23.9984 14.9529Z"
                  fill="#949495"
                />
                <path
                  d="M1.96738 20.7726C1.59489 20.1981 1.19215 19.6666 0.883334 19.0857C-0.62732 16.2483 -0.170462 12.9095 2.02468 10.5558C3.69293 8.76547 5.76868 7.76129 8.16917 7.40163C10.6715 7.02606 13.0561 7.40959 15.2831 8.62701C17.0453 9.59141 18.4302 10.9425 19.2054 12.822C20.2289 15.3014 19.9185 17.6726 18.3681 19.8337C16.6887 22.1778 14.3535 23.5035 11.5104 23.9379C11.1953 23.9857 10.8721 23.9968 10.5522 23.9968C7.30322 24.0016 4.05268 24 0.803742 23.9984C0.717783 23.9984 0.630232 23.9984 0.547456 23.9793C0.0078235 23.8552 -0.178422 23.2393 0.198844 22.8001C0.754395 22.1556 1.3195 21.5174 1.87982 20.8777C1.91962 20.8299 1.95782 20.7822 1.96738 20.7726ZM5.61746 16.8737C6.38791 16.8753 7.02305 16.2467 7.02624 15.478C7.02942 14.7062 6.40383 14.0712 5.63497 14.0633C4.85338 14.0569 4.21346 14.6903 4.21505 15.4717C4.21664 16.2403 4.84701 16.8721 5.61746 16.8737ZM11.2924 15.4653C11.2908 14.695 10.6604 14.0648 9.88995 14.0633C9.11791 14.0617 8.48436 14.6887 8.48117 15.4589C8.47799 16.2403 9.11473 16.8769 9.89473 16.8737C10.6636 16.8689 11.294 16.2355 11.2924 15.4653ZM15.5585 15.4621C15.5553 14.6919 14.9233 14.0633 14.1529 14.0633C13.3809 14.0633 12.7505 14.6919 12.7473 15.4621C12.7441 16.2435 13.3824 16.8785 14.164 16.8737C14.9329 16.8673 15.5617 16.2323 15.5585 15.4621Z"
                  fill="#949495"
                />
              </svg>
              <p>Chat</p>
            </Link>
          </div>
          <div className="side-btm-space">
            <Link
              to="/user-profile"
              className={
                window.location.pathname == "/user-profile"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
              >
                <path
                  d="M3.15667 13.3995C4.05186 14.1745 5.03952 14.7221 6.16035 14.9804C8.38722 15.4932 10.4106 15.0267 12.2195 13.5768C12.4045 13.4265 12.508 13.4342 12.6782 13.5807C14.6017 15.2465 15.5635 17.3827 15.6079 19.9777C15.6153 20.5291 15.6264 21.0805 15.5709 21.6281C15.4377 22.9391 14.365 23.9802 13.0999 23.9841C9.56354 24.0033 6.02718 24.0072 2.49453 23.9841C1.16285 23.9763 0.0605235 22.808 0.0198332 21.4237C-0.0245562 20.0047 -0.0356634 18.5857 0.44892 17.2207C0.985292 15.6822 1.86938 14.4097 3.15667 13.3995Z"
                  fill="#949495"
                />
                <path
                  d="M7.79907 1.63109C11.0321 1.63495 13.6548 4.36882 13.6548 7.73121C13.6548 11.0936 11.0247 13.8313 7.79907 13.8313C4.56604 13.8313 1.95076 11.1052 1.95076 7.73121C1.95076 4.35725 4.56974 1.62723 7.79907 1.63109Z"
                  fill="#949495"
                />
                <path
                  d="M15.6079 4.75056C15.6042 2.12851 17.635 0.00773272 20.1504 2.08152e-05C22.6547 -0.00769109 24.704 2.12851 24.704 4.7467C24.704 7.36104 22.6584 9.49724 20.1541 9.49724C17.6461 9.49724 15.6116 7.37261 15.6079 4.75056Z"
                  fill="#949495"
                />
                <path
                  d="M24.36 8.99596C25.3292 10.0679 25.884 11.3018 25.9765 12.7362C26.0135 13.3031 26.0172 13.8892 25.9099 14.4406C25.7213 15.4007 24.8668 16.1102 23.9346 16.1179C21.4266 16.1372 18.9186 16.1256 16.4106 16.1295C16.2146 16.1295 16.1036 16.0678 16.0148 15.8711C15.6264 14.9881 15.0937 14.2054 14.4686 13.4881C14.3909 13.3995 14.3058 13.2761 14.3095 13.172C14.3391 11.5717 14.8755 10.1836 15.9519 8.99982C17.1282 10.199 18.5228 10.8468 20.1615 10.8468C21.7891 10.843 23.1763 10.199 24.36 8.99596Z"
                  fill="#949495"
                />
              </svg>
              <p>User Profile</p>
            </Link>
          </div>
          <div className="side-btm-space">
            <Link
              to="/social-posts"
              className={
                window.location.pathname == "/social-posts"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.981 23.9892C9.48911 23.9892 6.99414 24.0172 4.50229 23.983C1.19435 23.9331 -0.964219 20.4856 0.43122 17.4838C1.10714 16.0281 1.84846 14.6005 2.58979 13.176C2.78291 12.8051 3.05078 12.4591 3.34669 12.163C4.29982 11.1998 5.65165 11.0377 6.78856 11.7827C7.24021 12.0788 7.60153 12.5121 7.99711 12.8924C8.28056 13.1667 8.5204 13.4971 8.83188 13.734C9.66666 14.3667 10.6727 14.2982 11.4141 13.5594C11.6788 13.2976 11.9031 12.9765 12.09 12.6523C12.8064 11.3993 13.501 10.1337 14.205 8.8713C14.56 8.23542 14.9743 7.64941 15.5568 7.19743C16.6221 6.3714 17.8711 6.43062 18.8523 7.3564C19.3132 7.78968 19.6559 8.31023 19.9019 8.88689C21.1635 11.8201 22.4499 14.7439 23.6615 17.6989C24.8732 20.6477 22.6804 23.9113 19.4908 23.9861C19.4285 23.9861 19.3631 23.9892 19.3008 23.9892C16.8619 23.9892 14.423 23.9892 11.9841 23.9892C11.981 23.9923 11.981 23.9923 11.981 23.9892Z"
                  fill="#949495"
                />
                <path
                  d="M2.31568 3.8902C2.32503 1.7207 4.07244 -0.0124015 6.24036 6.68545e-05C8.40204 0.0125352 10.1463 1.77369 10.1308 3.93072C10.1152 6.07216 8.35221 7.82085 6.21233 7.81773C4.05687 7.81461 2.30945 6.05346 2.31568 3.8902Z"
                  fill="#949495"
                />
              </svg>
              <p>Posts</p>
            </Link>
          </div>
          <div className="side-btm-space">
            <Link
              to="/likes"
              className={
                window.location.pathname == "/likes"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
              >
                <path
                  d="M26 7.48541C26 7.88794 26 8.2935 26 8.69603C25.9883 8.75656 25.9679 8.81407 25.962 8.8746C25.8277 10.4757 25.2698 11.9133 24.4315 13.251C23.5493 14.6584 22.4715 15.8993 21.271 17.01C18.8846 19.2164 16.4602 21.3834 14.0446 23.5565C13.3611 24.1709 12.6017 24.1406 11.889 23.505C10.04 21.8586 8.19104 20.2121 6.35085 18.5536C4.79107 17.1462 3.27803 15.6904 2.03663 13.9623C1.09025 12.6457 0.365852 11.2172 0.123414 9.57676C-0.259229 6.98905 0.225648 4.60411 1.89058 2.58539C3.55552 0.566671 5.71993 -0.23537 8.24946 0.100579C9.88811 0.318492 11.2142 1.17804 12.3242 2.41893C12.5578 2.67922 12.7682 2.96069 13.0048 3.25124C13.1216 3.09688 13.2209 2.95463 13.329 2.82146C13.4429 2.67922 13.5568 2.53999 13.6795 2.4038C15.6949 0.206509 18.1252 -0.528947 20.9146 0.38205C23.5289 1.23554 25.0536 3.21189 25.7371 5.91462C25.8656 6.42611 25.9153 6.96181 26 7.48541Z"
                  fill="#949495"
                />
              </svg>
              <p>Likes</p>
            </Link>
          </div>

          <div className="side-btm-space">
            <Link
              to="/user-profile-screen"
              className={
                window.location.pathname == "/user-profile-screen"
                  ? "menu-btn bar_active"
                  : "menu-btn "
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="24"
                viewBox="0 0 20 24"
                fill="none"
              >
                <path
                  d="M10.0267 14.2401C11.3361 14.2401 12.649 14.1684 13.9512 14.2537C17.1036 14.4585 19.7721 17.0799 19.9785 20.0972C20.0034 20.459 20.0212 20.8345 19.9464 21.186C19.7934 21.8926 19.3558 22.4216 18.6727 22.7391C17.5163 23.2749 16.2746 23.5241 15.015 23.6914C12.1758 24.0668 9.32936 24.0736 6.4794 23.8518C4.8534 23.7255 3.24162 23.4934 1.71168 22.8995C0.50552 22.4319 -0.0139477 21.7526 0.000284278 20.6502C0.0109583 19.8856 0.14972 19.1415 0.437918 18.4281C1.46618 15.875 3.98169 14.2094 6.83165 14.1991C7.89549 14.1957 8.96289 14.1991 10.0267 14.1991C10.0267 14.2094 10.0267 14.2264 10.0267 14.2401Z"
                  fill="#949495"
                />
                <path
                  d="M10.0089 11.7518C6.62172 11.7552 3.85716 9.1202 3.85004 5.88785C3.84648 2.63161 6.59326 0 10.0054 0C13.4175 0 16.1678 2.6282 16.1643 5.88444C16.1607 9.1202 13.3997 11.7518 10.0089 11.7518Z"
                  fill="#949495"
                />
              </svg>

              <p>Profile</p>
            </Link>
          </div>
        </div>
        <div>
          <div  className="side-btm-last">
            <div>
              <Button 
              // to="/" 
             onClick={()=>setShow(true)}
              style={{border:"1.5px solid #000"}}
              className="menu-btn btn-logout ">
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton className="border-none">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="setting-modal">
            <h2>Logout?</h2>
            <p>Are you sure want to logout?</p>
            <div className="setting-modal-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="90"
                height="100"
                viewBox="0 0 90 100"
                fill="none"
              >
                <path
                  d="M61.5187 0C62.1447 0.165851 62.7767 0.313933 63.3908 0.503477C67.952 1.90137 71.0345 6.01211 71.1001 10.9343C71.1598 15.4597 71.1299 19.9851 71.1061 24.5045C71.0942 26.6488 69.532 28.2421 67.5227 28.2658C65.4776 28.2895 63.8917 26.6547 63.8857 24.469C63.8738 20.1095 63.8857 15.744 63.8797 11.3845C63.8797 8.61835 62.4309 7.17307 59.6405 7.17307C45.8795 7.16715 32.1184 7.17307 18.3573 7.17307C18.0771 7.17307 17.8028 7.20269 17.2841 7.2323C17.7432 7.51662 18.0056 7.68247 18.2739 7.83648C24.9338 11.6629 31.5758 15.513 38.2536 19.298C42.3378 21.6081 44.2875 25.0139 44.2696 29.6933C44.2219 44.9576 44.2517 60.2218 44.2517 75.492C44.2517 75.9125 44.2517 76.3331 44.2517 76.8721C44.6512 76.8899 44.997 76.9254 45.3369 76.9254C50.0889 76.9313 54.8408 76.9313 59.5869 76.9254C62.3892 76.9195 63.8678 75.4387 63.8678 72.6251C63.8738 68.7868 63.8499 64.9426 63.8797 61.1044C63.8917 59.3037 65.1259 57.8762 66.8251 57.586C68.5482 57.2898 70.1819 58.1487 70.7901 59.7361C70.9809 60.2396 71.0822 60.8082 71.0882 61.3532C71.1121 65.227 71.1359 69.1008 71.0942 72.9746C71.0286 79.1762 66.5807 83.826 60.3441 84.0155C55.4013 84.1696 50.4526 84.057 45.5038 84.0689C45.1222 84.0689 44.7407 84.0689 44.2517 84.0689C44.2517 85.7214 44.2219 87.2437 44.2577 88.766C44.4247 95.9746 38.2715 100.228 32.8816 99.9906C31.081 99.9136 29.4055 99.4101 27.8494 98.5157C20.3905 94.245 12.9257 89.9921 5.49062 85.6859C1.88341 83.595 0.0231663 80.3905 0.0172039 76.262C-0.00664539 54.5177 -0.00664539 32.7734 0.0231663 11.0291C0.0291286 5.46717 3.7377 1.1965 9.26478 0.154005C9.38403 0.130312 9.49731 0.0533094 9.6106 0C26.9133 0 44.216 0 61.5187 0Z"
                  fill="url(#paint0_linear_2919_28978)"
                />
                <path
                  d="M77.7004 38.4597C76.3172 37.0915 75.1366 35.9305 73.968 34.7577C72.3403 33.1288 72.233 30.9372 73.6997 29.4623C75.1843 27.9696 77.3844 28.0407 79.03 29.6696C82.2616 32.8563 85.4753 36.0608 88.689 39.2653C90.43 41.0008 90.436 43.0503 88.7129 44.768C85.5051 47.9784 82.2795 51.177 79.0598 54.3696C77.4083 56.0044 75.2261 56.1051 73.7355 54.6362C72.221 53.1494 72.3343 50.946 74.0038 49.2697C75.1068 48.162 76.2158 47.0544 77.3188 45.9408C77.3546 45.9053 77.3546 45.8283 77.4142 45.621C77.0088 45.621 76.6332 45.621 76.2635 45.621C68.0116 45.621 59.7538 45.6269 51.5019 45.615C49.0395 45.615 47.4535 44.1165 47.5131 41.9071C47.5668 40.059 49.0097 38.5782 50.8699 38.4716C51.2277 38.4538 51.5914 38.4597 51.9491 38.4597C60.0698 38.4597 68.1965 38.4597 76.3172 38.4597C76.6749 38.4597 77.0327 38.4597 77.7004 38.4597Z"
                  fill="url(#paint1_linear_2919_28978)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2919_28978"
                    x1="0"
                    y1="0"
                    x2="92.0268"
                    y2="1.90138"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2D5FE5" />
                    <stop offset="0.505" stop-color="#EF117E" />
                    <stop offset="1" stop-color="#3BAD45" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2919_28978"
                    x1="0"
                    y1="0"
                    x2="92.0268"
                    y2="1.90138"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2D5FE5" />
                    <stop offset="0.505" stop-color="#EF117E" />
                    <stop offset="1" stop-color="#3BAD45" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none setting-modal-btn mb-3">
          <Button
            variant="secondary"
            onClick={handleClose}
            className="untheme-btn"
          >
            Cancel
          </Button>
          <Button 
           onClick={()=>mutate()}
          // to="/dashboard"
           className="theme-btn">
            Logout
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}
