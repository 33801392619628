import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { profile, userProfiles } from "../redux/Actions/userActions";
import useQueryHook from "../myHooks/queryHook";
import RangeComponent from "../Components/RangeSlider";
import { useDispatch, useSelector } from "react-redux";
import { advanceFilter, filters } from "../redux/Reducers/fIlterSlice";
import toast from "react-hot-toast";
import ProfilePicture from "../Components/ProfilePicture";
// import { useNavigate } from "react-router-dom";

export default function Filters() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const data = useSelector((state) => state?.profileData?.editprofiledata);
  console.log(data, "updatedData");
  const filterData = useSelector((state) => state?.filterData?.filterState);
  console.log(filterData, "filterData");

 

  // ===========================================react state==================================================

  const [gender, setGender] = useState(null);
  const [lookingFor, setlookingFor] = useState(null);
  const [country, setcountry] = useState(null);
  const [state, setstate] = useState(null);
  const [city, setcity] = useState(null);
  const [ageRange, setAgeRange] = useState([0, 80]); // Default range
  const [distanceRange, setDistanceRange] = useState([0, 100]); // Default range

  console.log(ageRange);

  const applyFilters = () => {
    if (
      !gender &&
      !lookingFor &&
      !country &&
      !state &&
      !city &&
      !ageRange[1] &&
      !distanceRange[1]
    ) {
      toast.error("Please select at least one option");
    } else {
      dispatch(
        filters({
          ...filterData,
          gender,
          lookingFor,
          country,
          state,
          city,
          min_age: ageRange[0],
          max_age: ageRange[1],
          min_distance: distanceRange[0],
          max_distance: distanceRange[1],
        })
      );
      navigate("/user-profile");
    }
  };

  useEffect(() => {
    if (filterData) {
      setGender(filterData?.gender ? filterData?.gender : null);
      setlookingFor(filterData?.lookingFor ? filterData?.lookingFor : null);
      setcountry(filterData?.country ? filterData?.country : null);
      setstate(filterData?.state ? filterData?.state : null);
      setcity(filterData?.city ? filterData?.city : null);
      setAgeRange([filterData?.min_age, filterData?.max_age]);
      setDistanceRange([filterData?.min_distance, filterData?.max_distance]);
    }
  }, [filterData]);

  console.log(gender, "grnfr");

  return (
    <Layout>
      <div className="right-side">
        {/* <div className="commn-right-top">
          <div className="search-nav-bar">
            <input type="search" placeholder="Search here..." />
          </div>
          <div className="notify-bell">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="30"
              viewBox="0 0 28 30"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M14.3331 0.00156536C14.4077 0.0367861 14.4814 0.0735721 14.5568 0.105662C15.0768 0.324813 15.3733 0.684847 15.3935 1.20298C15.4084 1.59276 15.4057 1.9841 15.397 2.37387C15.3935 2.5398 15.4478 2.60946 15.6443 2.64155C19.7215 3.30292 22.7604 5.92177 23.6032 9.53463C23.7471 10.1498 23.7778 10.7924 23.7962 11.4232C23.8348 12.7397 23.7672 14.0617 23.8584 15.3742C24.0146 17.6197 25.0959 19.4935 26.9692 20.9947C27.5095 21.4275 27.9243 21.9104 27.9892 22.5741C28.1173 23.8906 27.092 24.9276 25.6055 24.9989C25.5178 25.0028 25.4309 25.002 25.3432 25.002C17.7851 25.002 10.2269 25.0004 2.66877 25.0043C1.79701 25.0051 1.05856 24.7656 0.512174 24.1387C-0.301701 23.2057 -0.132436 21.9245 0.926127 21.0972C1.84963 20.374 2.60211 19.5342 3.16516 18.5605C3.88081 17.3223 4.20618 16.0019 4.20881 14.6135C4.21145 13.4128 4.19303 12.2122 4.21846 11.0116C4.30441 6.90169 7.76513 3.32405 12.3037 2.64938C12.5616 2.61103 12.6317 2.52806 12.6159 2.31361C12.5984 2.08037 12.6326 1.84322 12.608 1.61154C12.5256 0.83747 12.8518 0.28881 13.6789 0C13.8955 0.00156536 14.1139 0.00156536 14.3331 0.00156536Z"
                  fill="black"
                />
                <path
                  d="M8.89035 26.259C12.3125 26.259 15.7092 26.259 19.1059 26.259C19.0348 28.0529 16.573 29.9251 14.2261 29.9971C11.3118 30.0863 9.22712 28.1225 8.89035 26.259Z"
                  fill="black"
                />
              </g>
            </svg>
          </div>
        </div> */}
        <div className="right-side-flow">
          <div className="main-content">
            <div className="main-content-contin">
              <div className="common-page-left-part">
              <ProfilePicture/>
                {/* <div className="commn-profile-show">
                  <img
                    style={{ borderRadius: "50%" }}
                    src={`${BASE_URL}public/${profiledata?.data?.image}`}
                    alt="logo"
                  />
                  <h2>
                    {profiledata?.data?.name} {profiledata?.data?.sur_name}
                    {profiledata?.data?.age
                      ? ", " + profiledata?.data?.age
                      : ""}
                  </h2>
               
                </div> */}
              </div>
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link
                      to={
                        data?.freSub == "2"
                          ? "/profile-preferences"
                          : data?.freSub == "0" || data?.freSub == "1"
                          ? "/subscription-plans"
                          : "/seeking-for"
                      }
                      className="back-right-part"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>

                    
                    {/* <div className="online">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill="#3BAD45" />
                      </svg>
                      Online
                    </div> */}
                  </div>
                  <div className="follow-main-data">
                    <div className="commn-form-feild">
                      <div className="commn-form-title mb-4">
                        <h2>Filters</h2>
                        <p>
                          Please select your preferences to
                          <br />
                          filter the cards
                        </p>
                      </div>
                      <div className="commn-form-feild">
                        <Form className="mt-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Preferred Gender</Form.Label>
                            <Form.Select
                              onChange={(e) => setGender(e.target.value)}
                              aria-label="Default select example"
                              value={gender}
                            >
                              <option>Select</option>
                              <option value="1">Male</option>
                              <option value="2">Female</option>
                              <option value="3">Other</option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Looking For</Form.Label>
                            <Form.Select
                              onChange={(e) => setlookingFor(e.target.value)}
                              value={lookingFor}
                              aria-label="Default select example"
                            >
                              <option>Select</option>
                              <option value="0">Divorced</option>
                              <option value="1">Friendship</option>
                              <option value="2">
                                Fun with another Partner
                              </option>
                              <option value="3">Married</option>
                              <option value="4">Serious Relationship</option>
                              <option value="5">Single</option>
                              <option value="6">Widowed</option>
                            </Form.Select>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicCheckbox"
                            ></Form.Group>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              value={country}
                              onChange={(e) => setcountry(e.target.value)}
                              type="text"
                              placeholder="Country"
                            />
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicCheckbox"
                            ></Form.Group>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Control
                              value={state}
                              onChange={(e) => setstate(e.target.value)}
                              type="text"
                              placeholder="State"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Control
                              value={city}
                              onChange={(e) => setcity(e.target.value)}
                              type="text"
                              placeholder="City"
                            />
                          </Form.Group>

                          <RangeComponent
                            setAgeRange={setAgeRange}
                            ageRange={ageRange}
                            distanceRange={distanceRange}
                            setDistanceRange={setDistanceRange}
                          />
                        </Form>
                      </div>
                      <div
                        onClick={() => {
                          // dispatch(
                          //   filters({

                          //     seekingFor: filterData?.seekingFor,
                          //   })
                          // );
                          dispatch(filters(null));

                          navigate("/advance-filters");
                        }}
                        className="advance-filters"
                      >
                        <Link to="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="17"
                            viewBox="0 0 21 17"
                            fill="none"
                          >
                            <path
                              d="M14.1956 13.8124C12.3081 13.8124 10.4217 13.8154 8.53427 13.8083C8.34451 13.8072 8.24912 13.8483 8.18722 14.0493C7.62707 15.8658 5.82283 17.1263 4.00033 16.9899C1.95255 16.8361 0.362427 15.4001 0.0529259 13.4236C-0.293106 11.2174 1.09102 9.11988 3.2281 8.61216C5.37431 8.1024 7.53371 9.34142 8.19635 11.4738C8.25521 11.6625 8.34654 11.6871 8.51093 11.6871C12.301 11.6841 16.0922 11.6841 19.8823 11.6851C20.5581 11.6851 21.0219 12.1497 20.9985 12.7959C20.9782 13.3846 20.5196 13.8062 19.8803 13.8103C18.9447 13.8154 18.0101 13.8113 17.0745 13.8113C16.1155 13.8124 15.1556 13.8124 14.1956 13.8124ZM4.19821 10.6286C3.03936 10.6286 2.10071 11.5712 2.09462 12.7395C2.08954 13.8995 3.0353 14.8627 4.18502 14.8678C5.34894 14.8729 6.30586 13.9098 6.30079 12.7395C6.29571 11.5712 5.35706 10.6286 4.19821 10.6286Z"
                              fill="url(#paint0_linear_3575_458)"
                            />
                            <path
                              d="M6.80309 3.18735C8.69866 3.18735 10.5942 3.18427 12.4888 3.19248C12.6785 3.1935 12.7557 3.13504 12.8115 2.95349C13.3747 1.12984 15.1931 -0.136876 17.0217 0.0118476C19.0634 0.178008 20.6454 1.61191 20.9478 3.57198C21.2898 5.78847 19.9198 7.86752 17.7767 8.38652C15.6416 8.90346 13.4629 7.65623 12.8064 5.52897C12.7496 5.34333 12.6623 5.31153 12.4949 5.31256C8.70373 5.31666 4.91361 5.31563 1.12248 5.31461C0.45477 5.31461 -0.00795948 4.86331 0.00218809 4.23046C0.0113209 3.62531 0.468976 3.19248 1.11741 3.18838C2.05301 3.18325 2.9876 3.18735 3.92321 3.18735C4.88317 3.18735 5.84313 3.18735 6.80309 3.18735ZM16.8025 6.37003C17.9614 6.37106 18.901 5.42846 18.9071 4.26021C18.9132 3.09914 17.9695 2.13705 16.8177 2.1309C15.6538 2.12475 14.6969 3.08581 14.7009 4.25713C14.705 5.42435 15.6447 6.36901 16.8025 6.37003Z"
                              fill="url(#paint1_linear_3575_458)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_3575_458"
                                x1="0"
                                y1="0"
                                x2="21.4648"
                                y2="0.608709"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#2D5FE5" />
                                <stop offset="1" stop-color="#EF117E" />
                              </linearGradient>
                              <linearGradient
                                id="paint1_linear_3575_458"
                                x1="0"
                                y1="0"
                                x2="21.4648"
                                y2="0.608709"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#2D5FE5" />
                                <stop offset="1" stop-color="#EF117E" />
                              </linearGradient>
                            </defs>
                          </svg>
                          Advance Filters
                        </Link>
                      </div>
                      <div className="common-form-btn">
                        <Button
                          // onClick={() => navigate("/account-preferences")}
                          onClick={applyFilters}
                        >
                          Apply
                        </Button>
                      </div>
                      <div
                        onClick={() => {
                          dispatch(filters(null));
                          dispatch(advanceFilter(null));

                          navigate("/user-profile");
                        }}
                        className="filters-clear"
                      >
                        <Link>Clear Filter</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
