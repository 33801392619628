import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

export default function ProfileDetailExpanded() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/following-followers"); // Replace '/target-page' with your target route
  };

  var settingsTwo = {
    dots: true,
    infinite: false,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };
  return (
    <Layout>
      <div className="right-side">
        <div className="commn-right-top">
          <div className="search-nav-bar">
            <input type="search" placeholder="Search here..." />
          </div>
          <div className="notify-bell">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="30"
              viewBox="0 0 28 30"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M14.3331 0.00156536C14.4077 0.0367861 14.4814 0.0735721 14.5568 0.105662C15.0768 0.324813 15.3733 0.684847 15.3935 1.20298C15.4084 1.59276 15.4057 1.9841 15.397 2.37387C15.3935 2.5398 15.4478 2.60946 15.6443 2.64155C19.7215 3.30292 22.7604 5.92177 23.6032 9.53463C23.7471 10.1498 23.7778 10.7924 23.7962 11.4232C23.8348 12.7397 23.7672 14.0617 23.8584 15.3742C24.0146 17.6197 25.0959 19.4935 26.9692 20.9947C27.5095 21.4275 27.9243 21.9104 27.9892 22.5741C28.1173 23.8906 27.092 24.9276 25.6055 24.9989C25.5178 25.0028 25.4309 25.002 25.3432 25.002C17.7851 25.002 10.2269 25.0004 2.66877 25.0043C1.79701 25.0051 1.05856 24.7656 0.512174 24.1387C-0.301701 23.2057 -0.132436 21.9245 0.926127 21.0972C1.84963 20.374 2.60211 19.5342 3.16516 18.5605C3.88081 17.3223 4.20618 16.0019 4.20881 14.6135C4.21145 13.4128 4.19303 12.2122 4.21846 11.0116C4.30441 6.90169 7.76513 3.32405 12.3037 2.64938C12.5616 2.61103 12.6317 2.52806 12.6159 2.31361C12.5984 2.08037 12.6326 1.84322 12.608 1.61154C12.5256 0.83747 12.8518 0.28881 13.6789 0C13.8955 0.00156536 14.1139 0.00156536 14.3331 0.00156536Z"
                  fill="black"
                />
                <path
                  d="M8.89035 26.259C12.3125 26.259 15.7092 26.259 19.1059 26.259C19.0348 28.0529 16.573 29.9251 14.2261 29.9971C11.3118 30.0863 9.22712 28.1225 8.89035 26.259Z"
                  fill="black"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="right-side-flow">
          <div className="main-content">
            <div className="main-content-contin">
              <div className="common-page-left-part">
                <div className="new-match-contain">
                  <p>New Matches</p>
                  <div className="new-match-lists">
                    <Slider {...settings}>
                      <div className="story-img">
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>{" "}
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                      <div>
                        <img
                          src={require("../Assets/images/story-image.png")}
                        />
                      </div>
                    </Slider>
                   
                  </div>
                </div>
                <div className="message-contain">
                  <p>Messages</p>
                  <div className="message-lists">
                    <ul>
                      <li>
                        <div className="message-box-list active">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              {/* <span>1</span> */}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>{" "}
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>{" "}
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="message-box-list">
                          <img
                            src={require("../Assets/images/story-image.png")}
                          />
                          <div className="message-box-text">
                            <div className="name-time-msg">
                              <div className="">Devid,23</div>
                              <p>3:45 PM</p>
                            </div>
                            <div className="msg-notify-bottm">
                              <p>
                                Hi, How are you? Nice to meet you Free now, You?
                              </p>
                              <span>1</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="common-page-right-part">
                <div className="profile-pad-ad">
                  <div className="profile-page-top">
                    <Link to="/dashboard" className="back-right-part">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M6.65664 0.0150056C6.45215 0.063774 6.28806 0.225685 6.13785 0.380443C4.22491 2.35134 2.31133 4.32223 0.398386 6.29312C0.212203 6.48495 0.0146604 6.70213 0.000775337 6.97328C-0.0150023 7.2828 0.212834 7.5416 0.425524 7.76138C2.31827 9.71407 4.21039 11.6661 6.10314 13.6188C6.22684 13.7462 6.35496 13.8776 6.51716 13.9465C6.81568 14.074 7.17038 13.9602 7.41904 13.7475C7.58881 13.6019 7.72072 13.4159 7.83622 13.2234C7.86525 13.1747 7.89365 13.1259 7.92142 13.0758C7.94351 13.0368 7.96812 12.9972 7.98011 12.9529C7.98958 12.9178 7.98958 12.8814 7.994 12.8456C7.99968 12.7936 8.00346 12.7436 7.99526 12.6909C7.96181 12.4718 7.80403 12.2858 7.65761 12.133C7.65319 12.1284 7.64877 12.1239 7.64435 12.1187C5.9908 10.4137 4.33725 8.70879 2.6837 7.00449C4.35176 5.31255 6.00973 3.60761 7.65571 1.89096C7.79645 1.74466 7.94288 1.58665 7.98579 1.38572C8.06216 1.03004 7.7933 0.702965 7.54274 0.446769C7.30986 0.208779 7.02269 -0.0441667 6.69829 0.00655243C6.68441 0.00850317 6.67052 0.0117544 6.65664 0.0150056Z"
                          fill="black"
                        />
                      </svg>
                    </Link>
                    <div className="online">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle cx="5" cy="5" r="5" fill="#3BAD45" />
                      </svg>
                      Online
                    </div>
                  </div>
                  <div className="profile-main-data row">
                    <div className="profile-img-side col-md-3">
                      <div className="shop-menu-card">
                        <Slider {...settingsTwo}>
                          <div>
                            <img
                              src={require("../Assets/images/story-image.png")} className="w-chat-pro"
                              alt="Product"
                            />
                          </div>

                          <div>
                            <img
                              src={require("../Assets/images/story-image.png")}
                              alt="Product"
                            />
                          </div>
                        </Slider>
                        <Link to="/picture-expanded">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M13 1H19V7"
                              stroke="#28282B"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19 1L12 8"
                              stroke="#28282B"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7 19H1V13"
                              stroke="#28282B"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1 19L8 12"
                              stroke="#28282B"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                    <div className="profile-data-side col-md-9">
                      <div className="d-flex">
                        <div className="user-name w-100">
                          <h2>
                            Olivia, 23
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="20"
                              viewBox="0 0 19 20"
                              fill="none"
                            >
                              <path
                                d="M9.2186 17.1082C8.04057 16.4251 7.06824 15.6602 6.26472 14.8514C5.09461 13.6734 4.2858 12.4039 3.72844 11.1626C2.07479 7.4773 2.65238 4.04957 2.65238 4.04957C2.65238 4.04957 3.36272 4.73354 5.71352 4.42936C8.17069 4.11288 9.21861 3.02539 9.21861 3.02539C9.21861 3.02539 10.2665 4.11288 12.7237 4.42936C15.0745 4.73354 15.7848 4.04957 15.7848 4.04957C15.7848 4.04957 17.2046 12.4769 9.2186 17.1082Z"
                                fill="#3BAD45"
                              />
                              <path
                                d="M12.576 7.30455C12.235 6.96359 11.6821 6.96359 11.3412 7.30455L8.35284 10.2929L7.09216 9.0322C6.75119 8.69118 6.1983 8.69118 5.85733 9.0322C5.51631 9.37317 5.51631 9.926 5.85733 10.267L7.73543 12.1452C7.89915 12.309 8.12126 12.4009 8.35284 12.4009C8.58444 12.4009 8.80655 12.309 8.97025 12.1452L12.576 8.53939C12.917 8.19841 12.917 7.64552 12.576 7.30455Z"
                                fill="white"
                              />
                              <path
                                d="M9.21694 16.3754L9.14739 16.3323C6.99498 14.9967 5.394 13.2347 4.38893 11.0952C3.24378 8.65782 3.14462 6.31821 3.18747 5.14053L3.19322 4.98235L3.3478 5.0166C4.01981 5.16547 4.84232 5.17886 5.79245 5.05605C7.40189 4.84855 8.50334 4.29703 9.14388 3.87085L9.21695 3.82227L9.29 3.87085C9.93047 4.29703 11.0317 4.84855 12.6407 5.05605C13.5911 5.17874 14.4137 5.16542 15.0856 5.0166L15.2402 4.98235L15.2459 5.14053C15.2888 6.31826 15.1896 8.65794 14.0445 11.0954C13.0394 13.2349 11.4386 14.9968 9.2865 16.3323L9.21694 16.3754ZM3.44643 5.30581C3.41935 6.50199 3.55452 8.69904 4.62759 10.9831C5.59978 13.0525 7.14372 14.7619 9.21694 16.0648C11.2899 14.762 12.8336 13.0527 13.8058 10.9833C14.8789 8.69918 15.014 6.50204 14.987 5.30582C14.3159 5.43104 13.5163 5.43507 12.607 5.31766C11.0068 5.11132 9.88993 4.57259 9.21694 4.13835C8.5438 4.57259 7.42673 5.11132 5.82618 5.31766C4.91696 5.43502 4.11746 5.43098 3.44643 5.30581Z"
                                fill="white"
                              />
                              <g opacity="0.2">
                                <path
                                  d="M9.19206 17.1734L9.17001 17.1606C8.05238 16.5125 7.05549 15.7509 6.20703 14.8968C5.13769 13.8203 4.28136 12.5748 3.6618 11.195C2.80474 9.28496 2.55687 7.44651 2.49987 6.24059C2.43806 4.93339 2.58104 4.06534 2.5825 4.05672L2.59624 3.97525L2.65625 4.03225C2.66324 4.03891 3.38616 4.69731 5.68135 4.40018C8.09231 4.08965 9.15005 3.02005 9.16045 3.00928L9.19207 2.97656L9.22372 3.00928C9.23411 3.02005 10.2918 4.08965 12.7028 4.40018C14.9976 4.69706 15.7208 4.0389 15.7279 4.03225L15.7878 3.97535L15.8017 4.05673C15.8052 4.0779 16.1507 6.20317 15.5127 8.86684C15.1379 10.4314 14.5023 11.8736 13.6234 13.1533C12.5249 14.7527 11.0414 16.1009 9.21415 17.1606L9.19206 17.1734ZM2.65822 4.14587C2.62593 4.3814 2.53665 5.1576 2.58769 6.23646C2.64436 7.43479 2.89058 9.26152 3.74198 11.159C4.35721 12.5291 5.20757 13.7659 6.26939 14.8349C7.10684 15.6778 8.09003 16.4303 9.19206 17.0717C10.9979 16.0206 12.4643 14.6856 13.5509 13.1035C14.4237 11.8327 15.055 10.4004 15.4272 8.84629C15.994 6.48021 15.7799 4.54337 15.7258 4.14592C15.5067 4.30187 14.6852 4.74521 12.6916 4.48739C10.4777 4.20223 9.38601 3.28009 9.19207 3.10062C8.99814 3.28009 7.90648 4.20223 5.6926 4.48739C3.69823 4.74551 2.87727 4.30178 2.65822 4.14587Z"
                                  fill="#3BAD45"
                                />
                                <path
                                  d="M9.20927 18.1131L9.19301 18.1066C7.70822 17.5076 6.40983 16.6558 5.3338 15.5748C4.0941 14.3286 3.15744 12.807 2.54978 11.0523C1.80114 8.8904 1.70327 6.95632 1.75303 5.71454C1.80132 4.50921 1.96557 3.53874 2.08275 3.42146C2.14199 3.36236 2.25376 3.38245 2.47656 3.42237C2.90007 3.49847 3.78151 3.65683 5.34557 3.41339C7.09649 3.13669 8.18133 2.52783 8.76409 2.20073C8.99148 2.0731 9.12866 1.99609 9.2082 1.99609C9.2877 1.99609 9.42494 2.0731 9.65224 2.20068C10.235 2.52778 11.3198 3.13669 13.0709 3.41338C14.635 3.65673 15.5163 3.49846 15.9398 3.42241C16.1627 3.38243 16.2745 3.36235 16.3337 3.42145C16.6463 3.73414 17.1188 6.06146 16.5771 8.95807C16.2327 10.7992 15.4742 12.545 14.3835 14.0067C13.0842 15.7477 11.3487 17.1269 9.22527 18.1058L9.20927 18.1131ZM2.198 3.46535C2.16966 3.46535 2.14987 3.46938 2.14027 3.479C2.06162 3.55761 1.88726 4.39578 1.83424 5.71782C1.78479 6.95259 1.88214 8.87578 2.62661 11.0258C3.23024 12.7688 4.16046 14.28 5.3914 15.5175C6.45571 16.5867 7.73938 17.4301 9.20711 18.0246C11.3109 17.0519 13.0304 15.6838 14.3183 13.9581C15.4016 12.5063 16.155 10.7722 16.4971 8.94318C17.0337 6.07391 16.58 3.78285 16.2761 3.479C16.247 3.44982 16.1242 3.47193 15.9542 3.50245C15.5263 3.57929 14.6359 3.73927 13.0582 3.49378C11.2927 3.21476 10.1997 2.60122 9.61243 2.27162C9.41158 2.15891 9.26645 2.07742 9.20819 2.07742C9.14993 2.07742 9.00476 2.15891 8.80386 2.27167C8.21663 2.60126 7.12355 3.21476 5.35818 3.49378C3.78054 3.73919 2.89009 3.57933 2.46215 3.50245C2.34829 3.482 2.25556 3.46535 2.198 3.46535Z"
                                  fill="#3BAD45"
                                />
                                <path
                                  d="M9.23365 19.0562L9.22185 19.0527C7.40852 18.5227 5.76482 17.5551 4.46854 16.2544C3.03574 14.8158 2.019 13.0185 1.44649 10.9126C0.807153 8.56081 0.858654 6.50317 1.01448 5.19113C1.16532 3.92068 1.3815 3.00897 1.57874 2.81177V2.81172C1.71675 2.67376 1.97362 2.67135 2.44002 2.66702C2.98153 2.66196 3.79992 2.65436 5.01791 2.42836C6.51556 2.15049 7.5925 1.65839 8.30512 1.33274C8.74618 1.13116 9.03756 0.998047 9.23233 0.998047C9.42709 0.998047 9.71847 1.13116 10.1595 1.33274C10.8722 1.65839 11.9491 2.15049 13.4467 2.42836C14.6647 2.65437 15.4831 2.66196 16.0246 2.66702C16.491 2.67135 16.7479 2.67376 16.8859 2.81172C17.5018 3.42759 18.1096 5.93873 17.6492 9.05187C17.3321 11.196 16.4686 13.2049 15.1518 14.8614C13.6664 16.7301 11.6238 18.1792 9.24521 19.0519L9.23365 19.0562ZM1.63154 2.86458C1.44846 3.0477 1.23537 3.96438 1.08865 5.19998C0.933645 6.50541 0.882394 8.55278 1.5186 10.893C2.08763 12.9862 3.09794 14.7724 4.52151 16.2017C5.80607 17.4906 7.43441 18.4503 9.23099 18.9776C11.5918 18.1091 13.6188 16.6698 15.0933 14.8148C16.4019 13.1687 17.2601 11.1721 17.5753 9.04098C18.0321 5.95209 17.4378 3.46927 16.8331 2.86458C16.7167 2.74819 16.4559 2.74576 16.0239 2.74171C15.4798 2.73665 14.6575 2.72901 13.4331 2.50184C11.9264 2.22227 10.8444 1.72789 10.1285 1.4007C9.71186 1.21033 9.41082 1.07274 9.23233 1.07274C9.05384 1.07274 8.7528 1.21032 8.33616 1.4007C7.62024 1.72789 6.53824 2.22227 5.03157 2.50184C3.80719 2.72901 2.98485 2.73665 2.44071 2.74171C2.00874 2.74575 1.74801 2.74819 1.63155 2.86461V2.86458H1.63154Z"
                                  fill="#3BAD45"
                                />
                                <path
                                  d="M9.25225 20L9.24384 19.9982C7.08495 19.5322 5.13261 18.4727 3.59787 16.9341C1.9814 15.3124 0.854194 13.1822 0.338141 10.7737C-0.0885047 8.78232 -0.111851 6.67121 0.270585 4.66852C0.450178 3.72809 0.734529 2.53681 1.06931 2.20202C1.31995 1.95138 1.76875 1.89449 2.51232 1.80027C3.0701 1.72958 3.76426 1.64158 4.68503 1.44331C5.97114 1.16635 6.96488 0.771043 7.7634 0.453381C8.42498 0.190156 8.90296 0 9.25101 0C9.59906 0 10.0771 0.190168 10.7387 0.453381C11.5372 0.771043 12.531 1.16635 13.817 1.44331C14.7378 1.64159 15.432 1.72958 15.9898 1.80027C16.7333 1.89451 17.1822 1.95138 17.4328 2.20202C18.3424 3.11171 19.1012 5.85462 18.7158 9.14616C18.4292 11.5939 17.4607 13.8655 15.915 15.7156C14.2203 17.7442 11.9192 19.2248 9.26055 19.9976L9.25225 20ZM9.251 0.0681634C8.91601 0.0681634 8.44314 0.256267 7.78859 0.516663C7.02995 0.818475 5.99105 1.23176 4.69936 1.50993C3.77572 1.70881 3.05044 1.80075 2.5209 1.86785C1.79176 1.96028 1.35163 2.01603 1.11748 2.25019C0.862203 2.50547 0.592485 3.34614 0.337543 4.68132C-0.0432579 6.67511 -0.0199955 8.77687 0.404764 10.7594C0.918073 13.1551 2.03892 15.2736 3.64613 16.886C5.16963 18.4133 7.10707 19.4656 9.24977 19.9298C11.8919 19.1601 14.1784 17.6879 15.8627 15.6719C17.3999 13.832 18.3631 11.5727 18.6481 9.13827C19.031 5.86798 18.2829 3.14855 17.3846 2.25019C17.1504 2.01607 16.7103 1.96027 15.9812 1.86785C15.4516 1.80076 14.7263 1.70881 13.8027 1.50993C12.5111 1.23176 11.4721 0.818475 10.7135 0.516699C10.059 0.256267 9.58601 0.0681634 9.251 0.0681634Z"
                                  fill="#3BAD45"
                                />
                              </g>
                            </svg>
                          </h2>
                          <p>Olivia</p>
                        </div>
                        <div className="user-follow w-100">
                          <ul>
                            <li>
                              <div className="">50</div>
                              <p>post</p>
                            </li>
                            <li>
                              <div className="">100</div>
                              <p>followers</p>
                            </li>
                            <li>
                              <div className="">100</div>
                              <p>following</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="profile-bio">
                        <ul>
                          <li>Chandigarh, India</li>
                          <li>Blogger & Content Creator</li>
                          <li>Single</li>
                          <li>Blogger</li>
                        </ul>
                        <div className="follow-btn-profile-ad">
                          <button
                            onClick={handleRedirect}
                            className="ad-theme-btn"
                          >
                            Follow
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="feed-show-profile">
                    <hr />
                    <div className="like-show-more">
                      <div className="">
                        <img
                          src={
                            require("../Assets/images/comment-outline.svg")
                              .default
                          }
                        />
                        <img
                          src={
                            require("../Assets/images/heart-outline.svg")
                              .default
                          }
                        />
                      </div>
                      <Link to="">Show More</Link>
                    </div>
                  </div>
                  <div className="profile-overview-details">
                    <Row>
                      <Col lg={6}>
                        <div className="overview-box-contain">
                          <p className="overview-box-title">Overview</p>
                          <div className="overview-box">
                            <Row>
                              <Col lg={6}>
                                <div className="overview-details-txt">
                                  <h4> Education</h4>
                                  <p>Bachelors</p>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="overview-details-txt">
                                  <h4> Ethnicity</h4>
                                  <p>Prefer not to say</p>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="overview-details-txt">
                                  <h4> Religion</h4>
                                  <p>Others</p>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="overview-details-txt">
                                  <h4> Pets</h4>
                                  <p>Prefer not to say</p>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="overview-details-txt">
                                  <h4>Nationality</h4>
                                  <p>Prefer not to say</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="overview-box-contain">
                          <p className="overview-box-title">Basic</p>
                          <div className="overview-box">
                            <Row>
                              <Col lg={6}>
                                <div className="overview-details-txt">
                                  <h4> Height:</h4>
                                  <p>5’7</p>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="overview-details-txt">
                                  <h4> Weight</h4>
                                  <p>70 kg</p>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="overview-details-txt">
                                  <h4> Language</h4>
                                  <p>English</p>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="overview-details-txt">
                                  <h4> Body Type</h4>
                                  <p>Prefer not to say</p>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="overview-details-txt">
                                  <h4>Drink</h4>
                                  <p>Occasionally</p>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="overview-details-txt">
                                  <h4>Smoke</h4>
                                  <p>Prefer not to say</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col lg={12}>
                        <div className="overview-box-contain">
                          <p className="overview-box-title">Identity</p>
                          <div className="overview-box">
                            <Row>
                              <Col lg={6}>
                                <p className="overview-box-title">Freelancer</p>
                                <Row>
                                  <Col lg={12}>
                                    <div className="overview-details-txt">
                                      <h4>Occupation</h4>
                                      <p>Education / Academic</p>
                                    </div>
                                    <div className="freelancer-tags">
                                      <ul>
                                        <li>Advertising / Media</li>
                                        <li>Lorium ipsum</li>
                                        <li>Media</li>
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={6}>
                                <p className="overview-box-title">Address</p>
                                <Row>
                                  <Col lg={6}>
                                    <div className="overview-details-txt">
                                      <h4>City</h4>
                                      <p>Lorium</p>
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="overview-details-txt">
                                      <h4>State</h4>
                                      <p>Lorium</p>
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="overview-details-txt">
                                      <h4>Country</h4>
                                      <p>Lorium</p>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col lg={12}>
                        <div className="overview-box-contain">
                          <p className="overview-box-title">
                            Hobbies & Interest
                          </p>
                          <div className="">
                            <Row>
                              <Col lg={6}>
                                <Row>
                                  <Col lg={12}>
                                    <p className="hobbies-interest-title">
                                      Freelancer
                                    </p>
                                    <div className="hobbies-interest-tags">
                                      <ul>
                                        <li>Antiques</li>
                                        <li>Art/Painting</li>
                                        <li>Ballet</li>
                                        <li>Astrology</li>
                                        <li>Bars/Clubs</li>
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={6}>
                                <Row>
                                  <Col lg={12}>
                                    <p className="hobbies-interest-title">
                                      Food
                                    </p>
                                    <div className="hobbies-interest-tags">
                                      <ul>
                                        <li>Antiques</li>
                                        <li>Art/Painting</li>
                                        <li>Ballet</li>
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={6}>
                                <Row>
                                  <Col lg={12}>
                                    <p className="hobbies-interest-title">
                                      Music
                                    </p>
                                    <div className="hobbies-interest-tags">
                                      <ul>
                                        <li>Antiques</li>
                                        <li>Art/Painting</li>
                                        <li>Ballet</li>
                                        <li>Astrology</li>
                                        <li>Bars/Clubs</li>
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={6}>
                                <Row>
                                  <Col lg={12}>
                                    <p className="hobbies-interest-title">
                                      Play & Like
                                    </p>
                                    <div className="hobbies-interest-tags">
                                      <ul>
                                        <li>Antiques</li>
                                        <li>Art/Painting</li>
                                        <li>Ballet</li>
                                        <li>Astrology</li>
                                        <li>Bars/Clubs</li>
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="follow-btn-profile">
                          <button onClick={handleRedirect}>Follow</button>
                          <Link to="/restrict-person" className="block-report">
                            Block & Report
                          </Link>
                          <Link to="#" className="restrict-messages">
                            Restrict Messages
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
